import {
  SET_DEFAULT_NOTE_TEMPLATE,
  SET_INTERRUPTED_RECORDING_ACTION,
  SET_IS_CURRENTLY_RECORDING,
  SET_NOTE_TEMPLATES,
  SET_PROVIDER_NOTE,
  SET_PROVIDER_NOTES,
  SET_PROVIDER_NOTES_LOADING,
  SET_PROVIDER_NOTES_RECORDING,
  SET_PROVIDER_NOTES_RECORDING_CANCELLED,
  SET_PROVIDER_NOTES_TOGGLE_PAUSE,
  SET_PROVIDER_NOTE_CONTENT,
  SET_PROVIDER_NOTE_DELETED,
  SET_SELECTED_NOTE_TEMPLATE,
  SET_SELECTED_SCRIBE_NOTE_TEMPLATE,
  SET_SHOW_SCRIBE_NOTE_TEMPLATE,
} from "./types";

export function setProviderNotesAction(notes) {
  return {
    type: SET_PROVIDER_NOTES,
    payload: { notes },
  };
}

export function setNoteContentAction(noteId, noteContent) {
  return {
    type: SET_PROVIDER_NOTE_CONTENT,
    payload: { noteId, noteContent },
  };
}

export function setProviderNoteAction(payload) {
  return {
    type: SET_PROVIDER_NOTE,
    payload,
  };
}

export function setProviderNotesRecordingAction() {
  return {
    type: SET_PROVIDER_NOTES_RECORDING,
  };
}

export function setProviderNotesTogglePauseAction() {
  return {
    type: SET_PROVIDER_NOTES_TOGGLE_PAUSE,
  };
}

export function setProviderNotesRecordingCancelledAction(payload) {
  return {
    type: SET_PROVIDER_NOTES_RECORDING_CANCELLED,
    payload,
  };
}

export function setProviderNotesLoadingAction(payload) {
  return {
    type: SET_PROVIDER_NOTES_LOADING,
    payload,
  };
}

export function setProviderNoteDeletedAction(payload) {
  return {
    type: SET_PROVIDER_NOTE_DELETED,
    payload,
  };
}

export function setNoteTemplatesAction(noteTemplates) {
  return {
    type: SET_NOTE_TEMPLATES,
    payload: {
      noteTemplates: noteTemplates,
    },
  };
}

export function setDefaultNoteTemplateAction(defaultNoteTemplate) {
  return {
    type: SET_DEFAULT_NOTE_TEMPLATE,
    payload: {
      defaultNoteTemplate: defaultNoteTemplate,
    },
  };
}

export function setSelectedNoteTemplateAction(selectedNoteTemplate) {
  return {
    type: SET_SELECTED_NOTE_TEMPLATE,
    payload: {
      selectedNoteTemplate: selectedNoteTemplate,
    },
  };
}

export function setShowScribeNoteTemplateAction(showScribeNoteTemplate) {
  return {
    type: SET_SHOW_SCRIBE_NOTE_TEMPLATE,
    payload: {
      showScribeNoteTemplate: showScribeNoteTemplate,
    },
  };
}

export function setSelectedScribeNoteTemplateAction(
  selectedScribeNoteTemplate,
) {
  return {
    type: SET_SELECTED_SCRIBE_NOTE_TEMPLATE,
    payload: {
      selectedScribeNoteTemplate: selectedScribeNoteTemplate,
    },
  };
}

export function setInterruptedRecordingActionAction(
  interruptedRecordingAction,
) {
  return {
    type: SET_INTERRUPTED_RECORDING_ACTION,
    payload: { interruptedRecordingAction },
  };
}

export function setIsCurrentlyRecordingAction(isCurrentlyRecording) {
  return {
    type: SET_IS_CURRENTLY_RECORDING,
    payload: {
      isCurrentlyRecording: isCurrentlyRecording,
    },
  };
}
