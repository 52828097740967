import { useMediaQuery } from "react-responsive";
import Modal from "./Modal";
import AddEditPatientForm from "./AddEditPatientForm";
import ModalMobile from "./ModalMobile";

const AddEditPatientModal = ({
  open,
  onClose,
  onUpdate = () => {},
  patientInfo,
}) => {
  const edit = !!patientInfo;
  const isDesktop = useMediaQuery({ minWidth: 768 });

  return isDesktop ? (
    open && (
      <Modal className="pt-5 rounded-2xl" onClickAway={onClose}>
        <div className="w-134 max-h-85vh-dynamic grid grid-rows-tab-layout overflow-hidden">
          <div className="flex items-center justify-between px-5">
            <p className="text-base md:text-xl font-semibold">
              {edit ? "Edit Patient" : "Add Patient"}
            </p>
          </div>

          <AddEditPatientForm
            patientInfo={patientInfo}
            onClose={onClose}
            onUpdate={onUpdate}
          />
        </div>
      </Modal>
    )
  ) : (
    <ModalMobile
      open={open}
      header={edit ? "Edit Patient" : "Add Patient"}
      onClickAway={onClose}
      className=""
    >
      <AddEditPatientForm
        patientInfo={patientInfo}
        onClose={onClose}
        onUpdate={onUpdate}
      />
    </ModalMobile>
  );
};

export default AddEditPatientModal;
