import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CheckIcon } from "../../../../assets/icons/file-check.svg";
import { ReactComponent as PauseIcon } from "../../../../assets/icons/pause-filled.svg";
import { ReactComponent as PlayIcon } from "../../../../assets/icons/play.svg";
import { ReactComponent as CancelIcon } from "../../../../assets/icons/x-mark.svg";
import { ReactComponent as SettingUpHeader } from "../../../../assets/note-header.svg";
import useIndependentScribeRecorder from "../../../../hooks/useIndependentScribeRecorder";
import { store } from "../../../../store";
import {
  setLastSelectedPatientProfileAction,
  setSelectedPatientNoteAction,
  setSelectedPatientPanelTabAction,
} from "../../../../store/patient/actions";
import { getPatientNotes } from "../../../../store/patient/thunks";
import { setSelectedSettingsTabAction } from "../../../../store/user/actions";
import {
  setConversationIdGeneratingAction,
  setOpenCallOptionsModalAction,
  setPatientIdGeneratingAction,
} from "../../../../store/voiceRecorder/actions";
import { sendPatientAudioPart } from "../../../../store/voiceRecorder/thunks";
import MicrophoneLiveVisualizer from "../../../Basic/MicrophoneLiveVisualizer";
import ProgressBar from "../../../Basic/ProgressBar";
import CancelRecordingModal from "../../../IndependentScribe/CancelRecordingModal";
import RecordingIndicator from "../../../IndependentScribe/RecordingIndicator";
import TimerDisplay from "../../../IndependentScribe/TimerDisplay";
import { ERROR_NOT_ENOUGH_TRANSCRIPT } from "../../../IndependentScribe/consts";
import {
  setInterruptedRecordingActionAction,
  setIsCurrentlyRecordingAction,
  setShowScribeNoteTemplateAction,
} from "../../../IndependentScribe/store/actions";
import { SetInterruptedRecordingActionType } from "../../../IndependentScribe/store/types";
import { useNewEnrollmentLogic } from "../../CareCoordination/useNewEnrollmentLogic";

const buttonClasses =
  "w-full h-12 height-sm:h-16 text-base font-semibold flex items-center justify-center space-x-2 px-2";

const Recording = ({
  onCancel,
  microphoneId,
  mediaRecorder,
  setNotEnoughTranscript,
}) => {
  const [showCancelRecordingModal, setShowCancelRecordingModal] =
    useState(false);
  const { patientIdGenerating } = useSelector((state) => state.voiceRecorder);
  const {
    selectedPatientInfo,
    selectedPatientNotes,
    selectedPatientEnrollments,
    patientRecordingScribeTitle,
  } = useSelector((state) => state.patient);
  const { selectedScribeNoteTemplate, interruptedRecordingAction } =
    useSelector((state) => state.scribe);
  const [notesLength, setNotesLength] = useState(
    selectedPatientNotes?.length || 0,
  );
  const [notesPrevLength, setNotesPrevLength] = useState(
    selectedPatientNotes?.length || 0,
  );
  const [checkNote, setCheckNote] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [generatingProgress, setGeneratingProgress] = useState(0);
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const isHeightSm = useMediaQuery({ minHeight: 640 });
  const navigate = useNavigate();
  const { handleNewEnrollment } = useNewEnrollmentLogic();

  const {
    recorderState,
    startRecording,
    togglePause,
    toggleError,
    cancelRecording,
    saveRecording,
  } = useIndependentScribeRecorder(
    microphoneId,
    mediaRecorder,
    (data, status) => {
      dispatch(
        sendPatientAudioPart(
          selectedPatientInfo.patient_id,
          data,
          recorderState.audioId,
          status,
          "auto_detect",
          "",
          "",
          () => {},
          () => {},
          selectedScribeNoteTemplate
            ? selectedScribeNoteTemplate.note_template_id
            : null,
        ),
      );
    },
    (audioId) => {
      dispatch(setPatientIdGeneratingAction(selectedPatientInfo.patient_id));

      dispatch(
        sendPatientAudioPart(
          selectedPatientInfo.patient_id,
          null,
          audioId,
          "end",
          "auto_detect",
          patientRecordingScribeTitle,
          "",
          (data) => {
            dispatch(
              setConversationIdGeneratingAction(
                data.data.voice_conversation.note_id,
              ),
            );
          },
          (error) => {
            if (error === ERROR_NOT_ENOUGH_TRANSCRIPT) {
              toggleError();
              setNotEnoughTranscript(true);
            }
          },
        ),
      );
    },
  );

  const { audioId, isSaving } = recorderState;

  const isPaused = mediaRecorder.state === "paused";
  const isRecording = mediaRecorder.state === "recording";

  const handleCancelRecording = () => {
    onCancel();

    if (
      interruptedRecordingAction?.type ===
      SetInterruptedRecordingActionType.NAVIGATION
    ) {
      navigate(interruptedRecordingAction.value);
    } else if (
      interruptedRecordingAction?.type ===
      SetInterruptedRecordingActionType.PATIENT_PROFILE_CLOSE
    ) {
      dispatch(
        setLastSelectedPatientProfileAction({
          info: selectedPatientInfo,
          notes: selectedPatientNotes,
          enrollments: selectedPatientEnrollments,
        }),
      );
      navigate(-1);
    } else if (
      interruptedRecordingAction?.type ===
      SetInterruptedRecordingActionType.NEW_ENROLLMENT
    ) {
      handleNewEnrollment();
    } else if (
      interruptedRecordingAction?.type ===
      SetInterruptedRecordingActionType.PATIENT_PROFILE_TAB_CHANGE
    ) {
      dispatch(
        setSelectedPatientPanelTabAction(interruptedRecordingAction.value),
      );
    } else if (
      interruptedRecordingAction?.type ===
      SetInterruptedRecordingActionType.SETTINGS_NAVIGATION
    ) {
      const { selectedSettingsTab } = store.getState().user;

      if (!selectedSettingsTab) {
        dispatch(setSelectedSettingsTabAction("Profile"));
      }

      navigate(interruptedRecordingAction.value);
    }
  };

  useEffect(() => {
    startRecording();
    dispatch(setIsCurrentlyRecordingAction(true));

    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "Are you sure you want to cancel the recording?";
    };

    const handlePopState = (_) => {
      history.pushState(null, null, window.location.href);
      setShowCancelRecordingModal(true);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("popstate", handlePopState);
    history.pushState(null, null, window.location.href);

    return () => {
      onCancel();
      cancelRecording();
      dispatch(setShowScribeNoteTemplateAction(false));
      dispatch(setIsCurrentlyRecordingAction(false));
      dispatch(setInterruptedRecordingActionAction(null));
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  useEffect(() => {
    if (selectedPatientNotes) {
      setNotesPrevLength(notesLength);
      setNotesLength(selectedPatientNotes.length);
    }
  }, [selectedPatientNotes]);

  useEffect(() => {
    if (
      patientIdGenerating &&
      patientIdGenerating === selectedPatientInfo.patient_id
    ) {
      setIsGenerating(true);
    }
  }, [patientIdGenerating, selectedPatientInfo.patient_id]);

  useEffect(() => {
    if (isGenerating && selectedPatientNotes && notesLength > notesPrevLength) {
      setCheckNote(true);
    }
  }, [isGenerating, notesLength, selectedPatientNotes, notesPrevLength]);

  useEffect(() => {
    let interval;
    let progressInterval;

    if (isGenerating) {
      interval = setInterval(() => {
        dispatch(getPatientNotes(selectedPatientInfo.patient_id));
      }, 3000);

      progressInterval = setInterval(() => {
        setGeneratingProgress((oldProgress) => {
          if (oldProgress < 100) {
            return oldProgress + 1;
          }

          return 100;
        });
      }, 200);
    }

    return () => {
      clearInterval(interval);
      clearInterval(progressInterval);
      setGeneratingProgress(0);
    };
  }, [isGenerating, dispatch]);

  useEffect(() => {
    if (checkNote) {
      const sortedNotes = [...selectedPatientNotes];
      sortedNotes.sort(
        (a, b) =>
          new Date(`${b.started_at.replace(/-/g, "/")} GMT+0`) -
          new Date(`${a.started_at.replace(/-/g, "/")} GMT+0`),
      );
      if (sortedNotes[0].note) {
        if (!isDesktop) {
          dispatch(setOpenCallOptionsModalAction(false));
        }
        dispatch(setSelectedPatientNoteAction(sortedNotes[0]));
        dispatch(setShowScribeNoteTemplateAction(false));
        setCheckNote(false);
        setIsGenerating(false);

        if (!isRecording) {
          onCancel();
          dispatch(setShowScribeNoteTemplateAction(false));
        }
      }
    }
  }, [checkNote, selectedPatientNotes, dispatch]);

  useEffect(() => {
    if (interruptedRecordingAction) {
      setShowCancelRecordingModal(true);
    }
  }, [interruptedRecordingAction]);

  return (
    <div className="h-full w-full grid grid-rows-2 height-sm:grid-rows-3 overflow-y-auto scrollbar">
      <div className="hidden height-sm:block">{/* todo transcription */}</div>

      {isGenerating && !isRecording ? (
        <div className="p-8">
          <div className="w-full max-w-md mx-auto bg-slate-50 rounded-2xl p-8 flex flex-col items-center ">
            <SettingUpHeader width="200" height="81" className="mb-6" />
            <p className="text-xl text-center font-semibold mb-3">
              We’re setting up your note
            </p>
            <p className="text-sm text-center text-tertiary mb-6">
              While waiting, you can explore other things as this may take a few
              minutes
            </p>
            {generatingProgress < 100 && (
              <ProgressBar variant="determinate" value={generatingProgress} />
            )}
          </div>
        </div>
      ) : (
        <>
          <div className="w-full grid items-center relative">
            <div className="blur-3xl bg-blue-gradient absolute top-0 left-0 w-full h-full" />
            <div className="px-10 w-full overflow-x-hidden">
              <MicrophoneLiveVisualizer
                mediaRecorder={mediaRecorder}
                height={isHeightSm ? 160 : 70}
              />
            </div>
          </div>
          <div className="grid grid-rows-note-layout items-end gap-16">
            <div className="h-fit flex flex-col items-center space-y-1">
              <div className="text-center text-xl md:text-[32px] font-semibold">
                <TimerDisplay
                  isRecording={isRecording}
                  startedAt={recorderState.startedAt}
                  offset={recorderState.accumulatedSeconds}
                  className="text-2xl"
                />
              </div>
              <div className="h-6">
                {audioId && (
                  <div className="rounded-full pl-2 pr-3 py-1 bg-pink-150 flex items-center space-x-2">
                    <RecordingIndicator isRecording={isRecording} size="16" />
                    <p className="text-xs font-medium text-zinc-700">
                      {isRecording ? "Recording" : "Paused"}
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="w-full grid grid-cols-2 relative">
              <button
                type="button"
                className={`${buttonClasses} text-tertiary bg-gray-background border-r-2 border-white pr-10`}
                onClick={() => {
                  cancelRecording();
                  onCancel();
                  dispatch(setShowScribeNoteTemplateAction(false));
                }}
              >
                <CancelIcon width="21" height="20" className="flex-none" />
                <p>Cancel</p>
              </button>

              <button
                type="button"
                className={`${buttonClasses} text-primary-blue bg-primary-blue-light border-l-2 border-white pl-10`}
                onClick={saveRecording}
                disabled={isSaving}
              >
                <CheckIcon
                  width="20"
                  height="20"
                  stroke="#2970FF"
                  fill="#2970FF"
                  className="flex-none"
                />
                <p className="text-center leading-tight">Generate note</p>
              </button>

              <button
                type="button"
                className="absolute z-10 -top-10 left-1/2 -translate-x-1/2 rounded-full bg-primary-blue h-20 w-20
                  flex items-center justify-center border-4 border-white"
                onClick={togglePause}
              >
                {!isPaused ? (
                  <PauseIcon width="40" height="40" />
                ) : (
                  <PlayIcon width="30" height="30" fill="white" />
                )}
              </button>
            </div>
          </div>
          {showCancelRecordingModal && (
            <CancelRecordingModal
              onClose={() => {
                setShowCancelRecordingModal(false);
                dispatch(setInterruptedRecordingActionAction(null));
              }}
              onConfirm={handleCancelRecording}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Recording;
