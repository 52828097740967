import axios from "axios";
import Bugsnag from "@bugsnag/js";
import { setNotificationAction } from "../../../store/user/actions";
import { getUser, updateStoredUser } from "../../../store/user/thunks";
import {
  setNoteContentAction,
  setProviderNotesAction,
  setProviderNotesLoadingAction,
  setDefaultNoteTemplateAction,
  setNoteTemplatesAction,
  setSelectedNoteTemplateAction,
} from "./actions";
import { BASE_URL, fetchWithAuth } from "../../../helpers/config";
import { sectionDetailLevels, sectionStyles } from "../../../helpers/constants";

export const getProviderNotes = (providerId) => async (dispatch) => {
  try {
    const data = await fetchWithAuth(
      `${BASE_URL}/v2/provider/${providerId}/notes/`,
    );

    await dispatch(setProviderNotesAction(data.data.notes));
  } catch (error) {
    if (error instanceof TypeError) {
      console.error("Network error or JSON parsing failed:", error);
    } else {
      console.error("Failed to fetch provider notes:", error);
    }

    console.error("Failed to fetch provider notes:", error);
    dispatch(
      setNotificationAction({
        status: "error",
        title: "Something went wrong",
        desc: "Failed to get provider notes",
      }),
    );
    Bugsnag.notify(error);
  } finally {
    dispatch(setProviderNotesLoadingAction(false));
  }
};

export const getProviderNote = (audioId) => async (dispatch) => {
  try {
    const data = await fetchWithAuth(`${BASE_URL}/note/${audioId}`);

    await dispatch(setNoteContentAction(data.data.noteId, data.data.note));
  } catch (error) {
    console.error("Failed to fetch provider note:", error);
    dispatch(
      setNotificationAction({
        status: "error",
        title: "Something went wrong",
        desc: "Failed to load note content",
      }),
    );
    Bugsnag.notify(error);
  } finally {
    dispatch(setProviderNotesLoadingAction(false));
  }
};

export const getNoteTemplates = () => (dispatch) => {
  axios
    .get(`${BASE_URL}/note-template/list?show_hidden=1`)
    .then((response) => {
      if (response.data.error) {
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to load note templates",
          }),
        );
        console.error(response.data.error);
        Bugsnag.notify(response.data.error);
      } else {
        dispatch(setNoteTemplatesAction(response.data));
      }
    })
    .catch((error) => {
      console.error(error);
      Bugsnag.notify(error);
    });
};

export const describeNoteTemplate =
  (noteTemplateId, onSuccess = () => {}) =>
  (dispatch) => {
    axios
      .get(`${BASE_URL}/note-template/describe/${noteTemplateId}`)
      .then((response) => {
        if (response.data.error) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to describe note template",
            }),
          );
          console.error(response.data.error);
          Bugsnag.notify(response.data.error);
        } else {
          onSuccess(response.data);
        }
      })
      .catch((error) => {
        console.error(error);
        Bugsnag.notify(error);
      });
  };

export const selectDefaultNoteTemplate = (template) => (dispatch) => {
  axios
    .post(`${BASE_URL}/note-template/select/${template.note_template_id}`)
    .then((response) => {
      if (response.data.error) {
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to select note template",
          }),
        );
        console.error(response.data.error);
        Bugsnag.notify(response.data.error);
      } else {
        dispatch(
          describeNoteTemplate(template.note_template_id, (data) =>
            dispatch(setDefaultNoteTemplateAction(data)),
          ),
        );
        getUser().then((user) => dispatch(updateStoredUser(user)));
      }
    })
    .catch((error) => {
      console.error(error);
      Bugsnag.notify(error);
    });
};

export const updateNoteTemplate =
  (
    noteTemplateId,
    newName,
    language,
    instructions,
    showIcd10,
    isHidden,
    onSuccess = () => {},
  ) =>
  (dispatch) => {
    axios
      .post(`${BASE_URL}/note-template/update/${noteTemplateId}`, {
        name: newName, // text
        note_language: language, // text
        custom_instructions: instructions, // text
        show_icd10_codes_suggestions: showIcd10 ? 1 : 0, // boolean
        is_hidden: isHidden ? 1 : 0, // boolean
      })
      .then((response) => {
        if (response.data.error) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to update note template",
            }),
          );
          console.error(response.data.error);
          Bugsnag.notify(response.data.error);
        } else {
          dispatch(getNoteTemplates());
          onSuccess();
        }
      })
      .catch((error) => {
        console.error(error);
        Bugsnag.notify(error);
      });
  };

export const updateTemplateSectionsOrder =
  (noteTemplateId, sections) => (dispatch) => {
    axios
      .post(
        `${BASE_URL}/note-template/update-sections-order/${noteTemplateId}`,
        sections, // [{note_template_section_id: number, order: number}]
      )
      .then((response) => {
        if (response.data.error) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to update template sections order",
            }),
          );
          console.error(response.data.error);
          Bugsnag.notify(response.data.error);
        } else {
          dispatch(
            describeNoteTemplate(noteTemplateId, (data) =>
              dispatch(setSelectedNoteTemplateAction(data)),
            ),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to update template sections order",
          }),
        );
        console.error(error);
        Bugsnag.notify(error);
      });
  };

export const updateTemplateSection =
  (
    noteTemplateId,
    noteTemplateSectionId,
    newName,
    style,
    hideByDefault,
    subsections,
    detailLevel,
    splitByProblem,
    includeDifferential,
  ) =>
  (dispatch) => {
    axios
      .post(
        `${BASE_URL}/note-template/section/update/${noteTemplateSectionId}`,
        {
          name: newName, // text,
          section_style:
            style === "Bullet points" ? sectionStyles.BULLET : style, // enum('Auto','Bullet','Paragraph'),
          hide_by_default: hideByDefault ? 1 : 0, // boolean,
          subsections: subsections, // array,
          detail_level: detailLevel, // enum('High','Normal')
          split_by_problem: splitByProblem ? 1 : 0, // boolean,
          include_differential: includeDifferential ? 1 : 0, // boolean
        },
      )
      .then((response) => {
        if (response.data.error) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to update template section",
            }),
          );
          console.error(response.data.error);
          Bugsnag.notify(response.data.error);
        } else {
          dispatch(
            describeNoteTemplate(noteTemplateId, (data) =>
              dispatch(setSelectedNoteTemplateAction(data)),
            ),
          );
        }
      })
      .catch((error) => {
        console.error(error);
        Bugsnag.notify(error);
      });
  };

export const deleteTemplateSection =
  (noteTemplateId, noteTemplateSectionId) => (dispatch) => {
    axios
      .post(`${BASE_URL}/note-template/delete-section/${noteTemplateSectionId}`)
      .then((response) => {
        if (response.data.error) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to delete template section",
            }),
          );
          console.error(response.data.error);
          Bugsnag.notify(response.data.error);
        } else {
          dispatch(
            describeNoteTemplate(noteTemplateId, (data) =>
              dispatch(setSelectedNoteTemplateAction(data)),
            ),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to delete template section",
          }),
        );
        console.error(error);
        Bugsnag.notify(error);
      });
  };

export const createTemplateSection =
  (noteTemplateId, sectionOrder) => (dispatch) => {
    axios
      .post(`${BASE_URL}/note-template/create-section/${noteTemplateId}`, {
        name: "New section",
        section_style: sectionStyles.AUTO,
        hide_by_default: 0,
        subsections: [],
        detail_level: sectionDetailLevels.NORMAL,
        split_by_problem: 0,
        include_differential: 0,
        order: sectionOrder,
        has_subsections: 0,
      })
      .then((response) => {
        if (response.data.error) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to create template section",
            }),
          );
          console.error(response.data.error);
          Bugsnag.notify(response.data.error);
        } else {
          dispatch(
            describeNoteTemplate(noteTemplateId, (data) =>
              dispatch(setSelectedNoteTemplateAction(data)),
            ),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to create template section",
          }),
        );
        console.error(error);
        Bugsnag.notify(error);
      });
  };

export const createTemplate =
  (
    templateName,
    noteLanguage,
    customInstructions,
    sections,
    onSuccess = () => {},
  ) =>
  (dispatch) => {
    axios
      .post(`${BASE_URL}/note-template/create`, {
        template: {
          name: templateName,
          note_language: noteLanguage,
          custom_instructions: customInstructions,
        },
        sections: sections,
      })
      .then((response) => {
        if (response.data.error) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to create note template",
            }),
          );
          console.error(response.data.error);
          Bugsnag.notify(response.data.error);
        } else {
          onSuccess();
          dispatch(
            setNotificationAction({
              status: "success",
              title: "Success",
              desc: "Note template created successfully",
            }),
          );
          dispatch(getNoteTemplates());
          dispatch(setSelectedNoteTemplateAction(response.data.data));
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to create note template",
          }),
        );
        console.error(error);
        Bugsnag.notify(error);
      });
  };

export const deleteTemplate =
  (noteTemplateId, onSuccess = () => {}) =>
  (dispatch) => {
    axios
      .post(`${BASE_URL}/note-template/delete/${noteTemplateId}`)
      .then((response) => {
        if (response.data.error) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to delete note template",
            }),
          );
          console.error(response.data.error);
          Bugsnag.notify(response.data.error);
        } else {
          onSuccess();
          dispatch(
            setNotificationAction({
              status: "success",
              title: "Success",
              desc: "Note template deleted successfully",
            }),
          );
          dispatch(getNoteTemplates());
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to delete note template",
          }),
        );
        console.error(error);
        Bugsnag.notify(error);
      });
  };
