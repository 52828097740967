import Autocomplete from "@mui/material/Autocomplete";
import Paper from "@mui/material/Paper";
import { createContext, forwardRef, useContext, useState } from "react";
import { FixedSizeList } from "react-window";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";
import TeamOption from "./TeamOption";
import UserOption from "./UserOption";

const AutocompleteVirtualized = ({
  label,
  placeholder,
  options,
  disabledIds,
  clinicalLeadId,
  onSelect,
  className,
  isDoctors,
  isTeams,
}) => {
  const [autocompleteValue, setAutocompleteValue] = useState(null);
  const [inputValue, setInputValue] = useState("");

  const getOptionLabel = (option) => {
    if (isDoctors) {
      return option.display_name;
    }
    if (isTeams) {
      return option.leader.display_name;
    }
    return `${option.preferred_name ? option.preferred_name : option.first_name} ${option.last_name}`;
  };

  const getOptionDisabled = (option) => {
    if (isDoctors) {
      return disabledIds?.includes(option.doctor_id);
    }
    if (!isTeams) {
      return disabledIds?.includes(option.patient_id);
    }
  };

  const Row = ({ index, data, style }) => {
    const dataSet = data[index];
    return <div style={style}>{dataSet}</div>;
  };

  const OuterElementContext = createContext({});

  const OuterElementType = forwardRef((props, ref) => {
    const outerProps = useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
  });

  const ListboxComponent = forwardRef(({ children, ...props }, ref) => {
    const itemCount = children.length;
    const itemSize = 50;

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemSize;
      }
      return children.length * itemSize;
    };

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={props}>
          <FixedSizeList
            itemData={children}
            height={getHeight()}
            itemCount={itemCount}
            itemSize={itemSize}
            width="100%"
            outerElementType={OuterElementType}
            overscanCount={5}
          >
            {Row}
          </FixedSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  });

  const PaperComponent = ({ children, ...props }) => {
    return (
      <Paper
        {...props}
        sx={{
          margin: 0,
          padding: 0,
          borderRadius: "12px",
          "& > div > div > div": {
            padding: "0 !important",
          },
        }}
      >
        <div className="p-2 pr-1.5">{children}</div>
      </Paper>
    );
  };

  return (
    <div className={className}>
      {label && <p className="text-sm font-semibold mb-1.5">{label}</p>}
      <Autocomplete
        autoHighlight
        disableListWrap
        options={options}
        getOptionDisabled={getOptionDisabled}
        getOptionLabel={getOptionLabel}
        renderOption={(props, option) =>
          isTeams ? (
            <TeamOption option={option} {...props} key={option.id} />
          ) : (
            <UserOption
              isDoctors={isDoctors}
              clinicalLeadId={clinicalLeadId}
              option={option}
              {...props}
              key={isDoctors ? option.doctor_id : option.patient_id}
            />
          )
        }
        value={autocompleteValue}
        onChange={(_e, value) => {
          onSelect(value);
          setAutocompleteValue(null);
          setInputValue("");
        }}
        inputValue={inputValue}
        onInputChange={(_e, inputValue) => {
          setInputValue(inputValue);
        }}
        ListboxProps={{
          className:
            "scrollbar py-0 font-Mulish text-sm font-semibold text-zinc-700",
        }}
        ListboxComponent={ListboxComponent}
        PaperComponent={PaperComponent}
        renderInput={(params) => (
          <div ref={params.InputProps.ref} className="relative">
            <input
              type="text"
              placeholder={placeholder}
              {...params.inputProps}
              className={
                "py-2.5 pr-9 pl-3 rounded-lg border border-gray-300 w-full outline-none text-sm text-zinc-700 bg-white"
              }
            />
            <div className="absolute right-2.5 top-1/2 -translate-y-1/2">
              <SearchIcon width="18" height="18" stroke="#A0A6B2" />
            </div>
          </div>
        )}
      />
    </div>
  );
};

export default AutocompleteVirtualized;
