import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUsersWithoutTeam } from "../../../../store/user/thunks";
import AutocompleteVirtualized from "../../../Basic/Autocomplete/AutocompleteVirtualized";
import ProfilePicture from "../../../Basic/ProfilePicture";
import Button from "../../../Basic/Button";
import Input from "../../../Basic/Input";

const AddTeamForm = ({
  onClose = () => {},
  onNext = () => {},
  className = "",
}) => {
  const { usersWithoutTeam } = useSelector((state) => state.user);
  const [selectedLead, setSelectedLead] = useState(null);
  const [teamNameValue, setTeamNameValue] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsersWithoutTeam());
  }, [dispatch]);

  useEffect(() => {
    if (selectedLead) {
      setTeamNameValue(selectedLead.team?.name || selectedLead.display_name);
    }
  }, [selectedLead]);

  const handleSelectLead = (user) => {
    setSelectedLead(user);
  };

  return (
    <div className={`w-full grid grid-rows-note-layout ${className}`}>
      <div>
        <p className="text-base md:text-xl font-semibold mb-5 px-5">
          Add New Team
        </p>

        <div className="mb-4 px-5">
          <p className="text-sm font-semibold mb-1">Clinical Lead</p>
          {selectedLead && (
            <div className="w-full grid grid-cols-conv-layout gap-2 items-center rounded-lg py-2 px-2.5 mb-2 border border-gray-300">
              <div className="w-full flex items-center space-x-2 truncate">
                <ProfilePicture
                  firstName={selectedLead.first_name}
                  lastName={selectedLead.last_name}
                  src={selectedLead.profile_picture}
                />
                <div className="truncate">
                  <p className="text-sm font-bold truncate">
                    {selectedLead.display_name}
                  </p>
                  <p className="text-xs font-medium text-tertiary truncate">
                    {selectedLead.occupation}
                  </p>
                </div>
              </div>

              <div className="w-fit px-3 py-1 rounded-full border text-tertiary text-xs bg-gray-background whitespace-nowrap">
                Clinical lead
              </div>
            </div>
          )}
          <AutocompleteVirtualized
            options={usersWithoutTeam}
            onSelect={handleSelectLead}
            className="w-full bg-white rounded-lg"
            isDoctors
            placeholder="Select clinical lead"
          />
        </div>

        <Input
          label="Team Name"
          value={teamNameValue}
          onChange={(e) => setTeamNameValue(e.target.value)}
          name="teamName"
          placeholder="Type your team name"
          className="px-5"
        />
      </div>

      <div className="flex justify-end gap-4 font-semibold text-sm bg-gray-background py-4 px-5 mt-5">
        <Button
          variant="gray"
          onClick={(e) => {
            e.preventDefault();
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={!selectedLead}
          onClick={() => onNext(selectedLead, teamNameValue)}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default AddTeamForm;
