import LoadingProgressButton from "../LoadingProgressButton";

const RecordingSavingFooter = () => {
  return (
    <>
      <div className="hidden md:block w-full">
        <LoadingProgressButton label="Generating Note" />
      </div>
      <div className="block md:hidden w-full">
        <LoadingProgressButton label="Generating" />
      </div>
    </>
  );
};

export default RecordingSavingFooter;
