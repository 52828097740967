import Warning from "./index";
import noSoundDetectedImage from "../../../assets/no-sound-detected.svg";
import { useMediaQuery } from "react-responsive";

const NoSoundDetected = ({ onSubmit = () => {} }) => {
  const isDesktop = useMediaQuery({ minWidth: 768 });

  return (
    <Warning
      open={true}
      image={noSoundDetectedImage}
      title="No sound detected"
      description="Did you pick the correct microphone in the settings?"
      submitText="Try again"
      horizontal={!isDesktop}
      onSubmit={onSubmit}
      onCancel={onSubmit}
    />
  );
};

export default NoSoundDetected;
