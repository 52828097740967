export const LOGOUT = "LOGOUT";
export const SET_PROVIDER_NOTES = "SET_PROVIDER_NOTES";
export const SET_PROVIDER_NOTE = "SET_PROVIDER_NOTE";
export const SET_PROVIDER_NOTE_CONTENT = "SET_PROVIDER_NOTE_CONTENT";
export const SET_PROVIDER_NOTES_RECORDING = "SET_PROVIDER_NOTES_RECORDING";
export const SET_PROVIDER_NOTES_TOGGLE_PAUSE =
  "SET_PROVIDER_NOTES_TOGGLE_PAUSE";
export const SET_PROVIDER_NOTES_RECORDING_CANCELLED =
  "SET_PROVIDER_NOTES_RECORDING_CANCELLED";
export const SET_PROVIDER_NOTES_LOADING = "SET_PROVIDER_NOTES_LOADING";
export const SET_PROVIDER_NOTE_DELETED = "SET_PROVIDER_NOTE_DELETED";
export const SET_NOTE_TEMPLATES = "SET_NOTE_TEMPLATES";
export const SET_DEFAULT_NOTE_TEMPLATE = "SET_DEFAULT_NOTE_TEMPLATE";
export const SET_SELECTED_NOTE_TEMPLATE = "SET_SELECTED_NOTE_TEMPLATE";
export const SET_SHOW_SCRIBE_NOTE_TEMPLATE = "SET_SHOW_SCRIBE_NOTE_TEMPLATE";
export const SET_SELECTED_SCRIBE_NOTE_TEMPLATE =
  "SET_SELECTED_SCRIBE_NOTE_TEMPLATE";
export const SET_INTERRUPTED_RECORDING_ACTION =
  "SET_INTERRUPTED_RECORDING_ACTION";
export const SET_IS_CURRENTLY_RECORDING = "SET_IS_CURRENTLY_RECORDING";

export const SetInterruptedRecordingActionType = {
  NAVIGATION: "NAVIGATION",
  SETTINGS_NAVIGATION: "SETTINGS_NAVIGATION",
  PATIENT_PROFILE_CLOSE: "PATIENT_PROFILE_CLOSE",
  NEW_ENROLLMENT: "NEW_ENROLLMENT",
  PATIENT_PROFILE_TAB_CHANGE: "PATIENT_PROFILE_TAB_CHANGE",
};
