import ClickAwayListener from "@mui/base/ClickAwayListener";
import { useEffect } from "react";

const Modal = ({
  onClickAway = () => {},
  children,
  className = "p-6 rounded-2xl",
  bg = "white",
  ...props
}) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  return (
    <div
      className="fixed h-screen-dynamic w-screen inset-0 bg-black/50 flex items-center justify-center z-50 cursor-default"
      {...props}
    >
      <ClickAwayListener onClickAway={onClickAway}>
        <div
          className={`bg-${bg} max-w-[90vw] max-h-90vh-dynamic flex overflow-hidden ${className}`}
        >
          {children}
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default Modal;
