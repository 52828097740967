import { useState } from "react";
import Modal from "../../../Basic/Modal";
import TemplateEditModal from "./TemplateEditModal";
import CreateFromNoteModal from "./CreateFromNoteModal";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/x-mark.svg";
import { ReactComponent as FromScratchIcon } from "../../../../assets/icons/add.svg";
import { ReactComponent as TemplatesIcon } from "../../../../assets/icons/notes-templates.svg";

const CreateTemplateModal = ({
  onClose = () => {},
  onCreateFromScratch = () => {},
  onGenerateFromNote = () => {},
}) => {
  const [fromScratchHovered, setFromScratchHovered] = useState(false);
  const [fromTemplateHovered, setFromTemplateHovered] = useState(false);
  const [openFromScratchModal, setOpenFromScratchModal] = useState(false);
  const [openFromTemplateModal, setOpenFromTemplateModal] = useState(false);

  return (
    <>
      {!openFromScratchModal && !openFromTemplateModal && (
        <Modal onClickAway={onClose}>
          <div className="w-120 overflow-hidden">
            <div className="flex items-center justify-between mb-6">
              <p className="text-xl font-semibold">
                Type of template to create
              </p>
              <button type="button" onClick={onClose}>
                <CloseIcon width="20" height="20" />
              </button>
            </div>

            <button
              type="button"
              className="w-full p-5 border border-gray-foreground rounded-lg cursor-pointer space-y-2.5 text-left
                enabled:hover:border-primary-blue enabled:hover:bg-primary-blue-light disabled:cursor-default"
              onMouseEnter={() => setFromScratchHovered(true)}
              onMouseLeave={() => setFromScratchHovered(false)}
              onClick={() => setOpenFromScratchModal(true)}
            >
              <div className="flex items-center space-x-3">
                <FromScratchIcon
                  width="24"
                  height="24"
                  stroke={fromScratchHovered ? "#2970FF" : "#667085"}
                />
                <p className="text-xl font-semibold">From scratch</p>
              </div>
              <p className="text-base font-medium text-tertiary">
                Design a clinical note template by manually choosing sections
              </p>
            </button>

            <button
              disabled
              type="button"
              className="hidden w-full p-5 border border-gray-foreground rounded-lg cursor-pointer space-y-2.5 mt-5 text-left
                enabled:hover:border-primary-blue enabled:hover:bg-primary-blue-light disabled:cursor-default"
              onMouseEnter={() => setFromTemplateHovered(true)}
              onMouseLeave={() => setFromTemplateHovered(false)}
              onClick={() => setOpenFromTemplateModal(true)}
            >
              <div className="flex items-center space-x-3">
                <TemplatesIcon
                  width="24"
                  height="24"
                  stroke={fromTemplateHovered ? "#2970FF" : "#667085"}
                />
                <p className="text-xl font-semibold">From a previous note</p>
              </div>
              <p className="text-base font-medium text-tertiary">
                Clone a note you use frequently by learning your style and
                automatically generating sections
              </p>
            </button>
          </div>
        </Modal>
      )}

      {openFromScratchModal && (
        <TemplateEditModal
          onClose={onClose}
          onSubmit={onCreateFromScratch}
          editableTitle
          editableSections
        />
      )}

      {openFromTemplateModal && (
        <CreateFromNoteModal
          onClose={onClose}
          onGenerate={onGenerateFromNote}
        />
      )}
    </>
  );
};

export default CreateTemplateModal;
