import React, { useMemo } from "react";

const TOTAL_BARS = 8;
const MAX_VOLUME = 100;
const ACTIVE_BAR_CLASS = "bg-primary-blue";
const INACTIVE_BAR_CLASS = "bg-gray-300";

const MicrophoneTestVolumeBars = React.memo(({ volume }) => {
  const calculateVolumeForBar = useMemo(() => {
    return (volume, index, totalBars) => {
      const normalizedIndex = (index + 1) / totalBars;
      const scale = Math.exp(normalizedIndex * Math.log(MAX_VOLUME)) - 1;
      return volume >= scale;
    };
  }, []);

  const barStates = useMemo(() => {
    return Array.from({ length: TOTAL_BARS }, (_, index) =>
      calculateVolumeForBar(volume, index, TOTAL_BARS),
    );
  }, [volume, calculateVolumeForBar]);

  return (
    <div className="flex h-5">
      {barStates.map((isActive, index) => (
        <div
          key={index}
          className={`grow h-full w-1 my-0 ml-1.5 rounded-full ${isActive ? ACTIVE_BAR_CLASS : INACTIVE_BAR_CLASS}`}
        />
      ))}
    </div>
  );
});

export default MicrophoneTestVolumeBars;
