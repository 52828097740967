import { useEffect } from "react";
import { availableTags } from "../../helpers/constants";

const TextareaTags = ({
  label,
  value,
  placeholder,
  onChange,
  onFocus,
  onBlur,
  className,
  contentRef,
}) => {
  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.innerHTML = value ? parseTags(value) : "";
    }
  }, [value, contentRef]);

  const parseTags = (text) => {
    return text.replace(/{{([^}]+)}}/g, (_, keyword) => {
      let capitalizedKeyword;
      const tag = availableTags.find((tag) => tag.value === `{{${keyword}}}`);
      if (tag) {
        capitalizedKeyword = tag.name;
      } else {
        capitalizedKeyword = keyword
          .toLowerCase()
          .replace(/\b\w/g, (char) => char.toUpperCase());
      }
      return `<span contenteditable="false" class="py-0.5 px-1.5 border border-gray-300 rounded-md text-xs">${capitalizedKeyword}</span>`;
    });
  };

  return (
    <div className={`flex flex-col text-sm ${className}`}>
      {label && <p className="font-semibold mb-1.5">{label}</p>}
      <div
        ref={contentRef}
        contentEditable
        placeholder={placeholder}
        onFocus={onFocus}
        onBlur={onBlur}
        onInput={onChange}
        className="w-full resize-none px-3 py-2.5 outline-none focus:outline-none focus:ring-0 text-sm rounded-lg
          border border-gray-300 align-top scrollbar whitespace-pre-wrap leading-relaxed bg-white"
      />
    </div>
  );
};

export default TextareaTags;
