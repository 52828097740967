import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as PhoneIcon } from "../assets/icons/calling-gray.svg";
import { ReactComponent as MessageIcon } from "../assets/icons/chat-round.svg";
import { ReactComponent as ScribeIcon } from "../assets/icons/microphone-scribe.svg";
import { ReactComponent as CloseIcon } from "../assets/icons/x-mark.svg";
import DeleteModal from "../components/Basic/DeleteModal";
import ProfilePicture from "../components/Basic/ProfilePicture";
import TabButtonRound from "../components/Basic/TabButtonRound";
import {
  setInterruptedRecordingActionAction,
  setShowScribeNoteTemplateAction,
} from "../components/IndependentScribe/store/actions";
import { SetInterruptedRecordingActionType } from "../components/IndependentScribe/store/types";
import EnrollmentEditFields from "../components/PatientProfile/CareCoordination/EnrollmentEditFields";
import EnrollmentEditHeader from "../components/PatientProfile/CareCoordination/EnrollmentEditHeader";
import EnrollmentsTab from "../components/PatientProfile/CareCoordination/EnrollmentsTab";
import HistoryTab from "../components/PatientProfile/History/HistoryTab";
import InformationTab from "../components/PatientProfile/Information/InformationTab";
import NoteHeader from "../components/PatientProfile/Notes/NoteHeader";
import NoteInfo from "../components/PatientProfile/Notes/NoteInfo";
import NotePreview from "../components/PatientProfile/Notes/NotePreview";
import NotesTab from "../components/PatientProfile/Notes/NotesTab";
import PatientShortInfo from "../components/PatientProfile/PatientShortInfo";
import {
  NoteTabs,
  patientPanelTabs,
  patientProfileTabs,
} from "../helpers/constants";
import { useActivityTracker } from "../hooks/useActivityTracker";
import { openConversationEvent } from "../store/billingMetrics/thunks";
import { cancelUpcomingMessage } from "../store/campaign/thunks";
import {
  setConversationsUpdatedAction,
  setMessagesAction,
} from "../store/conversation/actions";
import {
  setLastSelectedPatientProfileAction,
  setPatientRecordingScribeTitleAction,
  setProfileConversationAction,
  setProfileLoadingAction,
  setSelectedPatientEnrollmentsAction,
  setSelectedPatientInfoAction,
  setSelectedPatientNoteAction,
  setSelectedPatientNotesAction,
  setSelectedPatientPanelTabAction,
  setSelectedPatientProfileTabAction,
} from "../store/patient/actions";
import {
  getPatientEnrollments,
  getPatientInfo,
  getPatientNotes,
  getProfileConversation,
} from "../store/patient/thunks";
import {
  setOpenCallModalAction,
  setOpenCallOptionsModalAction,
} from "../store/voiceRecorder/actions";

const buttonClasses =
  "w-full h-full flex flex-col items-center justify-center text-base font-medium text-tertiary";

const PatientProfile = () => {
  const { id } = useParams();
  const { user } = useSelector((state) => state.user);
  const {
    selectedPatientInfo,
    selectedPatientNotes,
    selectedPatientNote,
    selectedPatientEnrollments,
    selectedPatientProfileTab,
    lastSelectedPatientProfile,
    profileConversation,
    patientRecordingScribeTitle,
  } = useSelector((state) => state.patient);
  const {
    showScribeNoteTemplate,
    selectedScribeNoteTemplate,
    isCurrentlyRecording,
  } = useSelector((state) => state.scribe);
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const isWidthLg = useMediaQuery({ minWidth: 1024 });
  const isHeightSm = useMediaQuery({ minHeight: 640 });
  const { INFORMATION, ENROLLMENTS, NOTES, HISTORY } = patientProfileTabs;
  const { NOTE } = NoteTabs;
  const [editEnrollment, setEditEnrollment] = useState(false);
  const [selectedEnrollment, setSelectedEnrollment] = useState(null);
  const [activeNoteTab, setActiveNoteTab] = useState(NOTE);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const doTracking = profileConversation
    ? profileConversation.trackable
    : false;
  const { restartTimer } = useActivityTracker(doTracking);

  useEffect(() => {
    if (!user.customer.has_ehr_data && selectedPatientProfileTab === HISTORY) {
      dispatch(setSelectedPatientProfileTabAction(NOTES));
    }
  }, [user, dispatch]);

  useEffect(() => {
    if (
      lastSelectedPatientProfile?.info &&
      Number(id) === lastSelectedPatientProfile.info.patient_id
    ) {
      if (!selectedPatientInfo) {
        dispatch(setSelectedPatientInfoAction(lastSelectedPatientProfile.info));
      }
      dispatch(setSelectedPatientNotesAction(lastSelectedPatientProfile.notes));
      dispatch(
        setSelectedPatientEnrollmentsAction(
          lastSelectedPatientProfile.enrollments,
        ),
      );
    } else {
      dispatch(setProfileLoadingAction(true));
      dispatch(
        getPatientInfo(id, (patientInfo) => {
          dispatch(setSelectedPatientInfoAction(patientInfo));
        }),
      );
    }

    dispatch(setConversationsUpdatedAction(false));

    return () => {
      dispatch(setSelectedPatientInfoAction(null));
      dispatch(setSelectedPatientNotesAction(null));
      dispatch(setSelectedPatientEnrollmentsAction(null));

      dispatch(setProfileConversationAction(null));
      dispatch(setOpenCallOptionsModalAction(false));
      dispatch(setOpenCallModalAction(false));

      dispatch(setSelectedPatientNoteAction(null));
      dispatch(setMessagesAction({}));

      dispatch(setShowScribeNoteTemplateAction(false));
    };
  }, [id, dispatch]);

  useEffect(() => {
    if (selectedPatientInfo?.conversation_id) {
      dispatch(getProfileConversation(selectedPatientInfo.conversation_id));
    }
  }, [selectedPatientInfo, dispatch]);

  useEffect(() => {
    (async () => {
      if (doTracking) {
        restartTimer();
        await openConversationEvent(
          profileConversation.patient.patient_id,
          profileConversation.campaign_type,
        );
      }
    })();
  }, [profileConversation]);

  const setActiveTabPatientProfile = (tab) => {
    dispatch(setSelectedPatientProfileTabAction(tab));
  };

  const onCloseEditEnrollment = () => {
    setEditEnrollment(false);
    setSelectedEnrollment(null);
  };

  const onDeleteEnrollment = (e) => {
    e?.preventDefault();
    setOpenDeleteModal(true);
  };

  const deleteEnrollment = () => {
    setEditEnrollment(false);
    setSelectedEnrollment(null);
    dispatch(
      cancelUpcomingMessage(
        selectedEnrollment.campaign_id,
        selectedEnrollment.campaign_patient_id,
        selectedEnrollment.patient.patient_id,
        () => {
          dispatch(getPatientEnrollments(id));
        },
      ),
    );
  };

  const onCloseNote = () => {
    dispatch(setSelectedPatientNoteAction(null));
    setActiveNoteTab(NOTE);
    dispatch(setShowScribeNoteTemplateAction(false));
  };

  const onSaveNote = () => {
    dispatch(getPatientNotes(id));
  };

  const onCloseProfile = (e) => {
    if (isCurrentlyRecording) {
      e.preventDefault();
      dispatch(
        setInterruptedRecordingActionAction({
          type: SetInterruptedRecordingActionType.PATIENT_PROFILE_CLOSE,
        }),
      );

      return;
    }

    dispatch(
      setLastSelectedPatientProfileAction({
        info: selectedPatientInfo,
        notes: selectedPatientNotes,
        enrollments: selectedPatientEnrollments,
      }),
    );
    navigate(-1);
  };

  return (
    <div
      className={`relative flex flex-col overflow-x-auto min-h-0
        ${isDesktop && isHeightSm ? "py-4 pl-4 height-md:py-6 height-md:pl-6" : "md:py-2 md:pl-2"}`}
    >
      {!isDesktop && editEnrollment && selectedEnrollment ? (
        <EnrollmentEditHeader
          onClose={onCloseEditEnrollment}
          enrollment={selectedEnrollment}
        />
      ) : !isDesktop && selectedPatientNote ? (
        <NoteHeader
          note={selectedPatientNote}
          onClose={onCloseNote}
          activeTab={activeNoteTab}
          setActiveTab={setActiveNoteTab}
        />
      ) : !isDesktop && showScribeNoteTemplate && selectedScribeNoteTemplate ? (
        <NoteHeader
          note={{ title: patientRecordingScribeTitle }}
          onClose={onCloseNote}
          activeTab={activeNoteTab}
          setActiveTab={setActiveNoteTab}
        />
      ) : (
        <div className="px-4 md:px-0 pt-3 md:pt-0 overflow-x-hidden flex flex-col flex-none">
          <div className="grid grid-cols-conv-layout md:grid-cols-2-right md:gap-4 w-full">
            {isDesktop && (
              <button
                type="button"
                onClick={onCloseProfile}
                className="w-6 h-8 md:w-8 md:bg-white md:border rounded-lg flex items-center justify-center flex-none"
              >
                <CloseIcon stroke="#121212" width="18" height="18" />
              </button>
            )}

            {selectedPatientInfo && (
              <div className="w-full truncate">
                <div className="flex items-center md:items-start truncate">
                  <ProfilePicture
                    firstName={
                      selectedPatientInfo.preferred_name
                        ? selectedPatientInfo.preferred_name
                        : selectedPatientInfo.first_name
                    }
                    lastName={selectedPatientInfo.last_name}
                    src={selectedPatientInfo.profile_picture}
                    size={isWidthLg ? 16 : 12}
                    fontSize={isWidthLg ? "2xl" : "lg"}
                    className="bg-[#FF9800] flex-none text-2xl hidden lg:flex lg:mr-4"
                    bgColor="[#FF9800]"
                    textColor="white"
                    flagPadding={false}
                  />
                  <div className="truncate">
                    <p className="font-semibold mt-1 md:mt-0 truncate text-base md:text-xl lg:text-2xl">
                      {selectedPatientInfo.preferred_name
                        ? selectedPatientInfo.preferred_name
                        : selectedPatientInfo.first_name}{" "}
                      {selectedPatientInfo.last_name}
                    </p>
                    {isDesktop && (
                      <PatientShortInfo
                        patient={selectedPatientInfo}
                        className="mt-1.5 pb-0.5 flex-wrap text-xs md:text-sm lg:text-base"
                      />
                    )}
                  </div>
                </div>

                {!isDesktop && (
                  <PatientShortInfo
                    patient={selectedPatientInfo}
                    className="mt-1 pb-0.5 truncate text-xs text-tertiary"
                  />
                )}
              </div>
            )}

            {!isDesktop && (
              <button
                type="button"
                onClick={onCloseProfile}
                className="w-6 h-8 md:w-8 md:bg-white md:border rounded-lg flex items-center justify-center flex-none"
              >
                <CloseIcon stroke="#121212" width="18" height="18" />
              </button>
            )}
          </div>

          {editEnrollment ? (
            <EnrollmentEditHeader
              onClose={onCloseEditEnrollment}
              enrollment={selectedEnrollment}
            />
          ) : selectedPatientNote ? (
            <NoteHeader
              note={selectedPatientNote}
              onClose={onCloseNote}
              activeTab={activeNoteTab}
              setActiveTab={setActiveNoteTab}
            />
          ) : showScribeNoteTemplate && selectedScribeNoteTemplate ? (
            <NoteHeader
              note={{ title: patientRecordingScribeTitle }}
              onClose={onCloseNote}
              activeTab={activeNoteTab}
              setActiveTab={setActiveNoteTab}
              editableTitle
              onUpdateTitle={(title) =>
                dispatch(setPatientRecordingScribeTitleAction(title))
              }
            />
          ) : (
            <div className="flex min-w-0 space-x-2 whitespace-nowrap overflow-auto scrollbar pb-0.5 my-5">
              <TabButtonRound
                label={NOTES}
                active={selectedPatientProfileTab}
                onClick={() => setActiveTabPatientProfile(NOTES)}
              />
              <TabButtonRound
                label={ENROLLMENTS}
                active={selectedPatientProfileTab}
                onClick={() => setActiveTabPatientProfile(ENROLLMENTS)}
              />
              <TabButtonRound
                label={INFORMATION}
                active={selectedPatientProfileTab}
                onClick={() => setActiveTabPatientProfile(INFORMATION)}
              />
              {user.customer.has_ehr_data && (
                <TabButtonRound
                  label={HISTORY}
                  active={selectedPatientProfileTab}
                  onClick={() => setActiveTabPatientProfile(HISTORY)}
                />
              )}
            </div>
          )}
        </div>
      )}

      <div
        className={`w-full h-full overflow-y-auto scrollbar
          ${
            editEnrollment || selectedPatientNote || showScribeNoteTemplate
              ? `bg-white rounded-b-xl border-x border-b md:pt-0
                ${selectedPatientNote || showScribeNoteTemplate ? "" : "px-4 pt-4"}`
              : "md:pt-2 px-4 md:px-0"
          }`}
      >
        {editEnrollment && selectedEnrollment && selectedPatientInfo ? (
          <div className="w-full h-full pr-1">
            <EnrollmentEditFields
              enrollment={selectedEnrollment}
              patient={selectedPatientInfo}
            />
          </div>
        ) : selectedPatientNote && selectedPatientInfo ? (
          <NoteInfo
            scribe={selectedPatientNote}
            onSaveNote={onSaveNote}
            activeTab={activeNoteTab}
            patient={selectedPatientInfo}
          />
        ) : showScribeNoteTemplate &&
          selectedPatientInfo &&
          selectedScribeNoteTemplate ? (
          <NotePreview
            title="Scribe"
            sections={selectedScribeNoteTemplate.sections}
          />
        ) : (
          <div className="w-full min-h-fit pr-1 flex-none">
            {selectedPatientProfileTab === NOTES && <NotesTab />}
            {selectedPatientProfileTab === ENROLLMENTS && (
              <EnrollmentsTab
                onDeleteEnrollment={onDeleteEnrollment}
                setSelectedEnrollment={setSelectedEnrollment}
              />
            )}
            {selectedPatientProfileTab === INFORMATION &&
              selectedPatientInfo && (
                <InformationTab patientInfo={selectedPatientInfo} />
              )}
            {user.customer.has_ehr_data &&
              selectedPatientProfileTab === HISTORY && (
                <HistoryTab patientInfo={selectedPatientInfo} />
              )}
          </div>
        )}
      </div>

      {!isDesktop && !editEnrollment && !selectedPatientNote && (
        <div className="fixed bottom-5 w-full px-5">
          <div className="bg-white w-full h-16 rounded-full border grid items-center shadow-button grid-cols-3">
            <button
              type="button"
              onClick={() => {
                dispatch(setOpenCallOptionsModalAction(true));
                dispatch(
                  setSelectedPatientPanelTabAction(patientPanelTabs.MESSAGE),
                );
              }}
              className={buttonClasses}
            >
              <MessageIcon width="19" height="19" />
              <p>Message</p>
            </button>
            <button
              type="button"
              onClick={() => {
                dispatch(setOpenCallOptionsModalAction(true));
                dispatch(
                  setSelectedPatientPanelTabAction(patientPanelTabs.CALL),
                );
              }}
              className={buttonClasses}
            >
              <PhoneIcon width="20" height="20" />
              <p>Call</p>
            </button>
            <button
              type="button"
              onClick={() => {
                dispatch(setOpenCallOptionsModalAction(true));
                dispatch(
                  setSelectedPatientPanelTabAction(patientPanelTabs.SCRIBE),
                );
              }}
              className={buttonClasses}
            >
              <ScribeIcon width="20" height="20" />
              <p>Scribe</p>
            </button>
          </div>
        </div>
      )}

      {openDeleteModal && selectedEnrollment && (
        <DeleteModal
          title="Delete Campaign"
          description="Are you sure you want to stop this campaign? This action cannot be undone."
          onClose={() => setOpenDeleteModal(false)}
          onDelete={deleteEnrollment}
        />
      )}
    </div>
  );
};

export default PatientProfile;
