import { useCallback, useState } from "react";
import NewScribe from "../../PatientProfile/NewScribe";
import ScribeHeader from "../ScribeHeader";
import ScribeRecordingFooter from "../ScribeRecordingFooter";
import ScribeViewDetails from "../ScribeViewDetails";
import ScribeRecording from "../ScribeRecording";
import ScribeViewDetailsFooter from "../ScribeViewDetailsFooter";
import AssignPatientModal from "../AssignPatientModal";
import { NOTE_NOT_SELECTED } from "../consts";
import ScribeViewDetailsSkeleton from "../ScribeViewDetailsSkeleton";

const ScribeContainer = ({
  selectedAudioId,
  saveRecording,
  patient,
  scribe,
  mediaRecorder,
  recorderState,
  loadMicrophones,
  microphoneId,
  microphones,
  setMicrophoneId,
  userActionRef,
  recordingStarted,
  setRecordingStarted,
  setInputLanguage,
  onBack,
  onCancel,
  onBackCancelRecording,
  handleAssignPatientId,
  togglePause,
}) => {
  const [showAssignPatientModal, setShowAssignPatientModal] = useState(false);
  const isSaving = recorderState.isSaving;

  const onStart = useCallback(
    (data) => {
      if (recordingStarted) {
        onBackCancelRecording();
        return;
      }

      setRecordingStarted();
      setInputLanguage(data.inputLanguage);
    },
    [
      setInputLanguage,
      onBackCancelRecording,
      recordingStarted,
      setRecordingStarted,
    ],
  );

  const renderScribeView = () => (
    <>
      {scribe?.note ? (
        <ScribeViewDetails scribe={scribe} />
      ) : (
        <ScribeViewDetailsSkeleton scribe={scribe} />
      )}
      <ScribeViewDetailsFooter scribe={scribe} />
    </>
  );

  const renderScribeRecording = () => (
    <>
      <main className="h-full pb-6 pr-1 overflow-y-auto scrollbar">
        <ScribeRecording scribe={scribe} />
      </main>
      <ScribeRecordingFooter
        recorderState={recorderState}
        mediaRecorder={mediaRecorder}
        cancelRecording={onCancel}
        saveRecording={saveRecording}
        isSaving={isSaving}
        togglePause={togglePause}
      />
    </>
  );

  const renderNewScribe = () => (
    <div className="px-8 py-5 h-full w-full min-h-0 overflow-y-auto overflow-x-hidden scrollbar grid">
      <NewScribe
        onStart={onStart}
        mediaRecorder={mediaRecorder}
        loadMicrophones={loadMicrophones}
        microphoneId={microphoneId}
        microphones={microphones}
        setMicrophoneId={setMicrophoneId}
      />
    </div>
  );

  const isNoteSelected = selectedAudioId !== NOTE_NOT_SELECTED;

  return (
    <>
      <ScribeHeader
        handleAssignPatientId={(patientId) => {
          userActionRef.current = true;
          handleAssignPatientId(patientId);
        }}
        onBack={() => {
          if (recordingStarted) {
            onBackCancelRecording();
            return;
          }

          onBack();
        }}
        scribe={scribe}
        patient={patient}
        setShowAssignPatientModal={setShowAssignPatientModal}
      />
      {(isNoteSelected && !!scribe) || recordingStarted || isSaving ? (
        <div className="w-full h-full min-h-0 flex flex-col md:justify-between px-4 md:px-0 pt-5 pb-18">
          {isNoteSelected ? renderScribeView() : renderScribeRecording()}
        </div>
      ) : (
        renderNewScribe()
      )}
      {showAssignPatientModal && (
        <AssignPatientModal
          handleClose={() => setShowAssignPatientModal(false)}
          handleAssignPatientId={(patientId) => {
            userActionRef.current = true;
            handleAssignPatientId(patientId);
          }}
        />
      )}
    </>
  );
};

export default ScribeContainer;
