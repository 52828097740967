import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as ClockIcon } from "../../assets/icons/clock.svg";
import {
  getFormattedTimeColon,
  timeStringToSeconds,
} from "../../helpers/helpers";
import { setCurrentBillingPatientAction } from "../../store/billingMetrics/actions";
import {
  adjustBillingPatient,
  getBillingPatient,
  getBillingPatients,
  removeAdjustedTime,
} from "../../store/billingMetrics/thunks";
import Button from "../Basic/Button";
import LoaderDots from "../Basic/LoaderDots";
import Modal from "../Basic/Modal";
import ModalMobile from "../Basic/ModalMobile";
import TimeEditing from "./TimeEditing";

const TimeButton = ({ patient, className = "w-full" }) => {
  const {
    currentBillingPatient,
    currentDateStamp,
    timeAdjustLoading,
    rowsPerPage,
    currPage,
    selectedBillingType,
    selectedCptCode,
    sortedBy,
    sortingDirection,
    billingSearchValue,
  } = useSelector((state) => state.billingMetrics);
  const [openModal, setOpenModal] = useState(false);
  const [combinedStaffData, setCombinedStaffData] = useState(null);
  const [breakdownData, setBreakdownData] = useState(null);
  const [additionalTime, setAdditionalTime] = useState(0);
  const [hover, setHover] = useState(false);
  const [staffAdjusted, setStaffAdjusted] = useState([]);
  const [adjustmentsToRemove, setAdjustmentsToRemove] = useState([]);
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentBillingPatient?.staff) {
      const combinedData = {};

      currentBillingPatient.staff.forEach((item) => {
        const { doctor_id, first_name, last_name, seconds } = item;

        if (!item.reason) {
          if (combinedData[doctor_id]) {
            combinedData[doctor_id].seconds += seconds;
          } else {
            combinedData[doctor_id] = {
              doctor_id,
              first_name,
              last_name,
              seconds,
            };
          }
        }
      });

      const result = Object.values(combinedData);
      setCombinedStaffData(result);
      setBreakdownData(result);
    }
  }, [currentBillingPatient]);

  const onOpenTimeModal = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setOpenModal(true);
    dispatch(
      getBillingPatient(
        patient.patientId,
        currentDateStamp,
        patient.billingType,
      ),
    );
  };

  const onCloseTimeModal = () => {
    dispatch(setCurrentBillingPatientAction(null));
    setOpenModal(false);
    setAdditionalTime(0);
    setBreakdownData(null);
    setStaffAdjusted([]);
  };

  const getDoctorSecondsDiff = (doctorId) => {
    const initial = combinedStaffData.find((doc) => doc.doctor_id === doctorId);
    const adjusted = breakdownData.find((doc) => doc.doctor_id === doctorId);

    if (adjusted !== undefined && adjusted.seconds !== undefined) {
      return adjusted.seconds - initial.seconds;
    }

    return 0;
  };

  const onConfirm = () => {
    const totalSecondsDiff =
      getTotalWithAdditionalSeconds() - currentBillingPatient.totalSeconds;

    const staff = combinedStaffData.map((doctor) => {
      return {
        doctorId: doctor.doctor_id,
        secondsAdjusted: getDoctorSecondsDiff(doctor.doctor_id),
      };
    });

    staffAdjusted.forEach((doctor) => {
      staff.push(doctor);
    });

    if (adjustmentsToRemove.length > 0) {
      dispatch(
        removeAdjustedTime(
          currentBillingPatient.monthlyBillId,
          adjustmentsToRemove,
          () => {
            dispatch(
              getBillingPatient(
                patient.patientId,
                currentDateStamp,
                patient.billingType,
              ),
            );
            dispatch(
              getBillingPatients(
                currentDateStamp,
                rowsPerPage * currPage,
                billingSearchValue,
                selectedBillingType,
                selectedCptCode,
                sortedBy,
                sortingDirection,
              ),
            );
          },
        ),
      );
    }

    dispatch(
      adjustBillingPatient(
        currentBillingPatient.monthlyBillId,
        totalSecondsDiff,
        staff,
        () => {
          dispatch(
            getBillingPatient(
              patient.patientId,
              currentDateStamp,
              patient.billingType,
            ),
          );
          dispatch(
            getBillingPatients(
              currentDateStamp,
              rowsPerPage * currPage,
              billingSearchValue,
              selectedBillingType,
              selectedCptCode,
              sortedBy,
              sortingDirection,
            ),
          );
        },
      ),
    );
  };

  const getTotalWithAdditionalSeconds = () => {
    if (
      !currentBillingPatient ||
      !breakdownData ||
      !combinedStaffData ||
      !staffAdjusted
    ) {
      return 0;
    }

    return (
      currentBillingPatient.totalSeconds +
      (breakdownData.reduce((acc, curr) => acc + curr.seconds, 0) -
        combinedStaffData.reduce((acc, curr) => acc + curr.seconds, 0)) +
      staffAdjusted.reduce((acc, curr) => acc + curr.secondsAdjusted, 0)
    );
  };

  return (
    <div
      className={`h-full flex items-center justify-center ${className}`}
      onClick={onOpenTimeModal}
    >
      <button
        type="button"
        className="h-7 w-fit px-2.5 flex items-center justify-center space-x-1.5 bg-gray-foreground rounded-full
          text-sm font-semibold text-tertiary hover:bg-primary-blue-light hover:text-primary-blue"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <ClockIcon
          width="16"
          height="16"
          stroke={hover ? "#2970FF" : "#667085"}
        />
        <p>
          {patient.totalTime
            ? getFormattedTimeColon(timeStringToSeconds(patient.totalTime))
            : "0:00"}
        </p>
      </button>

      {openModal && isDesktop && (
        <Modal
          className="rounded-2xl text-primary"
          onClickAway={onCloseTimeModal}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <div className="w-[560px] max-w-full min-h-[508px] overflow-y-auto scrollbar grid grid-rows-chat-layout">
            <div className="flex justify-between p-5">
              <p className="text-xl font-semibold">
                {patient.preferred_name
                  ? patient.preferred_name
                  : patient.first_name}{" "}
                {patient.last_name}
              </p>
            </div>

            <TimeEditing
              billingType={patient.billingType}
              billingPatient={currentBillingPatient}
              combinedStaffData={combinedStaffData}
              additionalTime={additionalTime}
              setAdditionalTime={setAdditionalTime}
              breakdownData={breakdownData}
              setBreakdownData={setBreakdownData}
              staffAdjusted={staffAdjusted}
              setStaffAdjusted={setStaffAdjusted}
              editableAdjustments={
                currentDateStamp ===
                dayjs().startOf("month").format("YYYY-MM-DD")
              }
              adjustmentsToRemove={adjustmentsToRemove}
              setAdjustmentsToRemove={setAdjustmentsToRemove}
              className="px-5"
            />

            <div className="w-full bg-gray-background py-4 px-5 flex justify-end space-x-4 text-sm font-semibold mt-5">
              <Button variant="gray" onClick={onCloseTimeModal}>
                Cancel
              </Button>
              <Button
                className="w-[124px]"
                onClick={() => {
                  onConfirm();
                  onCloseTimeModal();
                }}
                disabled={!currentBillingPatient || timeAdjustLoading}
              >
                {timeAdjustLoading ? (
                  <LoaderDots color="disabled" />
                ) : (
                  "Save Changes"
                )}
              </Button>
            </div>
          </div>
        </Modal>
      )}

      {!isDesktop && (
        <ModalMobile
          open={openModal}
          childHeight={700}
          onClickAway={onCloseTimeModal}
          className=""
        >
          <div className="w-full max-w-full grid grid-rows-chat-layout">
            <div className="flex justify-between px-5 pb-5">
              <p className="text-xl font-semibold">
                {patient.preferred_name
                  ? patient.preferred_name
                  : patient.first_name}{" "}
                {patient.last_name}
              </p>
            </div>

            <TimeEditing
              billingType={patient.billingType}
              billingPatient={currentBillingPatient}
              combinedStaffData={combinedStaffData}
              additionalTime={additionalTime}
              setAdditionalTime={setAdditionalTime}
              breakdownData={breakdownData}
              setBreakdownData={setBreakdownData}
              staffAdjusted={staffAdjusted}
              setStaffAdjusted={setStaffAdjusted}
              editableAdjustments={
                currentDateStamp ===
                dayjs().startOf("month").format("YYYY-MM-DD")
              }
              adjustmentsToRemove={adjustmentsToRemove}
              setAdjustmentsToRemove={setAdjustmentsToRemove}
              className="px-5"
            />

            <div className="w-full bg-gray-background py-4 px-5 flex justify-end space-x-4 text-sm font-semibold mt-5">
              <Button
                variant="gray"
                onClick={onCloseTimeModal}
                className="w-full"
              >
                Cancel
              </Button>
              <Button
                className="w-full"
                onClick={() => {
                  onConfirm();
                  onCloseTimeModal();
                }}
                disabled={!currentBillingPatient || timeAdjustLoading}
              >
                {timeAdjustLoading ? (
                  <LoaderDots color="disabled" />
                ) : (
                  "Save Changes"
                )}
              </Button>
            </div>
          </div>
        </ModalMobile>
      )}
    </div>
  );
};

export default TimeButton;
