import RecordingSavingFooter from "../RecordingSavingFooter";
import { ReactComponent as FileCheckIcon } from "../../../assets/icons/file-check.svg";
import { ReactComponent as CancelIcon } from "../../../assets/icons/x-mark.svg";
import { ReactComponent as PauseIcon } from "../../../assets/icons/pause-filled.svg";
import { ReactComponent as PauseCircleIcon } from "../../../assets/icons/pause-circle.svg";
import { ReactComponent as PlayCircleIcon } from "../../../assets/icons/play-circule.svg";
import { ReactComponent as PlayIcon } from "../../../assets/icons/play.svg";

const ScribeRecordingFooterButtons = ({
  hasError,
  isSaving,
  isPaused,
  cancelRecording,
  saveRecording,
  togglePause,
}) => {
  const buttonClasses =
    "w-full h-12 py-3 text-base font-semibold items-center justify-center gap-3";

  return (
    <div className="flex mt-16 md:mt-0 gap-0 sm:gap-4 relative w-full sm:w-[440px]">
      <button
        type="button"
        disabled={hasError}
        className={`${buttonClasses} hidden md:flex flex-auto w-1/2 text-primary-blue bg-primary-blue-light text-center
          font-semibold rounded-lg border-l-2 border-white`}
        onClick={togglePause}
      >
        {isPaused ? (
          <PlayCircleIcon width="20" height="20" className="flex-none" />
        ) : (
          <PauseCircleIcon width="20" height="20" className="flex-none" />
        )}
        <p>{isPaused ? "Resume" : "Pause"}</p>
      </button>
      <button
        type="button"
        disabled={hasError}
        className={`${buttonClasses} flex md:hidden flex-auto w-1/2 text-tertiary bg-gray-110 text-center
          font-semibold mdborder-l-2 border-white`}
        onClick={cancelRecording}
      >
        <CancelIcon width="21" height="20" className="flex-none" />
        <p>Cancel</p>
      </button>
      {isSaving && !hasError ? (
        <div
          className={`${buttonClasses} flex flex-auto w-1/2 text-primary text-center font-semibold bg-primary-blue-light
            md:bg-gray-foreground md:rounded-lg border-l-2 border-white`}
        >
          <RecordingSavingFooter />
        </div>
      ) : (
        <button
          type="button"
          disabled={hasError}
          className={`${buttonClasses} flex flex-auto w-1/2 text-primary text-center font-semibold bg-primary-blue-light
            md:bg-gray-foreground md:rounded-lg border-l-2 border-white`}
          onClick={saveRecording}
        >
          <FileCheckIcon
            width="20"
            height="20"
            stroke="#121212"
            className="flex-none"
          />
          <p className="hidden lg:block whitespace-nowrap">Generate note</p>
          <p className="block lg:hidden">Generate</p>
        </button>
      )}
      <button
        type="button"
        disabled={hasError}
        className="absolute z-10 -top-10 left-1/2 -translate-x-1/2 rounded-full bg-primary-blue h-20 w-20
                  flex items-center justify-center border-4 border-white md:hidden"
        onClick={togglePause}
      >
        {isPaused ? (
          <PlayIcon width="30" height="30" fill="white" />
        ) : (
          <PauseIcon width="40" height="40" />
        )}
      </button>
    </div>
  );
};

export default ScribeRecordingFooterButtons;
