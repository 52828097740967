import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import { setConversationsCompletedTempAction } from "../../../store/conversation/actions";
import {
  changeFlag,
  completeConversation,
  markAsUnread,
} from "../../../store/conversation/thunks";
import { convPerPage, flags } from "../../../helpers/constants";
import { ReactComponent as MarkUnreadIcon } from "../../../assets/icons/mark-unread.svg";
import { ReactComponent as MarkCompleteIcon } from "../../../assets/icons/mark-complete.svg";
import { ReactComponent as ArrowIcon } from "../../../assets/icons/arrow-down.svg";
import { ReactComponent as CrossIcon } from "../../../assets/icons/close.svg";
import { ReactComponent as FlagIcon } from "../../../assets/icons/flag.svg";

const buttonStyles =
  "p-2 hover:bg-blue-50 rounded-lg text-left flex items-center whitespace-nowrap w-full";
const flagButtonStyles =
  "w-6 h-6 rounded flex items-center justify-center border";

const ConversationContextMenu = ({
  onClose,
  contextMenuPosition,
  conversation,
}) => {
  const {
    conversationsCompletedTemp,
    conversationSearchInput,
    activeFilters,
    activeInbox,
    convCurrPage,
    selectedDoctorInbox,
  } = useSelector((state) => state.conversation);
  const [showFlags, setShowFlags] = useState(false);
  const dispatch = useDispatch();

  const onComplete = () => {
    setTimeout(() => {
      dispatch(
        setConversationsCompletedTempAction([
          ...conversationsCompletedTemp,
          conversation.conversation_id,
        ]),
      );
      dispatch(
        completeConversation(
          conversation.conversation_id,
          conversationSearchInput,
          activeFilters,
          1,
          convPerPage * convCurrPage,
          activeInbox,
          selectedDoctorInbox,
        ),
      );
    }, 50);
  };

  const onUnread = () => {
    dispatch(
      markAsUnread(
        conversation.conversation_id,
        conversationSearchInput,
        activeFilters,
        1,
        convPerPage * convCurrPage,
        activeInbox,
        selectedDoctorInbox,
      ),
    );
  };

  const onResetFlag = () => {
    dispatch(
      changeFlag(
        conversation.conversation_id,
        null,
        conversationSearchInput,
        activeFilters,
        1,
        convPerPage * convCurrPage,
        activeInbox,
        selectedDoctorInbox,
      ),
    );
  };

  const onSelectFlag = (flag) => {
    dispatch(
      changeFlag(
        conversation.conversation_id,
        flag,
        conversationSearchInput,
        activeFilters,
        1,
        convPerPage * convCurrPage,
        activeInbox,
        selectedDoctorInbox,
      ),
    );
  };

  return (
    <ClickAwayListener onClickAway={onClose}>
      <div
        className="absolute bg-white p-2 rounded-lg shadow-filter z-30 w-40 flex flex-col
                text-xs font-semibold space-y-1"
        style={{
          top: contextMenuPosition.top,
          left: contextMenuPosition.left,
          bottom: contextMenuPosition.bottom,
          right: contextMenuPosition.right,
        }}
      >
        <button
          type="button"
          className={buttonStyles}
          onClick={(e) => {
            e.stopPropagation();
            onUnread();
            onClose();
          }}
        >
          <MarkUnreadIcon width="16" height="16" className="mr-2" />
          <p>Mark as Unread</p>
        </button>
        <button
          type="button"
          className={buttonStyles}
          onClick={(e) => {
            e.stopPropagation();
            onComplete();
            onClose();
          }}
        >
          <MarkCompleteIcon width="16" height="16" className="mr-2" />
          <p>Mark as Complete</p>
        </button>

        <div className="w-full">
          <button
            type="button"
            onClick={() => setShowFlags(!showFlags)}
            className={`${buttonStyles} ${showFlags ? "bg-blue-50" : ""}`}
          >
            <div className="w-4 h-4 rounded-full flex items-center justify-center mr-2">
              <FlagIcon width="12" height="11" fill="#101010" />
            </div>
            <p>Change Flag</p>
            <ArrowIcon
              stroke="#95A3BF"
              className="-rotate-90 ml-auto"
              width="10"
              height="6"
            />
          </button>

          {showFlags && (
            <div className="p-2 grid grid-cols-4 grid-rows-2 gap-0.5">
              <button
                type="button"
                onClick={onResetFlag}
                className={`${flagButtonStyles} border-transparent hover:border-gray-200`}
              >
                <CrossIcon width="14" height="14" />
              </button>
              {flags.map((flag) => (
                <button
                  type="button"
                  onClick={() => onSelectFlag(flag.value)}
                  key={flag.value}
                  className={`${flagButtonStyles} border-transparent hover:border-gray-200`}
                >
                  <div
                    className="w-4 h-4 rounded-full flex items-center justify-center"
                    style={{ backgroundColor: flag.color }}
                  >
                    <FlagIcon width="11" height="10" />
                  </div>
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default ConversationContextMenu;
