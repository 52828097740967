import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import {
  collapseSidebarAction,
  setConversationsCompletedTempAction,
  setConversationsUpdatedAction,
  setFiltersUpdatedAction,
  setMessagesAction,
} from "../store/conversation/actions";
import {
  getConversation,
  searchConversationsWithFilters,
} from "../store/conversation/thunks";
import { convPerPage } from "../helpers/constants";
import ConversationSidebar from "../components/Messaging/ConversationSidebar";
import Conversation from "../components/Messaging/Conversation";
import InsightPanel from "../components/Messaging/InsightPanel";
import EmptyConversation from "../components/Messaging/Conversation/EmptyConversation";
import { ReactComponent as ArrowsIcon } from "../assets/icons/double-arrow-left.svg";

const Messaging = () => {
  const {
    currentConversation,
    currentConversationId,
    conversationSearchInput,
    activeInbox,
    activeFilters,
    convCurrPage,
    conversationsUpdated,
    onMessageConversationId,
    selectedDoctorInbox,
    messages,
  } = useSelector((state) => state.conversation);
  const isSidebarStatic = useMediaQuery({ minWidth: 1340 });
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const [showInsightPanel, setShowInsightPanel] = useState(isDesktop);
  const [scrolledUp, setScrolledUp] = useState(false);
  const dispatch = useDispatch();

  const updateConversations = () => {
    if (
      currentConversationId &&
      onMessageConversationId &&
      currentConversationId === onMessageConversationId
    ) {
      dispatch(getConversation(currentConversationId));
    }
    dispatch(setConversationsUpdatedAction(true));

    dispatch(
      searchConversationsWithFilters(
        conversationSearchInput,
        activeFilters,
        1,
        convPerPage * convCurrPage,
        activeInbox,
        selectedDoctorInbox,
        () => dispatch(setFiltersUpdatedAction(true)),
      ),
    );
  };

  useEffect(() => {
    dispatch(setConversationsCompletedTempAction([]));

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      dispatch(setMessagesAction({}));
    };
  }, []);

  useEffect(() => {
    dispatch(setConversationsUpdatedAction(false));
  }, [activeFilters]);

  useEffect(() => {
    if (!conversationsUpdated) {
      updateConversations();
    }
  }, [conversationsUpdated]);

  const clickOutsideConversationSidebar = () => {
    if (!isSidebarStatic) {
      dispatch(collapseSidebarAction(true));
    }
  };

  const handleResize = () => {
    setShowInsightPanel(false);
  };

  return (
    <div
      className={`w-full flex min-h-0 md:border-l ${isDesktop ? "h-screen-dynamic" : ""}`}
    >
      {isDesktop ? (
        <div className="flex w-full">
          <ConversationSidebar messages={messages} />
          {!currentConversation && (
            <EmptyConversation onClick={clickOutsideConversationSidebar} />
          )}
        </div>
      ) : (
        <div className="min-h-0 h-full w-screen relative">
          <div
            className={`absolute top-0 left-0 h-full bg-white transition-all duration-300 overflow-hidden
              ${currentConversation ? "w-0" : "w-full"}`}
          >
            <ConversationSidebar messages={messages} />
          </div>
          {currentConversation && (
            <>
              <Conversation
                messages={messages}
                setMessages={(messages) =>
                  dispatch(setMessagesAction(messages))
                }
                scrolledUp={scrolledUp}
                setScrolledUp={setScrolledUp}
              />
              <div
                className={`absolute z-20 top-0 right-0 h-full bg-white transition-all duration-300 overflow-hidden
                  ${showInsightPanel ? "w-full" : "w-0"}`}
              >
                <InsightPanel />
              </div>

              <button
                type="button"
                onClick={() => setShowInsightPanel(!showInsightPanel)}
                className={`w-6 h-6 rounded-full flex items-center justify-center shadow-sm shadow-black/10
                  bg-white absolute z-20 top-[20px] transition-all duration-300
                  ${showInsightPanel ? "left-4" : "left-full -translate-x-full"}`}
                aria-label="Toggle insight panel"
              >
                <ArrowsIcon
                  className={`transition-all
                    ${showInsightPanel ? "rotate-180" : ""}`}
                  width="16"
                  height="16"
                />
              </button>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Messaging;
