import FreeText from "../FreeText";
import ScribeNote from "../ScribeNote";

const ScribeViewDetails = ({ scribe }) => {
  return (
    <main className="w-full h-full overflow-auto pr-1 scrollbar">
      <ScribeNote
        scribe={scribe}
        originalNote={scribe.note}
        variant="oldRecord"
      />
      <FreeText
        scribe={scribe}
        originalFreeText={scribe.free_text}
        variant="oldRecord"
        copyButton
      />
    </main>
  );
};

export default ScribeViewDetails;
