import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as CallGrayIcon } from "../../assets/icons/calling-gray.svg";
import { ReactComponent as CallIcon } from "../../assets/icons/calling.svg";
import { ReactComponent as MessageIcon } from "../../assets/icons/chat-round.svg";
import { ReactComponent as ScribeIcon } from "../../assets/icons/microphone-scribe.svg";
import { ReactComponent as MinimizeIcon } from "../../assets/icons/minimize-square.svg";
import { patientPanelTabs } from "../../helpers/constants";
import { setPatientRecordingScribeTitleAction } from "../../store/patient/actions";
import {
  setOpenCallModalAction,
  setOpenCallOptionsModalAction,
  setRecordingStarted,
} from "../../store/voiceRecorder/actions";
import AddPhoneNumber from "../Basic/AddPhoneNumber";
import TabButton from "../Basic/TabButton";
import NotEnoughTranscript from "../Basic/Warning/NotEnoughTranscript";
import useRecorder from "../IndependentScribe/Recorder";
import NewScribe from "../PatientProfile/NewScribe";
import PatientShortInfo from "../PatientProfile/PatientShortInfo";
import Call from "../PatientProfile/RightPanel/Call/Call";
import CallOptions from "../PatientProfile/RightPanel/Call/CallOptions";
import MessageTab from "../PatientProfile/RightPanel/Message/MessageTab";
import Recording from "../PatientProfile/RightPanel/Scribe/Recording";

const ChatCallScribePanel = ({
  handleCall,
  handleDisconnect,
  handleMute,
  isMuted,
  conversation,
  patient,
  selectedTab,
  setSelectedTab,
  messages,
  setMessages,
  scribeTab,
}) => {
  const { recordingStarted, openCallModal } = useSelector(
    (state) => state.voiceRecorder,
  );
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const [notEnoughTranscript, setNotEnoughTranscript] = useState(false);

  const {
    mediaRecorder,
    microphones,
    microphoneId,
    setMicrophoneId,
    loadMicrophones,
  } = useRecorder();

  useEffect(() => {
    if (!isDesktop) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isDesktop]);

  const onCall = () => {
    dispatch(setOpenCallModalAction(true));
    handleCall(patient?.phone_number);
  };

  const onCancel = useCallback(() => {
    dispatch(setRecordingStarted(false));
    setNotEnoughTranscript(false);
  }, [dispatch]);

  return (
    <div className="bg-white md:rounded-xl md:border border-gray-200 w-full h-full grid grid-rows-tab-layout overflow-hidden">
      <div>
        <div className="md:hidden grid items-start grid-cols-conv-layout w-full min-w-0 px-4 py-3 border-b truncate">
          <div className="truncate">
            {patient && (
              <>
                <p className="font-semibold text-base mt-1">
                  {patient.preferred_name
                    ? patient.preferred_name
                    : patient.first_name}{" "}
                  {patient.last_name}
                </p>
                <PatientShortInfo
                  patient={patient}
                  className="mt-1 pb-0.5 truncate text-xs text-tertiary"
                />
              </>
            )}
          </div>
          <button
            type="button"
            className="w-6 h-8 md:w-8 flex items-center justify-center flex-none"
            onClick={() => dispatch(setOpenCallOptionsModalAction(false))}
          >
            <MinimizeIcon width="24" height="24" stroke="#121212" />
          </button>
        </div>

        <div
          className={`grid h-[52px] lg:h-16 p-2 border-b
            ${scribeTab ? "grid-cols-3" : "grid-cols-2"}`}
        >
          <TabButton
            icon={
              <MessageIcon
                width="20"
                height="20"
                stroke={
                  selectedTab === patientPanelTabs.MESSAGE
                    ? "#2970FF"
                    : "#667085"
                }
              />
            }
            label={patientPanelTabs.MESSAGE}
            active={selectedTab}
            onClick={() => setSelectedTab(patientPanelTabs.MESSAGE)}
          />
          <TabButton
            icon={
              selectedTab === patientPanelTabs.CALL ? (
                <CallIcon width="20" height="20" />
              ) : (
                <CallGrayIcon width="20" height="20" />
              )
            }
            label={patientPanelTabs.CALL}
            active={selectedTab}
            onClick={() => setSelectedTab(patientPanelTabs.CALL)}
          />
          {scribeTab && (
            <TabButton
              icon={
                <ScribeIcon
                  width="20"
                  height="20"
                  stroke={
                    selectedTab === patientPanelTabs.SCRIBE
                      ? "#2970FF"
                      : "#667085"
                  }
                />
              }
              label={patientPanelTabs.SCRIBE}
              active={selectedTab}
              onClick={() => setSelectedTab(patientPanelTabs.SCRIBE)}
            />
          )}
        </div>
      </div>

      <div className="flex overflow-hidden">
        {selectedTab === patientPanelTabs.CALL && (
          <>
            {openCallModal ? (
              <div className="overflow-y-auto scrollbar h-full w-full">
                <Call
                  onClose={handleDisconnect}
                  handleMute={handleMute}
                  isMuted={isMuted}
                  patient={patient}
                />
              </div>
            ) : patient.phone_number ? (
              <div className="pl-5 py-3 height-sm:py-5 pr-2 bg-gray-background2 h-full w-full">
                <CallOptions
                  className="h-full min-h-0 overflow-y-auto scrollbar pr-3"
                  onCall={onCall}
                  patient={patient}
                />
              </div>
            ) : (
              <AddPhoneNumber patientId={patient.patient_id} call />
            )}
          </>
        )}

        {selectedTab === patientPanelTabs.MESSAGE && (
          <MessageTab
            patientInfo={patient}
            conversation={conversation}
            messages={messages}
            setMessages={setMessages}
          />
        )}

        {selectedTab === patientPanelTabs.SCRIBE && (
          <div className="h-full w-full overflow-hidden">
            {recordingStarted ? (
              <Recording
                onCancel={onCancel}
                microphoneId={microphoneId}
                mediaRecorder={mediaRecorder}
                setNotEnoughTranscript={setNotEnoughTranscript}
              />
            ) : (
              <div className="px-5 py-3 md:px-8 height-sm:py-8 h-full min-h-fit grid overflow-y-auto scrollbar">
                <NewScribe
                  onStart={() => {
                    dispatch(setRecordingStarted(true));
                    dispatch(setPatientRecordingScribeTitleAction("Scribe"));
                  }}
                  mediaRecorder={mediaRecorder}
                  loadMicrophones={loadMicrophones}
                  microphoneId={microphoneId}
                  microphones={microphones}
                  setMicrophoneId={setMicrophoneId}
                />
              </div>
            )}
          </div>
        )}
      </div>
      {notEnoughTranscript && <NotEnoughTranscript onSubmit={onCancel} />}
    </div>
  );
};

export default ChatCallScribePanel;
