import ScribeListItem from "../ScribeListItem";

const ScribeListContent = ({ groupedScribes, handleSelectScribe }) => {
  return (
    <main className="h-full overflow-y-auto scrollbar">
      {groupedScribes.map((groupedScribe, groupIndex) => (
        <div key={groupedScribe.date}>
          <div className="flex justify-between pb-1">
            <p className="text-xs font-medium text-tertiary uppercase">
              {groupedScribe.date}
            </p>
          </div>
          {groupedScribe.entries.map((scribe) => {
            const handleClick = () => {
              handleSelectScribe(scribe);
            };

            return (
              <ScribeListItem
                key={scribe.audioId}
                scribe={scribe}
                onClick={handleClick}
              />
            );
          })}
          {groupIndex !== groupedScribes.length - 1 && (
            <div className="md:px-1 py-3">
              <hr />
            </div>
          )}
        </div>
      ))}
      <div className="h-[150px]" />
    </main>
  );
};

export default ScribeListContent;
