import Modal from "../../Basic/Modal";

const CancelRecordingModal = ({ onConfirm, onClose }) => {
  return (
    <Modal
      onClickAway={onClose}
      className="flex-col font-Mulish pt-6 rounded-2xl min-w-20 md:w-110 gap-5 scrollbar"
    >
      <header className="flex font-semibold w-full justify-between items-center px-5">
        <h1 className="text-xl">Cancel Recording</h1>
      </header>
      <main className="px-5 flex flex-col gap-1">
        <p className="font-medium">
          Are you sure you want to cancel the recording?
        </p>
      </main>
      <footer className="flex bg-gray-background justify-end gap-4 px-5 py-4 font-semibold">
        <button
          type="button"
          onClick={onClose}
          className="py-1.5 md:py-2.5 px-4 rounded-lg border border-gray-foreground text-sm bg-white"
        >
          No
        </button>
        <button
          type="button"
          onClick={onConfirm}
          className="py-1.5 md:py-2.5 px-6 bg-primary-blue text-sm text-white rounded-lg disabled:opacity-70"
        >
          Yes
        </button>
      </footer>
    </Modal>
  );
};

export default CancelRecordingModal;
