import { useState } from "react";
import AutocompleteMultiple from "../../../Basic/Autocomplete/AutocompleteMultiple";
import TextareaAutosizeForm from "../../../Basic/TextareaAutosizeForm";
import Button from "../../../Basic/Button";
import Modal from "../../../Basic/Modal";
import Input from "../../../Basic/Input";
import { ReactComponent as TemplatesIcon } from "../../../../assets/icons/notes-templates.svg";

const sectionOptions = [
  { title: "Medications" },
  { title: "Allergies" },
  { title: "Family history" },
  { title: "Social history" },
  { title: "Plan" },
  { title: "Impression" },
  { title: "Past medical history" },
  { title: "Chief complaint" },
  { title: "History of present illness" },
  { title: "Past surgical history" },
];

const TemplateEditModal = ({
  template,
  onClose = () => {},
  onSubmit = () => {},
  editableTitle,
  editableSections,
}) => {
  const [title, setTitle] = useState(template?.name || "");
  const [editTitle, setEditTitle] = useState(!template?.name);
  const [instructionsValue, setInstructionsValue] = useState(
    template?.custom_instructions || "",
  );
  const [sectionsValue, setSectionsValue] = useState(template?.sections || []);

  const handleSubmit = () => {
    onSubmit({
      title: title,
      sections: sectionsValue,
      custom_instructions: instructionsValue,
    });
  };

  return (
    <Modal onClickAway={onClose} className="pt-5 rounded-2xl">
      <div className="w-180 flex flex-col overflow-hidden">
        <div className="px-5 min-h-0 h-full overflow-y-auto scrollbar">
          <div className="h-10 mb-3 w-full flex items-center">
            {editTitle ? (
              <Input
                autoFocus
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                onBlur={() => {
                  if (title.trim() !== "") {
                    setEditTitle(false);
                  }
                }}
                className="w-full"
                inputClassName="text-base h-10"
                placeholder="Enter template title"
              />
            ) : (
              <button
                disabled={!editableTitle}
                type="button"
                className={`w-full flex items-center space-x-2 text-left ${editableTitle ? "" : "cursor-default"}`}
                onClick={() => setEditTitle(true)}
              >
                <TemplatesIcon width="20" height="20" stroke="#667085" />
                <p className="text-sm text-tertiary font-semibold">{title}</p>
              </button>
            )}
          </div>

          {editableSections && (
            <>
              <div className="flex items-center justify-between space-x-2 mb-3">
                <p className="text-base font-semibold">Sections</p>
                {/* todo: magic fill if editableSections */}
              </div>
              <AutocompleteMultiple
                options={sectionOptions}
                className="mb-5"
                placeholder="Section"
                value={sectionsValue}
                setValue={setSectionsValue}
              />
            </>
          )}

          <div className="flex items-center justify-between space-x-2 mb-3">
            <p className="text-base font-semibold">Additional instructions</p>
            {/* todo: magic fill if !editableSections */}
          </div>
          <TextareaAutosizeForm
            placeholder="Enter additional instructions"
            value={instructionsValue}
            onChange={(e) => setInstructionsValue(e.target.value)}
            minRows={6}
            maxRows={12}
            className="mb-5"
            textareaClassName="text-base"
          />

          <div className="p-3 rounded-lg bg-gray-110">
            <ul className="pl-5 list-disc text-xs text-tertiary font-medium">
              <li>Eg. Include all body systems on review of systems</li>
              <li>Eg. Include further appointments</li>
            </ul>
          </div>
        </div>

        <div className="w-full bg-gray-background py-4 px-5 flex justify-end space-x-4 text-sm font-semibold mt-5">
          <Button variant="gray" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} disabled={!title}>
            Create
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default TemplateEditModal;
