import { useMediaQuery } from "react-responsive";
import Modal from "../../../Basic/Modal";
import ModalMobile from "../../../Basic/ModalMobile";
import AddTeamForm from "./AddTeamForm";

const AddTeamModal = ({ open, onClose, onNext }) => {
  const isDesktop = useMediaQuery({ minWidth: 768 });

  return isDesktop ? (
    open && (
      <Modal className="pt-5 rounded-2xl" onClickAway={onClose}>
        <div className="grow md:grow-0 sm:w-128">
          <AddTeamForm onClose={onClose} onNext={onNext} />
        </div>
      </Modal>
    )
  ) : (
    <ModalMobile
      open={open}
      childHeight={350}
      onClickAway={onClose}
      className=""
    >
      <AddTeamForm onClose={onClose} onNext={onNext} />
    </ModalMobile>
  );
};

export default AddTeamModal;
