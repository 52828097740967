import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import useVolumeLevel from "../../hooks/useVolumeLevel";
import Button from "../Basic/Button";
import MicSelect from "../Basic/MicSelect";
import MicrophoneTestVolumeBars from "../Basic/MicrophoneTestVolumeBars";
import Select from "../Basic/Select";
import NoSoundDetected from "../Basic/Warning/NoSoundDetected";
import {
  setSelectedScribeNoteTemplateAction,
  setShowScribeNoteTemplateAction,
} from "../IndependentScribe/store/actions";
import {
  describeNoteTemplate,
  getNoteTemplates,
} from "../IndependentScribe/store/thunks";
import Dropdown from "../Settings/Notes/Dropdown/Dropdown";

const encounterTypeOptions = [{ text: "In person", value: "in-person" }];

const defaultUserLang =
  (
    navigator.language ||
    navigator.userLanguage ||
    navigator.languages[0]
  ).split("-")[0] ?? "en";

const NewScribe = ({
  onStart,
  mediaRecorder,
  loadMicrophones,
  microphones,
  microphoneId,
  setMicrophoneId,
}) => {
  const { user } = useSelector((state) => state.user);
  const { noteTemplates, selectedScribeNoteTemplate, defaultNoteTemplate } =
    useSelector((state) => state.scribe);
  const [notEnoughVolume, setNotEnoughVolume] = useState(false);
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      encounterType: "in-person",
      microphone: microphoneId,
      inputLanguage: user.settings.scribe_input_language ?? defaultUserLang,
    },
  });

  const [volume, noAudioDetected] = useVolumeLevel(mediaRecorder);

  useEffect(() => {
    loadMicrophones();
  }, [loadMicrophones]);

  useEffect(() => {
    if (microphoneId) {
      setValue("microphone", microphoneId);
    }
  }, [microphoneId, setValue]);

  useEffect(() => {
    dispatch(getNoteTemplates());
  }, [dispatch]);

  useEffect(() => {
    // preselect default note template
    if (defaultNoteTemplate) {
      dispatch(setSelectedScribeNoteTemplateAction(defaultNoteTemplate));
    } else if (user?.note_template_id && noteTemplates.length > 0) {
      dispatch(
        describeNoteTemplate(user.note_template_id, (data) =>
          dispatch(setSelectedScribeNoteTemplateAction(data)),
        ),
      );
    }
  }, [noteTemplates, user, defaultNoteTemplate, dispatch]);

  const onMicrophoneChange = (value) => {
    setMicrophoneId(value);
    setValue("microphone", value);
  };

  const onSubmit = useCallback(
    (data) => {
      if (noAudioDetected) {
        setNotEnoughVolume(true);
        return;
      }

      onStart(data);
      dispatch(setShowScribeNoteTemplateAction(true));
    },
    [onStart, noAudioDetected, dispatch],
  );

  const handleSelectTemplate = (template) => {
    if (
      template.note_template_id !== selectedScribeNoteTemplate?.note_template_id
    ) {
      dispatch(
        describeNoteTemplate(template.note_template_id, (data) =>
          dispatch(setSelectedScribeNoteTemplateAction(data)),
        ),
      );
    }
  };

  return (
    <>
      <div className="grid h-fit w-full max-w-full min-w-0 md:max-w-[602px] mx-auto my-auto">
        <form
          id="startEncounter"
          className="mt-1 mb-8 grid space-y-4 min-w-0 w-full max-w-full"
          onSubmit={handleSubmit(onSubmit)}
          type="submit"
        >
          <Select
            required="Encounter is required"
            label="Encounter"
            placeholder="Choose encounter"
            name="encounterType"
            options={encounterTypeOptions}
            register={register}
            withoutAsterisk={true}
            error={errors.encounterType}
          />

          <div className="space-y-1.5">
            <p className="text-sm font-semibold">Note Template</p>
            <Dropdown
              customItems={noteTemplates.filter(
                (template) => template.is_custom,
              )}
              preMadeItems={noteTemplates.filter(
                (template) => !template.is_custom && !template.is_hidden,
              )}
              selectedItem={selectedScribeNoteTemplate}
              onSelectItem={handleSelectTemplate}
              itemName="template"
              defaultSelect
              closeDropdownOnSelect
              withoutSearch
            />
          </div>

          <div className="min-w-0 max-w-full overflow-x-hidden">
            <MicSelect
              label="Microphone"
              placeholder="Choose microphone"
              name="microphone"
              register={register}
              loadMicrophones={loadMicrophones}
              microphones={microphones}
              withoutAsterisk={true}
              error={errors.microphone}
              onChange={onMicrophoneChange}
              value={microphoneId ?? ""}
            />
          </div>

          <div className="flex items-center justify-between space-x-4">
            <p className="text-sm text-tertiary font-medium">
              Test your microphone
            </p>
            <MicrophoneTestVolumeBars volume={volume} />
          </div>
        </form>

        <Button
          variant="blue-light"
          type="submit"
          className="w-full justify-center font-semibold py-3"
          form="startEncounter"
        >
          Start Encounter
        </Button>
      </div>
      {notEnoughVolume && (
        <NoSoundDetected onSubmit={() => setNotEnoughVolume(false)} />
      )}
    </>
  );
};

export default NewScribe;
