import { isEqual } from "lodash";

import LocalStorageService from "../../services/LocalStorageService";
import {
  LOGOUT,
  SET_CALL_EVENT,
  SET_CONVERSATION_ID_GENERATING,
  SET_DEVICE_STATUS,
  SET_MICROPHONES,
  SET_MICROPHONE_ID,
  SET_OPEN_CALL_MODAL,
  SET_OPEN_CALL_OPTIONS_MODAL,
  SET_PATIENT_ID_GENERATING,
  SET_RECORDING_STARTED,
  SET_SCRIBE_CALLS,
  SET_TRANSCRIBING,
  SET_TWILIO_TOKEN,
} from "../types";

const storageService = new LocalStorageService();

const init = {
  transcribing: false,
  savedAudioId: null,
  conversationIdGenerating: null,
  patientIdGenerating: null,
  twilioToken: null,
  deviceStatus: "offline",
  openCallOptionsModal: storageService.getItem("openCallOptionsModal") ?? false,
  openCallModal: false,
  callEvent: null,
  scribeCalls: false,
  microphoneId: null,
  microphones: [],
  recordingStarted: false,
};

export default function voiceRecorderReducer(state = init, action) {
  const { type, payload } = action;

  switch (type) {
    case LOGOUT:
      storageService.removeItem("openCallOptionsModal");
      return init;
    case SET_TRANSCRIBING:
      if (state.transcribing === payload.transcribing) {
        return state;
      }

      return { ...state, transcribing: payload.transcribing };
    case SET_CONVERSATION_ID_GENERATING:
      return {
        ...state,
        conversationIdGenerating: payload.conversationIdGenerating,
      };
    case SET_PATIENT_ID_GENERATING:
      if (state.patientIdGenerating === payload.patientIdGenerating) {
        return state;
      }

      return { ...state, patientIdGenerating: payload.patientIdGenerating };
    case SET_TWILIO_TOKEN:
      if (state.twilioToken === payload.twilioToken) {
        return state;
      }

      return { ...state, twilioToken: payload.twilioToken };
    case SET_DEVICE_STATUS:
      if (state.deviceStatus === payload.deviceStatus) {
        return state;
      }

      return { ...state, deviceStatus: payload.deviceStatus };
    case SET_OPEN_CALL_OPTIONS_MODAL:
      storageService.setItem(
        "openCallOptionsModal",
        payload.openCallOptionsModal,
      );
      return { ...state, openCallOptionsModal: payload.openCallOptionsModal };
    case SET_OPEN_CALL_MODAL:
      return { ...state, openCallModal: payload.openCallModal };
    case SET_CALL_EVENT:
      return { ...state, callEvent: payload.callEvent };
    case SET_SCRIBE_CALLS:
      return { ...state, scribeCalls: payload.scribeCalls };
    case SET_MICROPHONE_ID:
      if (state.microphoneId === payload.microphoneId) {
        return state;
      }

      return { ...state, microphoneId: payload.microphoneId };
    case SET_MICROPHONES:
      if (isEqual(state.microphones, payload.microphones)) {
        return state;
      }

      return { ...state, microphones: payload.microphones };
    case SET_RECORDING_STARTED:
      if (state.recordingStarted === payload.recordingStarted) {
        return state;
      }

      return { ...state, recordingStarted: payload.recordingStarted };
    default:
      return state;
  }
}
