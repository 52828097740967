import Bugsnag from "@bugsnag/js";
import { debounce } from "@mui/material/utils";
import axios from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useDispatch } from "react-redux";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";
import { BASE_URL } from "../../../helpers/config";
import { selectInputText } from "../../../helpers/helpers";
import { setNotificationAction } from "../../../store/user/actions";
import AddEditPatientModal from "../../Basic/AddEditPatientModal";
import Loader from "../../Basic/Loader";
import LoaderDots from "../../Basic/LoaderDots";
import Modal from "../../Basic/Modal";
import ProfilePicture from "../../Basic/ProfilePicture";
import UserPlusRoundedIcon from "../UserPlusRoundedIcon";

const AssignPatientModal = ({ handleClose, handleAssignPatientId }) => {
  const [selectedPatientId, setSelectedPatientId] = useState(-1);
  const [openCreatePatientModal, setOpenPatientModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [patients, setPatients] = useState([]);
  const [currPage, setCurrPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [optionsTotal, setOptionsTotal] = useState(0);
  const dispatch = useDispatch();

  const onFilterUpdate = useCallback(
    (filter, currPage, onSuccess = () => {}) => {
      setIsLoading(true);
      axios
        .post(`${BASE_URL}/patient/search`, {
          q: filter,
          offset: 0,
          limit: currPage * 50,
          doctor_id: null,
          unassigned: false,
          campaigns: [],
          status: [],
        })
        .then((response) => {
          if (response.data.error) {
            dispatch(
              setNotificationAction({
                status: "error",
                title: "Something went wrong",
                desc: "Failed to load patients",
              }),
            );
            Bugsnag.notify(response.data.error);
            return;
          }

          onSuccess();
          setOptionsTotal(response.data.data.total);
          const patients = response.data.data.patients.map((patient) => {
            patient.age =
              new Date().getFullYear() -
              new Date(patient.birthdate).getFullYear();

            patient.sexAbbreviation = "";
            if (patient.sex === "female") {
              patient.sexAbbreviation = "F";
            } else if (patient.sex === "male") {
              patient.sexAbbreviation = "M";
            }

            return patient;
          });
          setPatients(patients);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [dispatch],
  );

  const debouncedSearch = useMemo(
    () =>
      debounce((inputValue) => {
        onFilterUpdate(inputValue, 1);
      }, 300),
    [onFilterUpdate],
  );

  useEffect(() => {
    onFilterUpdate("", currPage);
  }, [onFilterUpdate]);

  const handleSearchInput = (searchValue) => {
    setSearchValue(searchValue);
    debouncedSearch(searchValue);
  };

  const handleAssign = (patientId) => {
    handleAssignPatientId(patientId);
    handleClose();
  };

  const handleSelectPatient = (patient) => {
    if (selectedPatientId === patient.patient_id) {
      setSelectedPatientId(-1);
      return;
    }

    setSelectedPatientId(patient.patient_id);
  };

  const onNext = () => {
    onFilterUpdate(searchValue, currPage + 1, () => setCurrPage(currPage + 1));
  };

  return (
    <Modal
      onClickAway={handleClose}
      className="flex-col w-full md:w-110 h-full height-md:max-h-[650px] rounded-2xl min-w-20 scrollbar"
    >
      <div className="grid grid-rows-chat-layout h-full w-full pt-4 gap-1 overflow-hidden">
        <header className="flex font-semibold w-full md:w-110 justify-between items-center px-5 h-fit">
          <h1 className="text-xl">Assign Patient</h1>
          <button
            type="button"
            className="text-sm bg-primary-blue-light text-primary-blue flex items-center justify-center rounded-lg py-2 px-4 gap-3"
            onClick={() => setOpenPatientModal(true)}
          >
            <UserPlusRoundedIcon stroke="#2970FF" width="20" height="20" />
            <p className="hidden sm:block">Add New Patient</p>
          </button>
        </header>
        <main className="px-5 h-full grid grid-rows-tab-layout overflow-hidden">
          <div className="relative w-full md:max-w-lg h-fit">
            <input
              onClick={selectInputText}
              value={searchValue}
              className="h-10 text-sm outline-none p-2.5 pr-10 border border-primary-blue w-full rounded-lg caret-primary-blue"
              placeholder="Search patient"
              onChange={(e) => {
                setSearchValue(e.target.value);
                setCurrPage(1);
                handleSearchInput(e.target.value);
              }}
            />
            {isLoading ? (
              <div className="absolute right-2.5 pt-1 top-1/2 -translate-y-1/2">
                <Loader size={5} borderWidth={2} />
              </div>
            ) : (
              <SearchIcon
                width="20"
                height="20"
                className="absolute top-2.5 right-3.5"
                stroke="#667085"
              />
            )}
          </div>
          <div className="overflow-y-auto overflow-x-hidden h-full w-full scrollbar pr-1">
            <InfiniteScroll
              pageStart={0}
              loadMore={onNext}
              hasMore={patients.length < optionsTotal}
              useWindow={false}
              loader={
                <div
                  key={0}
                  className="w-full h-10 flex items-center justify-center"
                >
                  <LoaderDots />
                </div>
              }
            >
              <div className="flex flex-col mt-2 gap-2 h-full w-full overflow-x-hidden">
                {patients.map((patient, index) => (
                  <div
                    key={`${patient.patient_id}_${index}`}
                    onClick={() => handleSelectPatient(patient)}
                    onDoubleClick={() => handleAssign(patient.patient_id)}
                    className={`grid grid-cols-2-right items-center gap-3 hover:bg-gray-110 rounded-lg p-2 cursor-pointer w-full overflow-hidden
                      ${
                        selectedPatientId === patient.patient_id
                          ? "bg-[#d5dce3] hover:bg-[#d5dce3]"
                          : ""
                      }`}
                  >
                    <ProfilePicture
                      src={patient.photo}
                      firstName={patient.first_name}
                      lastName={patient.last_name}
                      flagPadding={false}
                    />
                    <div className="flex flex-col justify-center w-full truncate">
                      <p className="font-semibold text-sm truncate">
                        {patient.first_name} {patient.last_name}
                      </p>
                      <p className="text-xs text-gray-500">
                        {[
                          `${patient.age} yo`,
                          patient.sexAbbreviation,
                          patient.pronouns,
                          patient.birthdate
                            ? new Date(patient.birthdate).toLocaleDateString(
                                undefined,
                                {
                                  month: "short",
                                  day: "numeric",
                                  year: "numeric",
                                },
                              )
                            : "",
                        ]
                          .filter((field) => !!field)
                          .join(" • ")}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </InfiniteScroll>
          </div>
        </main>
        <footer className="flex bg-gray-background justify-end gap-4 px-5 py-4 font-semibold h-fit">
          <button
            type="button"
            onClick={handleClose}
            className="py-1.5 md:py-2.5 px-4 rounded-lg border border-gray-foreground bg-white"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={() => handleAssign(selectedPatientId)}
            disabled={selectedPatientId === -1}
            className="py-1.5 md:py-2.5 px-6 bg-primary-blue text-white rounded-lg disabled:opacity-70"
          >
            Select Patient
          </button>
        </footer>
      </div>

      <AddEditPatientModal
        open={openCreatePatientModal}
        onClose={() => {
          setOpenPatientModal(false);
          onFilterUpdate(searchValue, currPage);
        }}
        patientInfo={null}
      />
    </Modal>
  );
};

export default AssignPatientModal;
