import Button from "./Button";
import Modal from "./Modal";

const DeleteModal = ({ onClose, onDelete, title, description }) => {
  return (
    <Modal className="pt-5 rounded-2xl" onClickAway={onClose}>
      <div className="w-106">
        <p className="text-base md:text-xl font-semibold mb-5 px-5">{title}</p>

        <div className="px-5">
          <p className="text-sm md:text-base font-medium">{description}</p>

          <p className="text-xs md:text-sm font-medium text-tertiary mt-1">
            This action cannot be undone
          </p>
        </div>

        <div className="flex justify-end gap-4 font-semibold text-sm bg-gray-background py-4 px-5 mt-5">
          <Button variant="gray" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={onDelete}>Delete</Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;
