import Button from "../../Basic/Button";
import UserPlusRoundedIcon from "../UserPlusRoundedIcon";

const ScribeUnassignedPatient = ({
  setShowAssignPatientModal,
  className = "",
}) => {
  return (
    <>
      <Button
        className={`whitespace-nowrap font-semibold text-primary-blue bg-primary-blue-light gap-3 ${className}`}
        variant="unknown"
        size="small2"
        onClick={() => setShowAssignPatientModal(true)}
      >
        <UserPlusRoundedIcon width="20" height="20" stroke="#2970FF" />
        <p className="text-base">Assign Patient</p>
      </Button>
    </>
  );
};

export default ScribeUnassignedPatient;
