import { useCallback, useEffect, useState } from "react";
import { getFormattedTimeWithSeconds } from "../../../helpers/helpers";

const TimerDisplay = ({ isRecording, startedAt, offset, className }) => {
  const [formattedTime, setFormattedTime] = useState("00:00");

  const incrementSeconds = useCallback(() => {
    if (!isRecording) {
      return;
    }

    let seconds = offset;
    if (startedAt) {
      seconds += Math.floor((new Date() - startedAt) / 1000);
    }

    setFormattedTime(getFormattedTimeWithSeconds(seconds));
  }, [startedAt, offset, isRecording]);

  useEffect(() => {
    const interval = setInterval(incrementSeconds, 1000);
    return () => clearInterval(interval);
  }, [incrementSeconds]);

  return (
    <p className={`text-center md:m-1.5 font-semibold ${className}`}>
      {formattedTime}
    </p>
  );
};

export default TimerDisplay;
