import {
  SET_TWILIO_TOKEN,
  SET_CONVERSATION_ID_GENERATING,
  SET_PATIENT_ID_GENERATING,
  SET_TRANSCRIBING,
  SET_DEVICE_STATUS,
  SET_OPEN_CALL_OPTIONS_MODAL,
  SET_OPEN_CALL_MODAL,
  SET_CALL_EVENT,
  SET_SCRIBE_CALLS,
  SET_MICROPHONE_ID,
  SET_MICROPHONES,
  SET_RECORDING_STARTED,
} from "../types";

export function setTranscribingAction(transcribing) {
  return {
    type: SET_TRANSCRIBING,
    payload: {
      transcribing: transcribing,
    },
  };
}

export function setConversationIdGeneratingAction(conversationIdGenerating) {
  return {
    type: SET_CONVERSATION_ID_GENERATING,
    payload: {
      conversationIdGenerating: conversationIdGenerating,
    },
  };
}

export function setPatientIdGeneratingAction(patientIdGenerating) {
  return {
    type: SET_PATIENT_ID_GENERATING,
    payload: {
      patientIdGenerating: patientIdGenerating,
    },
  };
}

export function setTwilioTokenAction(twilioToken) {
  return {
    type: SET_TWILIO_TOKEN,
    payload: {
      twilioToken: twilioToken,
    },
  };
}

export function setDeviceStatusAction(deviceStatus) {
  return {
    type: SET_DEVICE_STATUS,
    payload: {
      deviceStatus: deviceStatus,
    },
  };
}

export function setOpenCallOptionsModalAction(openCallOptionsModal) {
  return {
    type: SET_OPEN_CALL_OPTIONS_MODAL,
    payload: {
      openCallOptionsModal: openCallOptionsModal,
    },
  };
}

export function setOpenCallModalAction(openCallModal) {
  return {
    type: SET_OPEN_CALL_MODAL,
    payload: {
      openCallModal: openCallModal,
    },
  };
}

export function setCallEventAction(callEvent) {
  return {
    type: SET_CALL_EVENT,
    payload: {
      callEvent: callEvent,
    },
  };
}

export function setScribeCallsAction(scribeCalls) {
  return {
    type: SET_SCRIBE_CALLS,
    payload: {
      scribeCalls: scribeCalls,
    },
  };
}

export function setMicrophoneIdAction(microphoneId) {
  return {
    type: SET_MICROPHONE_ID,
    payload: {
      microphoneId: microphoneId,
    },
  };
}

export function setMicrophonesAction(microphones) {
  return {
    type: SET_MICROPHONES,
    payload: {
      microphones: microphones,
    },
  };
}

export function setRecordingStarted(recordingStarted) {
  return {
    type: SET_RECORDING_STARTED,
    payload: {
      recordingStarted: recordingStarted,
    },
  };
}
