import {
  SET_FACILITIES,
  SET_FACILITIES_LOADING,
  SET_FACILITY_SEARCH_VALUE,
  SET_USER_FACILITIES,
} from "../types";

const init = {
  facilities: null,
  facilitySearchValue: "",
  facilitiesLoading: true,
  userFacilities: {},
};

export default function facilityReducer(state = init, { type, payload }) {
  switch (type) {
    case SET_FACILITIES:
      return { ...state, facilities: payload.facilities };
    case SET_FACILITY_SEARCH_VALUE:
      return { ...state, facilitySearchValue: payload.facilitySearchValue };
    case SET_FACILITIES_LOADING:
      return { ...state, facilitiesLoading: payload.facilitiesLoading };
    case SET_USER_FACILITIES:
      return {
        ...state,
        userFacilities: {
          ...state.userFacilities,
          [payload.id]: payload.facilities,
        },
      };
    default:
      return state;
  }
}
