import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { v4 as uuid } from "uuid";
import { updateTemplateSection } from "../../../IndependentScribe/store/thunks";
import { onDragEnd } from "../../../../helpers/helpers";
import Switch from "../../../Basic/Switch";
import Input from "../../../Basic/Input";
import TabsBg from "../../../Basic/TabsBg";
import Subsection from "./Subsection";
import { ReactComponent as PlusIcon } from "../../../../assets/icons/plus-circle.svg";
import { ReactComponent as TrashIcon } from "../../../../assets/icons/trash-redesign.svg";

const subsectionsOptions = ["Auto", "Custom"];
const sectionStyleOptions = ["Auto", "Bullet points", "Paragraph"];
const detailLevelOptions = ["Normal", "High"];

const fieldContainerClasses =
  "w-full flex flex-wrap items-center justify-between";
const fieldLabelClasses = "text-base font-semibold mr-3";

const TemplateSectionCard = ({
  section,
  removable,
  onRemoveSection = () => {},
}) => {
  const [title, setTitle] = useState(section.name || "");
  const [editTitle, setEditTitle] = useState(false);
  const [selectedSubsectionsVariant, setSelectedSubsectionsVariant] = useState(
    section.has_subsections ? "Custom" : "Auto",
  );
  const [subsections, setSubsections] = useState(
    section.has_subsections && section.subsections
      ? section.subsections.map((subsection) => ({
          id: uuid(),
          name: subsection,
        }))
      : [],
  );
  const [selectedSectionStyle, setSelectedSectionStyle] = useState(
    section.section_style === "Bullet"
      ? "Bullet points"
      : section.section_style || "Auto",
  );
  const [selectedDetailLevel, setSelectedDetailLevel] = useState(
    section.detail_level || "Normal",
  );
  const [hideByDefault, setHideByDefault] = useState(!!section.hide_by_default);
  const [splitByProblem, setSplitByProblem] = useState(
    !!section.split_by_problem,
  );
  const [includeDifferential, setIncludeDifferential] = useState(
    !!section.include_differential,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      section.section_style !== selectedSectionStyle ||
      section.detail_level !== selectedDetailLevel ||
      !!section.hide_by_default !== hideByDefault ||
      !!section.split_by_problem !== splitByProblem ||
      !!section.include_differential !== includeDifferential ||
      (section.has_subsections && subsections.length === 0) ||
      selectedSubsectionsVariant === "Custom"
    ) {
      handleUpdateSection();
    }
  }, [
    selectedSectionStyle,
    hideByDefault,
    selectedDetailLevel,
    splitByProblem,
    includeDifferential,
    subsections,
    selectedSubsectionsVariant,
  ]);

  const handleUpdateSection = () => {
    dispatch(
      updateTemplateSection(
        section.note_template_id,
        section.note_template_section_id,
        title,
        selectedSectionStyle,
        hideByDefault,
        subsections
          .map((subsection) => subsection.name)
          .filter((name) => !!name.trim()),
        selectedDetailLevel,
        splitByProblem,
        includeDifferential,
      ),
    );
  };

  const handleUpdateSubsection = (newName, id) => {
    const newSubsections = [...subsections];
    const index = newSubsections.findIndex(
      (subsection) => subsection.id === id,
    );
    newSubsections[index].name = newName;
    setSubsections(newSubsections);
  };

  const handleRemoveSubsection = (id) => {
    const newSubsections = [...subsections];
    const index = newSubsections.findIndex(
      (subsection) => subsection.id === id,
    );
    newSubsections.splice(index, 1);
    setSubsections(newSubsections);
  };

  return (
    <div className="w-full p-5 rounded-lg bg-white border border-gray-foreground">
      <div className="w-full flex items-center space-x-2 mb-4">
        <div className="h-10 w-full flex items-center">
          {editTitle ? (
            <Input
              autoFocus
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              onBlur={() => {
                if (title.trim() !== "") {
                  setEditTitle(false);
                  if (section.name !== title) {
                    handleUpdateSection();
                  }
                }
              }}
              className="w-full"
              inputClassName="text-base h-10"
              placeholder="Enter section title"
            />
          ) : (
            <button
              type="button"
              className="w-full font-Inter text-xl font-medium uppercase tracking-wider text-left"
              onClick={() => setEditTitle(true)}
            >
              {title}
            </button>
          )}
        </div>

        {removable && (
          <button type="button" onClick={onRemoveSection}>
            <TrashIcon width="20" height="20" />
          </button>
        )}
      </div>

      <div className={fieldContainerClasses}>
        <p className={fieldLabelClasses}>Subsections</p>
        <TabsBg
          tabs={subsectionsOptions}
          activeTab={selectedSubsectionsVariant}
          onChange={(tab) => {
            setSelectedSubsectionsVariant(tab);
            if (tab === "Auto") {
              setSubsections([]);
            } else if (tab === "Custom") {
              setSubsections(
                section.subsections
                  ? section.subsections.map((subsection) => ({
                      id: uuid(),
                      name: subsection,
                    }))
                  : [],
              );
            }
          }}
          className="bg-gray-150 text-sm w-38"
          tabClassName="py-1.5 text-center whitespace-nowrap"
        />
      </div>
      {selectedSubsectionsVariant === "Custom" && (
        <div className="mt-2 w-full bg-gray-background p-4 rounded-xl">
          <DragDropContext
            onDragEnd={(result) =>
              onDragEnd(result, subsections, setSubsections)
            }
          >
            <Droppable droppableId="droppable-subsections">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="space-y-3"
                >
                  {subsections?.map((subsection, index) => (
                    <Subsection
                      key={subsection.id}
                      subsection={subsection}
                      onChangeName={(newName) =>
                        handleUpdateSubsection(newName, subsection.id)
                      }
                      onRemove={() => handleRemoveSubsection(subsection.id)}
                      index={index}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <button
            type="button"
            className={`flex items-center space-x-2 ${subsections.length > 0 ? "mt-3" : ""}`}
            onClick={() =>
              setSubsections([...subsections, { id: uuid(), name: "" }])
            }
          >
            <PlusIcon width="20" height="20" />
            <p className="text-primary-blue text-sm font-semibold">New entry</p>
          </button>
        </div>
      )}

      <div className={`mt-6 ${fieldContainerClasses}`}>
        <p className={fieldLabelClasses}>Section style</p>
        <TabsBg
          tabs={sectionStyleOptions}
          activeTab={selectedSectionStyle}
          onChange={(tab) => setSelectedSectionStyle(tab)}
          className="bg-gray-150 text-sm w-80 ml-auto"
          tabClassName="py-1.5 text-center whitespace-nowrap"
        />
      </div>

      {(title.trim().toLowerCase() === "hpi" ||
        title.trim().toLowerCase() === "subjective") && (
        <div className={`mt-6 ${fieldContainerClasses}`}>
          <p className={fieldLabelClasses}>Detail level</p>
          <TabsBg
            tabs={detailLevelOptions}
            activeTab={selectedDetailLevel}
            onChange={(tab) => setSelectedDetailLevel(tab)}
            className="bg-gray-150 text-sm w-38"
            tabClassName="py-1.5 text-center whitespace-nowrap"
          />
        </div>
      )}

      {(title.trim().toLowerCase() === "hpi" ||
        title.trim().toLowerCase() === "subjective") && (
        <div className={`mt-6 ${fieldContainerClasses}`}>
          <p className={fieldLabelClasses}>Split by problem</p>
          <Switch
            size="medium-large"
            enabled={splitByProblem}
            onChange={() => setSplitByProblem(!splitByProblem)}
          />
        </div>
      )}

      {(title.trim().toLowerCase() === "assessment and plan" ||
        title.trim().toLowerCase() === "plan") && (
        <div className={`mt-6 ${fieldContainerClasses}`}>
          <p className={fieldLabelClasses}>Include differential diagnosis</p>
          <Switch
            size="medium-large"
            enabled={includeDifferential}
            onChange={() => setIncludeDifferential(!includeDifferential)}
          />
        </div>
      )}

      <div className={`mt-6 ${fieldContainerClasses}`}>
        <p className={fieldLabelClasses}>Hide section by default</p>
        <Switch
          size="medium-large"
          enabled={hideByDefault}
          onChange={() => setHideByDefault(!hideByDefault)}
        />
      </div>
    </div>
  );
};

export default TemplateSectionCard;
