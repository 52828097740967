import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Popover } from "@mui/material";
import { getPatientInfo } from "../../../store/patient/thunks";
import { setSelectedPatientInfoAction } from "../../../store/patient/actions";
import { Pages } from "../../../helpers/constants";
import { ReactComponent as UserMinusRoundedIcon } from "../../../assets/icons/user-minus-rounded.svg";
import { ReactComponent as EllipsisHorizontal2Icon } from "../../../assets/icons/ellipsis-horizontal2.svg";
import { ReactComponent as UserRoundedIcon } from "../../../assets/icons/user-rounded.svg";
import { ReactComponent as UsersGroupRoundedIcon } from "../../../assets/icons/users-group-rounded.svg";

const ScribePatient = ({
  patient,
  setShowAssignPatientModal,
  handleAssignPatientId,
}) => {
  const [openOptions, setOpenOptions] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onCloseOptions = () => {
    setOpenOptions(false);
    setAnchorEl(null);
  };

  const onOpenOptions = (e) => {
    setOpenOptions(true);
    setAnchorEl(e.currentTarget);
  };

  let sexAbbreviation = "";
  if (patient.sex === "female") {
    sexAbbreviation = "F";
  } else if (patient.sex === "male") {
    sexAbbreviation = "M";
  }

  const patientDescription = patient
    ? [
        patient.birthdate
          ? `${new Date().getFullYear() - new Date(patient.birthdate).getFullYear()} yo`
          : "",
        sexAbbreviation,
        patient.pronouns,
        patient.birthdate
          ? new Date(patient.birthdate).toLocaleDateString(undefined, {
              month: "short",
              day: "numeric",
              year: "numeric",
            })
          : "",
      ]
        .filter((field) => !!field)
        .join(" • ")
    : "";

  const openPatientPage = () => {
    dispatch(
      getPatientInfo(patient.patient_id, (patientInfo) => {
        dispatch(setSelectedPatientInfoAction(patientInfo));
        navigate(`${Pages.PATIENTS}/${patient.patient_id}`);
      }),
    );
  };

  return (
    <>
      <div className="flex items-center gap-2 md:hidden font-semibold text-base">
        <p className="cursor-pointer" onClick={openPatientPage}>
          {patient.first_name} {patient.last_name}
        </p>
        <EllipsisHorizontal2Icon
          onClick={onOpenOptions}
          width="20"
          height="20"
          className="stroke-2 text-tertiary"
        />
      </div>
      <div className="hidden md:flex gap-3 bg-gray-110 items-center rounded-full p-1">
        {patient.photo ? (
          <img
            src={patient.photo}
            alt="patient"
            className="w-8 h-8 rounded-full cursor-pointer"
            onClick={openPatientPage}
          />
        ) : (
          <p
            className="w-8 h-8 rounded-full bg-gray-foreground text-xs text-primary-blue uppercase
              flex items-center justify-center cursor-pointer"
            onClick={openPatientPage}
          >
            {patient.first_name?.charAt(0)}
            {patient.last_name?.charAt(0)}
          </p>
        )}
        <div
          className="flex flex-col justify-center cursor-pointer"
          onClick={openPatientPage}
        >
          <p className="font-semibold text-sm">
            {patient.first_name} {patient.last_name}
          </p>
          <p className="text-xs text-gray-500">{patientDescription}</p>
        </div>
        <div
          className="bg-white rounded-full cursor-pointer p-2 align-middle"
          onClick={onOpenOptions}
        >
          <EllipsisHorizontal2Icon
            width="20"
            height="20"
            className="stroke-2 text-tertiary"
          />
        </div>
      </div>
      {openOptions && anchorEl && (
        <Popover
          open={openOptions}
          onClose={onCloseOptions}
          className="drop-shadow-2xl border border-gray-foreground"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          PaperProps={{
            sx: {
              borderRadius: "8px",
            },
          }}
        >
          <div className="flex flex-col gap-1 py-2 items-start text-sm font-medium">
            <button
              type="button"
              className="flex gap-2 px-4 py-2 w-full group hover:text-primary hover:bg-gray-background"
              onClick={openPatientPage}
            >
              <UserRoundedIcon
                width="20"
                height="20"
                className="opacity-60 group-hover:opacity-100"
              />
              <span>Patient Profile</span>
            </button>
            <button
              type="button"
              className="flex gap-2 px-4 py-2 w-full group hover:text-primary hover:bg-gray-background"
              onClick={() => {
                setShowAssignPatientModal(true);
                onCloseOptions();
              }}
            >
              <UsersGroupRoundedIcon
                width="20"
                height="20"
                className="opacity-60 group-hover:opacity-100"
              />
              <span>Change Patient</span>
            </button>
            <button
              type="button"
              className="flex gap-2 px-4 py-2 w-full group hover:text-primary hover:bg-gray-background"
              onClick={() => handleAssignPatientId(null)}
            >
              <UserMinusRoundedIcon
                width="20"
                height="20"
                className="opacity-60 group-hover:opacity-100"
              />
              <span>Unassign Patient</span>
            </button>
          </div>
        </Popover>
      )}
    </>
  );
};

export default ScribePatient;
