import { useEffect, useRef, useState } from "react";
import DropdownButton from "./DropdownButton";
import DropdownIconButton from "./DropdownIconButton";
import DropdownPopover from "./DropdownPopover";

const Dropdown = ({
  preMadeItems,
  customItems,
  selectedItem,
  onSelectItem,
  onCreateItem,
  defaultSelect,
  onUpdateTitle,
  icon,
  itemName,
  newTitle,
  showTitleInput,
  closeDropdownOnSelect,
  withoutSearch,
  withoutSubtitles,
  inputWithBorder,
  createNew,
  onRemoveCustomItem,
  onHidePreMadeItem,
}) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dropdownWidth, setDropdownWidth] = useState(null);
  const containerRef = useRef(null);

  const handleResize = () => {
    if (containerRef.current) {
      setDropdownWidth(containerRef.current.offsetWidth);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [containerRef]);

  useEffect(() => {
    handleResize();
  }, [openDropdown]);

  return (
    <div ref={containerRef}>
      {defaultSelect ? (
        <DropdownButton
          onClick={(e) => {
            setOpenDropdown(true);
            if (!showTitleInput) {
              setAnchorEl(e.currentTarget);
            } else {
              setAnchorEl(e.currentTarget.parentElement);
            }
          }}
          label={selectedItem?.name || `Select ${itemName}`}
          selectedItem={selectedItem}
          newTitle={newTitle}
          showTitleInput={showTitleInput}
          onUpdateTitle={onUpdateTitle}
        />
      ) : (
        <DropdownIconButton
          onClick={(e) => {
            setOpenDropdown(true);
            setAnchorEl(e.currentTarget.parentElement);
          }}
          selectedItem={selectedItem}
          clicked={openDropdown}
          label={`Select ${itemName}`}
          onUpdateTitle={onUpdateTitle}
          icon={icon}
          inputWithBorder={inputWithBorder}
          createNew={createNew}
          itemName={itemName}
        />
      )}

      {openDropdown && anchorEl && (
        <DropdownPopover
          open={openDropdown}
          onClose={() => {
            setOpenDropdown(false);
            setAnchorEl(null);
          }}
          anchorEl={anchorEl}
          preMadeItems={preMadeItems}
          customItems={customItems}
          onSelectItem={(item) => {
            onSelectItem(item);
            if (closeDropdownOnSelect) {
              setOpenDropdown(false);
              setAnchorEl(null);
            }
          }}
          onCreateItem={onCreateItem}
          width={dropdownWidth}
          itemName={itemName}
          withoutSearch={withoutSearch}
          withoutSubtitles={withoutSubtitles}
          onRemoveCustomItem={onRemoveCustomItem}
          onHidePreMadeItem={onHidePreMadeItem}
        />
      )}
    </div>
  );
};

export default Dropdown;
