import { useState } from "react";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import Popover from "@mui/material/Popover";
import { saveEncounterNote } from "../../../store/conversation/thunks";
import { setNotificationAction } from "../../../store/user/actions";
import { NoteTabs } from "../../../helpers/constants";
import { copyText } from "../../../helpers/helpers";
import TextEditor from "../../Basic/TextEditor";
import Button from "../../Basic/Button";
import { ReactComponent as CopyIcon } from "../../../assets/icons/copy-note.svg";
import { ReactComponent as CardSendIcon } from "../../../assets/icons/card-send.svg";
import { ReactComponent as MoreIcon } from "../../../assets/icons/menu-dots.svg";

const getDate = (date) => {
  const dateObj = new Date(`${date.replace(/-/g, "/")} GMT+0`);
  return dateObj.toLocaleString("en-us", {
    month: "2-digit",
    day: "2-digit",
    year: "2-digit",
  });
};

const getTime = (date) => {
  const dateObj = new Date(`${date.replace(/-/g, "/")} GMT+0`);
  return dateObj.toLocaleString("en-us", {
    hour: "numeric",
    minute: "2-digit",
  });
};

const buttonClasses =
  "flex items-center gap-3 bg-gray-foreground text-primary whitespace-nowrap h-10 lg:h-11 text-sm font-semibold lg:text-base";

const optionButtonClasses =
  "px-2.5 py-2 flex items-center space-x-2.5 rounded-lg text-tertiary group enabled:hover:text-primary hover:bg-gray-background disabled:bg-gray-background";

const NoteInfo = ({ scribe, patient, onSaveNote, activeTab }) => {
  const [editedNote, setEditedNote] = useState(scribe.note);
  const [openOptions, setOpenOptions] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const dispatch = useDispatch();

  const handleCopyNote = () => {
    copyText(scribe.note, () =>
      dispatch(
        setNotificationAction({
          status: "success",
          title: "Successfully copied note content",
          desc: "Note content has been successfully copied to the clipboard",
        }),
      ),
    );
  };

  const handleSaveNote = () => {
    dispatch(saveEncounterNote(scribe.audio_id, editedNote, onSaveNote));
  };

  const onCloseOptions = () => {
    setOpenOptions(false);
    setAnchorEl(null);
  };

  const onOpenOptions = (e) => {
    setOpenOptions(true);
    setAnchorEl(e.currentTarget);
  };

  const getMessagesPreview = () => {
    let currentDate = null;

    return scribe.conversation.messages.map((message, index) => {
      let sender = "";
      if (message.is_automatic === 1) {
        sender = "Quadrant AI";
      } else if (message.from === "doctor") {
        sender = message.sender.display_name
          ? message.sender.display_name
          : `${message.sender.first_name} ${message.sender.last_name}`;
      } else if (message.from === "patient") {
        sender = `${patient.preferred_name ? patient.preferred_name : patient.first_name} ${patient.last_name}`;
      }

      const messageDate = getDate(message.send_at);
      const displayDate =
        currentDate !== messageDate ? (
          <div className="flex items-center space-x-4 mb-6">
            <p className="font-medium text-sm text-zinc-450">{messageDate}</p>
            <div className="bg-gray-150 h-0.5 w-full" />
          </div>
        ) : null;
      currentDate = messageDate;

      return (
        <div key={index} className="w-full text-base">
          {displayDate}
          <div className="flex flex-col lg:flex-row gap-2 lg:gap-4">
            <div className="w-full h-fit flex justify-between lg:flex-col lg:w-36 flex-none space-y-1">
              <p className="font-semibold truncate">{sender}</p>
              <p className="font-medium text-xs text-zinc-450">
                {getTime(message.send_at)}
              </p>
            </div>
            <p className="whitespace-pre-wrap">{message.message}</p>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="w-full grid grid-rows-tab-layout md:block h-full">
      {!isDesktop && (
        <div className="w-full bg-gray-background border-b border-gray-150 font-base font-semibold py-3 px-4 mb-4">
          {scribe.title}
        </div>
      )}
      {activeTab === NoteTabs.NOTE ? (
        <div className="h-full px-4">
          {scribe.note ? (
            <div className="w-full h-full grid grid-rows-note-layout gap-4">
              <TextEditor
                htmlMarkup={scribe.note}
                onChange={(htmlMarkup) => setEditedNote(htmlMarkup)}
                className="h-full min-h-fit max-h-full border rounded-xl"
                rerenderDeps={[scribe.note]}
              />

              <div className="pb-4 flex justify-end space-x-4 font-semibold">
                <div className="flex divide-x divide-gray-300">
                  <button
                    type="button"
                    className={`${buttonClasses} rounded-l-lg px-4`}
                    onClick={handleCopyNote}
                  >
                    <CopyIcon width="20" height="20" />
                    <p>Copy Note</p>
                  </button>
                  <button
                    type="button"
                    className={`${buttonClasses} rounded-r-lg px-3`}
                    onClick={onOpenOptions}
                  >
                    <MoreIcon width="20" height="20" />
                  </button>
                </div>

                <Button
                  size="unset"
                  className="whitespace-nowrap h-10 lg:h-11 px-4 text-sm lg:text-base"
                  onClick={handleSaveNote}
                >
                  Save Changes
                </Button>
              </div>
            </div>
          ) : (
            <div className="flex items-center p-3">
              <p>Note is being generated</p>
            </div>
          )}
        </div>
      ) : (
        <div className="space-y-4 h-full px-4 py-4">{getMessagesPreview()}</div>
      )}

      {openOptions && anchorEl && (
        <Popover
          open={openOptions}
          onClose={onCloseOptions}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: -8,
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          PaperProps={{
            sx: {
              borderRadius: "8px",
              width: "200px",
              boxShadow:
                "0px -4px 20px 0px rgba(0, 0, 0, 0.02), 0px 32px 64px -12px rgba(16, 24, 40, 0.08)",
            },
          }}
        >
          <div className="w-full flex flex-col space-y-1 p-2 text-sm font-medium">
            <button
              type="button"
              className={optionButtonClasses}
              // onClick={handleSendToEhr}
              disabled
            >
              <CardSendIcon
                width="20"
                height="20"
                className="opacity-60 enabled:group-hover:opacity-100"
              />
              <span>Send to EHR</span>
            </button>
          </div>
        </Popover>
      )}
    </div>
  );
};

export default NoteInfo;
