import { isEqual } from "lodash";

import { patientPanelTabs, patientProfileTabs } from "../../helpers/constants";
import LocalStorageService from "../../services/LocalStorageService";
import {
  LOGOUT,
  SET_CURR_PAGE,
  SET_DELETED_PATIENT_NOTE,
  SET_ENCOUNTER_TITLE,
  SET_LAST_SELECTED_PATIENT_PROFILE,
  SET_NUM_FILTERED_PATIENTS,
  SET_NUM_PATIENTS,
  SET_NUM_PATIENTS_BY_DOCTOR,
  SET_OPEN_EDIT_INFO_MODAL,
  SET_PATIENTS,
  SET_PATIENT_EXISTS_ERROR,
  SET_PATIENT_LOADER,
  SET_PATIENT_RECORDING_SCRIBE_TITLE,
  SET_PROFILE_CONVERSATION,
  SET_PROFILE_LOADING,
  SET_SEARCH,
  SET_SELECTED_DOCTOR_PATIENTS,
  SET_SELECTED_PATIENTS,
  SET_SELECTED_PATIENT_CAMPAIGNS,
  SET_SELECTED_PATIENT_ENROLLMENTS,
  SET_SELECTED_PATIENT_INFO,
  SET_SELECTED_PATIENT_INSURANCES,
  SET_SELECTED_PATIENT_NOTE,
  SET_SELECTED_PATIENT_NOTES,
  SET_SELECTED_PATIENT_PANEL_TAB,
  SET_SELECTED_PATIENT_PROFILE_TAB,
  SET_SELECTED_PATIENT_STATUSES,
  SET_TABLE_SCROLL_POSITION,
} from "../types";

const storageService = new LocalStorageService();

const init = {
  patientsPage: null,
  currPage: 1,
  numOfPatients: 0,
  numOfPatientsByDoctorId: {},
  numOfFilteredPatients: 0,
  rowsPerPage: 50,
  tableScrollPosition: 0,
  loader: false,
  searchValue: storageService.getItem("patientSearchInput") ?? "",
  selectedPatients: [],
  patientExistsError: false,
  selectedPatientInfo: storageService.getItem("selectedPatientInfo") ?? null,
  selectedPatientNotes: storageService.getItem("selectedPatientNotes", []),
  selectedPatientNote: null,
  selectedPatientEnrollments: null,
  lastSelectedPatientProfile: null,
  profileLoading: false,
  selectedDoctorPatients:
    storageService.getItem("selectedDoctorPatients") ?? null,
  selectedPatientPanelTab:
    storageService.getItem("selectedPatientPanelTab") ??
    patientPanelTabs.SCRIBE,
  selectedPatientProfileTab:
    storageService.getItem("selectedPatientProfileTab") ??
    patientProfileTabs.NOTES,
  profileConversation: null,
  openEditInfoModal: false,
  selectedPatientCampaigns:
    storageService.getItem("selectedPatientCampaigns") ?? [],
  selectedPatientStatuses:
    storageService.getItem("selectedPatientStatuses") ?? [],
  selectedPatientInsurances:
    storageService.getItem("selectedPatientInsurances") ?? [],
  patientRecordingScribeTitle: "Scribe",
};

export default function patientReducer(state = init, action) {
  const { type, payload } = action;

  switch (type) {
    case LOGOUT:
      storageService.removeItem("selectedPatientNotes");
      storageService.removeItem("patientSearchInput");
      storageService.removeItem("patientProfileTab");
      storageService.removeItem("selectedDoctorPatients");
      storageService.removeItem("selectedPatientPanelTab");
      storageService.removeItem("selectedPatientInfo");
      storageService.removeItem("selectedPatientProfileTab");
      storageService.removeItem("selectedPatientCampaigns");
      storageService.removeItem("selectedPatientStatuses");
      return init;
    case SET_PATIENTS:
      if (isEqual(state.patientsPage, payload.patients)) {
        return state;
      }

      return { ...state, patientsPage: payload.patients };
    case SET_SELECTED_PATIENTS:
      if (isEqual(state.selectedPatients, payload.patients)) {
        return state;
      }

      return { ...state, selectedPatients: payload.patients };
    case SET_SELECTED_PATIENT_INFO:
      storageService.setItem("selectedPatientInfo", payload.patientInfo);
      return {
        ...state,
        selectedPatientInfo: payload.patientInfo,
      };
    case SET_SELECTED_PATIENT_NOTES:
      if (isEqual(state.selectedPatientNotes, payload.patientNotes)) {
        return state;
      }

      storageService.setItem("selectedPatientNotes", payload.patientNotes);

      return {
        ...state,
        selectedPatientNotes: payload.patientNotes,
      };
    case SET_SELECTED_PATIENT_NOTE:
      return {
        ...state,
        selectedPatientNote: payload.patientNote,
      };

    case SET_ENCOUNTER_TITLE: {
      const encounter = state.selectedPatientNotes.find(
        (encounter) => encounter.conversation_history_id === payload.noteId,
      );

      if (!encounter || encounter.title === payload.name) {
        return state;
      }

      const updatedEncounters = state.selectedPatientNotes.map((encounter) => {
        if (encounter.conversation_history_id === payload.noteId) {
          return { ...encounter, title: payload.name };
        }

        return encounter;
      });

      return {
        ...state,
        selectedPatientNotes: updatedEncounters,
      };
    }

    case SET_SELECTED_PATIENT_ENROLLMENTS:
      return {
        ...state,
        selectedPatientEnrollments: payload.patientEnrollments,
      };

    case SET_LAST_SELECTED_PATIENT_PROFILE:
      return {
        ...state,
        lastSelectedPatientProfile: payload.patient,
      };
    case SET_PROFILE_LOADING:
      if (state.profileLoading === payload.profileLoading) {
        return state;
      }

      return { ...state, profileLoading: payload.profileLoading };
    case SET_CURR_PAGE:
      if (state.currPage === payload.currPage) {
        return state;
      }

      return { ...state, currPage: payload.currPage };
    case SET_SEARCH:
      if (state.searchValue === payload.searchValue) {
        return state;
      }

      storageService.setItem("patientSearchInput", payload.searchValue);
      return { ...state, searchValue: payload.searchValue };
    case SET_NUM_PATIENTS:
      if (state.numOfPatients === payload.numOfPatients) {
        return state;
      }

      return { ...state, numOfPatients: payload.numOfPatients };
    case SET_NUM_PATIENTS_BY_DOCTOR:
      return {
        ...state,
        numOfPatientsByDoctorId: {
          ...state.numOfPatientsByDoctorId,
          ...payload.numOfPatientsByDoctorId,
        },
      };
    case SET_NUM_FILTERED_PATIENTS:
      if (state.numOfFilteredPatients === payload.numOfFilteredPatients) {
        return state;
      }

      return { ...state, numOfFilteredPatients: payload.numOfFilteredPatients };
    case SET_PATIENT_LOADER:
      if (state.loader === payload.loader) {
        return state;
      }

      return { ...state, loader: payload.loader };
    case SET_TABLE_SCROLL_POSITION:
      if (state.tableScrollPosition === payload.tableScrollPosition) {
        return state;
      }

      return { ...state, tableScrollPosition: payload.tableScrollPosition };
    case SET_PATIENT_EXISTS_ERROR:
      if (state.patientExistsError === payload.patientExistsError) {
        return state;
      }

      return { ...state, patientExistsError: payload.patientExistsError };
    case SET_SELECTED_DOCTOR_PATIENTS:
      if (
        isEqual(state.selectedDoctorPatients, payload.selectedDoctorPatients)
      ) {
        return state;
      }

      storageService.setItem(
        "selectedDoctorPatients",
        payload.selectedDoctorPatients,
      );
      return {
        ...state,
        selectedDoctorPatients: payload.selectedDoctorPatients,
      };
    case SET_SELECTED_PATIENT_PANEL_TAB:
      if (state.selectedPatientPanelTab === payload.selectedPatientPanelTab) {
        return state;
      }

      storageService.setItem(
        "selectedPatientPanelTab",
        payload.selectedPatientPanelTab,
      );
      return {
        ...state,
        selectedPatientPanelTab: payload.selectedPatientPanelTab,
      };

    case SET_SELECTED_PATIENT_PROFILE_TAB:
      storageService.setItem(
        "patientProfileTab",
        payload.selectedPatientProfileTab,
      );
      return {
        ...state,
        selectedPatientProfileTab: payload.selectedPatientProfileTab,
      };
    case SET_PROFILE_CONVERSATION:
      return {
        ...state,
        profileConversation: payload.profileConversation,
      };
    case SET_OPEN_EDIT_INFO_MODAL:
      return {
        ...state,
        openEditInfoModal: payload.openEditInfoModal,
      };

    case SET_SELECTED_PATIENT_CAMPAIGNS:
      storageService.setItem(
        "selectedPatientCampaigns",
        payload.selectedPatientCampaigns,
      );
      return {
        ...state,
        selectedPatientCampaigns: payload.selectedPatientCampaigns,
      };
    case SET_SELECTED_PATIENT_STATUSES:
      storageService.setItem(
        "selectedPatientStatuses",
        payload.selectedPatientStatuses,
      );
      return {
        ...state,
        selectedPatientStatuses: payload.selectedPatientStatuses,
      };

    case SET_SELECTED_PATIENT_INSURANCES:
      storageService.setItem(
        "selectedPatientInsurances",
        payload.selectedPatientInsurances,
      );
      return {
        ...state,
        selectedPatientInsurances: payload.selectedPatientInsurances,
      };

    case SET_DELETED_PATIENT_NOTE: {
      const updatedNotesForNote = state.selectedPatientNotes.filter((note) => {
        return note.conversation_history_id !== payload.conversation_history_id;
      });

      storageService.setItem("selectedPatientNotes", updatedNotesForNote);

      return {
        ...state,
        selectedPatientNotes: updatedNotesForNote,
      };
    }

    case SET_PATIENT_RECORDING_SCRIBE_TITLE:
      return {
        ...state,
        patientRecordingScribeTitle: payload.patientRecordingScribeTitle,
      };

    default:
      return state;
  }
}
