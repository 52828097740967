import Skeleton from "@mui/material/Skeleton";

const ScribeListSkeleton = ({
  days = 3,
  notes = 5,
  bgColor = "#EAEDF3",
  animation = "wave",
  className = "",
}) => {
  return (
    <div className={`flex flex-col space-y-3 max-w-full ${className}`}>
      {[...Array(days)].map((_, index) => (
        <div
          key={`day-${index}`}
          className={`space-y-1 pb-2 ${index < days - 1 ? "border-b" : ""}`}
        >
          <Skeleton
            animation={animation}
            variant="rounded"
            width={64}
            height={12}
            sx={{ bgcolor: bgColor, borderRadius: "12px" }}
          />
          <div className="pb-1">
            {[...Array(notes)].map((_, index) => (
              <div key={`note-${index}`} className="py-2 px-3 space-y-1">
                <Skeleton
                  animation={animation}
                  variant="rounded"
                  width={128}
                  height={12}
                  sx={{ bgcolor: bgColor, borderRadius: "12px" }}
                />
                <Skeleton
                  animation={animation}
                  variant="rounded"
                  width={64}
                  height={12}
                  sx={{ bgcolor: bgColor, borderRadius: "12px" }}
                />
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ScribeListSkeleton;
