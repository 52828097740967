import { useCallback, useEffect, useRef, useState } from "react";
import { v4 as uuid } from "uuid";

const initialState = {
  audioId: null,
  hasError: false,
  isSaving: false,
  startedAt: null,
  accumulatedSeconds: 0,
};

const useIndependentScribeRecorder = (
  microphoneId,
  mediaRecorder,
  sendAudioPart,
  onSave,
) => {
  const [recorderState, setRecorderState] = useState(initialState);
  const wakeLock = useRef(null);

  useEffect(() => {
    // add an event listener for iOS devices to trigger wakeLock after screen touch
    document.addEventListener("click", handleWakeLockAfterTouch);

    return () => {
      document.removeEventListener("click", handleWakeLockAfterTouch);
    };
  }, []);

  const handleWakeLockAfterTouch = () => {
    if (wakeLock.current === null) {
      requestWakeLock().then(() => console.log("Wake lock requested"));
    }
  };

  const handleWakeLockRelease = () => {
    console.log("Screen Wake Lock released:", wakeLock.current.released);
  };

  const requestWakeLock = async () => {
    if ("wakeLock" in navigator) {
      try {
        wakeLock.current = await navigator.wakeLock.request();
        wakeLock.current.addEventListener("release", handleWakeLockRelease);
        console.log("Screen Wake Lock released:", wakeLock.current.released);
      } catch (err) {
        console.error(`${err.name}, ${err.message}`);
      }
    } else {
      console.warn("Wake Lock API is not supported in this browser.");
    }
  };

  const startRecording = useCallback(() => {
    if (
      !mediaRecorder ||
      recorderState.audioId ||
      !microphoneId ||
      recorderState.startedAt
    ) {
      // alert("failed to start recording");
      return;
    }

    setRecorderState((state) => ({
      ...state,
      audioId: uuid(),
      isSaving: false,
      startedAt: new Date(),
      accumulatedSeconds: 0,
    }));

    console.log(
      "starting media recorder, current status: ",
      mediaRecorder?.state,
    );
    mediaRecorder.start(2000);

    requestWakeLock().then(() => console.log("Wake lock requested"));
  }, [
    mediaRecorder,
    microphoneId,
    recorderState.audioId,
    recorderState.startedAt,
  ]);

  useEffect(() => {
    const handleDataAvailable = (event) => {
      if (event.data.size === 0) {
        return;
      }

      sendAudioPart(
        new Blob([event.data], { type: mediaRecorder.mimeType }),
        "progress",
      );
    };

    if (!mediaRecorder) {
      // alert("mediaRecorder is not available");
      return;
    }

    mediaRecorder.addEventListener("dataavailable", handleDataAvailable);

    return () => {
      if (mediaRecorder) {
        mediaRecorder.removeEventListener("dataavailable", handleDataAvailable);
      }
    };
  }, [mediaRecorder, sendAudioPart]);

  useEffect(() => {
    const handleStop = () => {
      const audioId = recorderState.audioId;
      if (!audioId) {
        // alert("cancelled?");
        return;
      }

      setRecorderState((state) => {
        return {
          ...state,
          audioId: null,
          isSaving: true,
        };
      });

      if (wakeLock.current) {
        wakeLock.current
          .release()
          .then(() => (wakeLock.current = null))
          .catch((err) => {
            console.error("Error releasing Wake Lock:", err);
            wakeLock.current = null;
          });
      }

      onSave(audioId);
    };

    if (mediaRecorder) {
      mediaRecorder.addEventListener("stop", handleStop);

      return () => {
        mediaRecorder.removeEventListener("stop", handleStop);
      };
    }
  }, [onSave, mediaRecorder, recorderState.audioId]);

  const togglePause = useCallback(() => {
    if (mediaRecorder && mediaRecorder.state === "recording") {
      mediaRecorder.pause();

      setRecorderState((state) => ({
        ...state,
        startedAt: null,
        accumulatedSeconds:
          state.accumulatedSeconds + (new Date() - state.startedAt) / 1000,
      }));
    } else if (mediaRecorder && mediaRecorder.state === "paused") {
      setRecorderState((state) => ({
        ...state,
        startedAt: new Date(),
      }));
      mediaRecorder.resume();
    }
  }, [mediaRecorder]);

  const toggleError = useCallback(() => {
    setRecorderState((state) => ({
      ...state,
      hasError: true,
    }));
  }, []);

  return {
    recorderState,
    startRecording,
    togglePause,
    toggleError,
    cancelRecording: useCallback(() => {
      setRecorderState(initialState);
      if (
        mediaRecorder &&
        (mediaRecorder.state === "recording" ||
          mediaRecorder.state === "paused")
      ) {
        mediaRecorder.stop();
      }
    }, [mediaRecorder]),
    saveRecording: useCallback(() => {
      if (
        mediaRecorder &&
        (mediaRecorder.state === "recording" ||
          mediaRecorder.state === "paused")
      ) {
        mediaRecorder.stop();
      }
    }, [mediaRecorder]),
  };
};

export default useIndependentScribeRecorder;
