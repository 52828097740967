import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import emptyNotesIllustration from "../../../assets/no-encounters.svg";
import { setSelectedPatientNoteAction } from "../../../store/patient/actions";
import { getPatientNotes } from "../../../store/patient/thunks";
import NotesSkeleton from "../../Skeletons/NotesSkeleton";
import NoteCard from "./NoteCard";

const NotesTab = () => {
  const { id } = useParams();
  const { selectedPatientNotes, profileLoading } = useSelector(
    (state) => state.patient,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPatientNotes(id));

    const interval = setInterval(() => {
      dispatch(getPatientNotes(id));
    }, 3000);

    return () => clearInterval(interval);
  }, [id, dispatch]);

  const groupedNotes =
    selectedPatientNotes?.reduce((acc, note) => {
      const startDate = new Date(note.started_at.replace(/-/g, "/"));
      const monthYear = startDate.toLocaleString("en-US", {
        month: "long",
        year: "numeric",
      });
      if (!acc[monthYear]) {
        acc[monthYear] = [];
      }
      acc[monthYear].push(note);
      return acc;
    }, {}) || [];

  return (
    <div className="w-full pb-20 md:pb-0">
      {selectedPatientNotes?.length > 0 ? (
        groupedNotes &&
        Object.keys(groupedNotes).length > 0 && (
          <div className="flex flex-col">
            {Object.entries(groupedNotes)
              .sort((a, b) => {
                const dateA = new Date(a[0]);
                const dateB = new Date(b[0]);
                return dateB - dateA;
              })
              .map(([monthYear, notes]) => (
                <div key={monthYear} className="space-y-2 mb-2">
                  <p className="text-sm font-semibold uppercase">{monthYear}</p>
                  <div className="border-l ml-2 pl-2 lg:ml-3 lg:pl-4 pt-3 pb-4">
                    {notes.map((note) => (
                      <NoteCard
                        key={note.note_id}
                        note={note}
                        onClick={() =>
                          dispatch(setSelectedPatientNoteAction(note))
                        }
                      />
                    ))}
                  </div>
                </div>
              ))}
          </div>
        )
      ) : !selectedPatientNotes || profileLoading ? (
        <NotesSkeleton />
      ) : (
        <div className="mx-auto w-full h-35vh-dynamic flex flex-col items-center justify-center">
          <img
            src={emptyNotesIllustration}
            width="578"
            height="200"
            className="mb-8"
            alt="No Notes"
          />
          <p className="text-xl lg:text-2xl font-semibold mb-1">
            No Previous Notes
          </p>
          <p className="text-base font-medium text-tertiary">
            We’re ready whenever you are...
          </p>
        </div>
      )}
    </div>
  );
};

export default NotesTab;
