import Warning from "./index";
import notEnoughTranscriptImage from "../../../assets/not-enough-transcript.svg";
import { useMediaQuery } from "react-responsive";

const NotEnoughTranscript = ({
  forceHorizontal = false,
  onSubmit = () => {},
}) => {
  const isDesktop = useMediaQuery({ minWidth: 768 });

  return (
    <Warning
      open={true}
      image={notEnoughTranscriptImage}
      title="Not enough transcript"
      description="There is not enough audio to generate a note"
      submitText="Try again"
      horizontal={forceHorizontal || !isDesktop}
      onSubmit={onSubmit}
      onCancel={onSubmit}
    />
  );
};

export default NotEnoughTranscript;
