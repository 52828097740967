import { useEffect, useState } from "react";

const PillTabsBg = ({
  tabs,
  activeTab,
  onChange,
  className,
  tabClassName,
  activeBgColor = "primary-blue",
  activeTextColor = "white",
}) => {
  const [translateX, setTranslateX] = useState(0);

  useEffect(() => {
    setTranslateX(100 * tabs.map((tab) => tab.title).indexOf(activeTab) + 2);
  }, [activeTab, tabs]);

  const getTab = (tab) => {
    return (
      <li
        key={tab.title}
        onClick={() => onChange(tab.title)}
        className={`w-8 sm:w-25 cursor-pointer font-medium rounded-full py-1 px-2
          flex items-center h-7 justify-center z-[1] transition-all group
          ${tabClassName}
          ${activeTab === tab.title ? `active text-${activeTextColor}` : "text-zinc-500"}
        `}
      >
        <div className="hidden sm:block">{tab.title}</div>
        <div className="sm:hidden">{tab.icon}</div>
      </li>
    );
  };

  return (
    <ul
      className={`flex bg-gray-150 text-xs h-9 rounded-full p-1 relative ${className}`}
    >
      <div
        aria-hidden="true"
        style={{
          transform: `translateX(${translateX}%) translateY(-50%)`,
        }}
        className={`w-8 sm:w-25 absolute h-7 p-1 rounded-full z-0 left-0.5 top-1/2 transition-all bg-${activeBgColor}`}
      />
      {tabs.map((tab) => getTab(tab))}
    </ul>
  );
};

export default PillTabsBg;
