import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as ArrowDownIcon } from "../../../assets/icons/arrow-down.svg";
import { filtersClear } from "../../../helpers/constants";
import { setActiveFiltersAction } from "../../../store/conversation/actions";
import Checkbox from "../../Basic/Checkbox";
import RadioButton from "../../Basic/RadioButton";

const FilterDropdownButton = ({
  filterName,
  icon,
  arrow,
  options,
  openedDropdown,
  setOpenedDropdown,
  multipleSelect,
}) => {
  const { activeFilters, initFilters, activeInbox, conversationsCounts } =
    useSelector((state) => state.conversation);
  const dispatch = useDispatch();

  const onApplyFilters = (filters) => {
    dispatch(setActiveFiltersAction(filters));
  };

  const isFilterApplied = () => {
    if (!initFilters) {
      return false;
    }
    if (multipleSelect) {
      if (filterName === "status") {
        return (
          activeFilters.status[activeInbox].length !==
          initFilters.status[activeInbox].length
        );
      }
      return (
        activeFilters[filterName].length !== initFilters[filterName].length
      );
    }
    return activeFilters[filterName] !== "all";
  };

  const clearAll = () => {
    if (filterName === "status") {
      onApplyFilters({
        ...activeFilters,
        status: {
          ...activeFilters.status,
          [activeInbox]: filtersClear.status[activeInbox],
        },
      });
    } else {
      onApplyFilters({
        ...activeFilters,
        [filterName]: filtersClear[filterName],
      });
    }
  };

  const selectAll = () => {
    if (filterName === "status") {
      onApplyFilters({
        ...activeFilters,
        status: {
          ...activeFilters.status,
          [activeInbox]: initFilters.status[activeInbox],
        },
      });
    } else {
      onApplyFilters({
        ...activeFilters,
        [filterName]: initFilters[filterName],
      });
    }
  };

  const onSelectOption = (option) => {
    if (multipleSelect) {
      if (filterName === "status") {
        if (activeFilters.status[activeInbox].includes(option)) {
          onApplyFilters({
            ...activeFilters,
            status: {
              ...activeFilters.status,
              [activeInbox]: activeFilters.status[activeInbox].filter(
                (item) => item !== option,
              ),
            },
          });
        } else {
          onApplyFilters({
            ...activeFilters,
            status: {
              ...activeFilters.status,
              [activeInbox]: [...activeFilters.status[activeInbox], option],
            },
          });
        }
      } else if (activeFilters[filterName].includes(option)) {
        onApplyFilters({
          ...activeFilters,
          [filterName]: activeFilters[filterName].filter(
            (item) => item !== option,
          ),
        });
      } else {
        onApplyFilters({
          ...activeFilters,
          [filterName]: [...activeFilters[filterName], option],
        });
      }
    } else {
      onApplyFilters({
        ...activeFilters,
        [filterName]: option,
      });
    }
  };

  const isActiveOption = (option) => {
    if (multipleSelect) {
      if (filterName === "status") {
        return activeFilters.status[activeInbox].includes(option.value);
      }
      return activeFilters[filterName].includes(option.value);
    }
    return activeFilters[filterName] === option.value;
  };

  const getOptions = () => {
    return options.map((option) => {
      const isActive = isActiveOption(option);
      let count = null;
      if (conversationsCounts?.[activeInbox]) {
        if (filterName === "time") {
          count =
            conversationsCounts[activeInbox][option.text] !== undefined
              ? conversationsCounts[activeInbox][option.text]
              : null;
        } else {
          count =
            conversationsCounts[activeInbox][option.value] !== undefined
              ? conversationsCounts[activeInbox][option.value]
              : null;
        }
      }

      return (
        <button
          type="button"
          key={option.value}
          onClick={() => onSelectOption(option.value)}
          className={`p-3 w-full text-left flex justify-between rounded-lg hover:bg-indigo-50
            ${isActive ? "text-primary-blue bg-indigo-50" : "text-zinc-700"}`}
        >
          <div className="flex items-center space-x-2">
            {multipleSelect ? (
              <Checkbox
                checked={isActive}
                onClick={(e) => e.stopPropagation()}
                variant="dark"
              />
            ) : (
              <RadioButton
                checked={isActive}
                onClick={() => onSelectOption(option.value)}
                variant="dark"
              />
            )}
            <p>{option.text}</p>
          </div>

          {count !== null && (
            <div
              className={`h-5 min-w-[28px] rounded-full text-white text-xs font-medium px-1.5
                flex items-center justify-center
                ${isActive ? "bg-primary-blue" : "bg-slate-400"}`}
            >
              {count}
            </div>
          )}
        </button>
      );
    });
  };

  return (
    <div
      onClick={() => {
        if (openedDropdown === filterName) {
          setOpenedDropdown("");
        } else {
          setOpenedDropdown(filterName);
        }
      }}
      className={`flex items-center justify-center rounded-full capitalize select-none cursor-pointer
        ${icon ? "p-1.5" : "py-2 px-3"}`}
    >
      <div
        className={`text-xs leading-none font-medium flex-none relative
              ${isFilterApplied() ? "text-blue-1000" : "text-primary"}`}
      >
        <div>{icon ? icon : filterName}</div>
        {isFilterApplied() && (
          <div className="absolute -top-0.5 -right-0.5 bg-primary-blue w-2.5 h-2.5 rounded-full" />
        )}
      </div>
      {arrow && (
        <ArrowDownIcon
          stroke={isFilterApplied() ? "#122C62" : "#121212"}
          className={`mt-0.5 ml-1 transition-all duration-300
                  ${openedDropdown === filterName ? "-rotate-180" : ""}`}
          width="10"
          height="6"
        />
      )}

      {openedDropdown === filterName && (
        <div
          className="absolute left-0 top-9 w-full bg-white rounded-2.5 shadow-md overflow-hidden
              leading-tight text-sm font-medium"
        >
          <div className="p-2 flex flex-col items-start space-y-1">
            {getOptions()}
          </div>

          {multipleSelect && (
            <div className="border-t flex w-full items-center">
              <button
                type="button"
                className="py-2.5 px-5 w-full text-zinc-450 hover:text-primary"
                onClick={clearAll}
              >
                Clear All
              </button>
              <button
                type="button"
                className="py-2.5 px-5 w-full text-zinc-450 hover:text-primary"
                onClick={selectAll}
              >
                Select All
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FilterDropdownButton;
