import { useState, useEffect, useRef, useCallback } from "react";
import { SoundMeter } from "../helpers/soundMeter";
import { getAudioContext } from "../components/IndependentScribe/audioContextSingleton";

const useVolumeLevel = (mediaRecorder) => {
  const [level, setLevel] = useState(0);
  const [noAudioDetected, setNoAudioDetected] = useState(false);
  const isRecording = useRef(false);
  const animationFrameRef = useRef(null);
  const soundMeterRef = useRef(null);
  const noAudioTimeoutRef = useRef(null);
  const volumeHistoryRef = useRef([]);
  const HISTORY_LENGTH = 100; // Keep track of the last 100 volume readings
  const NO_AUDIO_THRESHOLD = 1; // Adjust this value based on testing
  const NO_AUDIO_DURATION = 10000; // 10 seconds of very low audio before triggering warning

  const updateVolume = useCallback(() => {
    if (!isRecording.current || !soundMeterRef.current) {
      return;
    }

    const newVolume = Math.min(soundMeterRef.current.instant * 200, 100);
    setLevel(newVolume);

    volumeHistoryRef.current.push(newVolume);
    if (volumeHistoryRef.current.length > HISTORY_LENGTH) {
      volumeHistoryRef.current.shift();
    }

    const averageVolume =
      volumeHistoryRef.current.reduce((sum, vol) => sum + vol, 0) /
      volumeHistoryRef.current.length;

    if (
      averageVolume <= NO_AUDIO_THRESHOLD &&
      volumeHistoryRef.current.length === HISTORY_LENGTH
    ) {
      if (!noAudioTimeoutRef.current) {
        noAudioTimeoutRef.current = setTimeout(() => {
          setNoAudioDetected(true);
        }, NO_AUDIO_DURATION);
      }
    } else {
      setNoAudioDetected(false);
      clearTimeout(noAudioTimeoutRef.current);
      noAudioTimeoutRef.current = null;
    }

    animationFrameRef.current = requestAnimationFrame(updateVolume);
  }, []);

  // useEffect(() => {
  //   console.log(mediaRecorder);
  // }, [mediaRecorder]);

  useEffect(() => {
    if (!mediaRecorder) {
      console.error("MediaRecorder not provided to useVolumeLevel hook");
      setNoAudioDetected(true);
      return;
    }

    const audioContext = getAudioContext();

    const initializeAudio = async () => {
      try {
        console.log(
          `Initializing audio, current status: ${audioContext?.state}; media recorder: ${mediaRecorder?.state}`,
        );
        if (audioContext.state === "suspended") {
          await audioContext.resume();
        }

        soundMeterRef.current = new SoundMeter(audioContext);
        await soundMeterRef.current.connectToSource(mediaRecorder.stream);

        isRecording.current = true;
        updateVolume();
      } catch (error) {
        console.error("Error initializing audio:", error);
      }
    };

    initializeAudio();

    const handleTouchStart = () => {
      if (audioContext.state === "suspended") {
        audioContext.resume().then(() => {
          console.log("AudioContext resumed by user interaction");
        });
      }
    };
    document.addEventListener("touchstart", handleTouchStart);

    return () => {
      isRecording.current = false;
      if (soundMeterRef.current) {
        soundMeterRef.current.stop();
      }

      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }

      clearTimeout(noAudioTimeoutRef.current);
      document.removeEventListener("touchstart", handleTouchStart);
    };
  }, [mediaRecorder, updateVolume]);

  return [level, noAudioDetected];
};

export default useVolumeLevel;
