import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Device } from "twilio-client";
import {
  Pages,
  patientPanelTabs,
  twilioDeviceStatuses,
} from "../../helpers/constants";
import { store } from "../../store";
import { setSelectedEventAction } from "../../store/campaign/actions";
import {
  collapseSidebarAction,
  selectConversationAction,
  setConversationAction,
  setMessagesAction,
  setSelectedConversationTabAction,
} from "../../store/conversation/actions";
import { setSelectedPatientPanelTabAction } from "../../store/patient/actions";
import {
  setDeviceStatusAction,
  setOpenCallModalAction,
  setOpenCallOptionsModalAction,
} from "../../store/voiceRecorder/actions";
import { getTwilioToken } from "../../store/voiceRecorder/thunks";
import { setInterruptedRecordingActionAction } from "../IndependentScribe/store/actions";
import { SetInterruptedRecordingActionType } from "../IndependentScribe/store/types";
import InsightPanel from "../Messaging/InsightPanel";
import ChatCallScribePanel from "./ChatCallScribePanel";
import Navigation from "./Navigation";
import NotificationsWrapper from "./NotificationsWrapper";

const Layout = ({ children }) => {
  const { READY, RINGING, IN_PROGRESS, OFFLINE } = twilioDeviceStatuses;
  const { currentConversation, selectedConversationTab, messages } =
    useSelector((state) => state.conversation);
  const {
    twilioToken,
    openCallOptionsModal,
    callEvent,
    deviceStatus,
    recordingStarted,
    openCallModal,
  } = useSelector((state) => state.voiceRecorder);
  const { selectedPatientInfo, selectedPatientPanelTab, profileConversation } =
    useSelector((state) => state.patient);
  const [twilioDevice, setTwilioDevice] = useState(new Device());
  const [call, setCall] = useState(null);
  const [isMuted, setIsMuted] = useState(false);
  const [isPatientProfile, setIsPatientProfile] = useState(false);
  const [isInbox, setIsInbox] = useState(false);
  const isSidebarStatic = useMediaQuery({ minWidth: 1340 });
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const isHeightSm = useMediaQuery({ minHeight: 640 });
  const dispatch = useDispatch();

  const selectTab = (tab) => {
    const { isCurrentlyRecording } = store.getState().scribe;

    if (isCurrentlyRecording) {
      dispatch(
        setInterruptedRecordingActionAction({
          type: SetInterruptedRecordingActionType.PATIENT_PROFILE_TAB_CHANGE,
          value: tab,
        }),
      );

      return;
    }

    dispatch(setSelectedPatientPanelTabAction(tab));
  };

  useEffect(() => {
    if (window.location.pathname !== Pages.MESSAGING && !isDesktop) {
      dispatch(selectConversationAction(""));
      dispatch(setConversationAction(null));
    }
    if (recordingStarted && !isDesktop) {
      dispatch(setOpenCallOptionsModalAction(true));
      dispatch(setSelectedPatientPanelTabAction(patientPanelTabs.SCRIBE));
    }
    if (openCallModal && !isDesktop) {
      dispatch(setOpenCallOptionsModalAction(true));
      dispatch(setSelectedPatientPanelTabAction(patientPanelTabs.CALL));
    }
  }, [isDesktop, dispatch]);

  useEffect(() => {
    dispatch(setSelectedEventAction(null));

    if (window.location.pathname.match(/\/patients\/\d+/)) {
      setIsPatientProfile(true);
    } else {
      setIsPatientProfile(false);
    }

    if (window.location.pathname === Pages.MESSAGING) {
      setIsInbox(true);
    } else {
      setIsInbox(false);
    }
  }, [dispatch, window.location.pathname]);

  useEffect(() => {
    if (twilioToken) {
      twilioDevice.setup(twilioToken, {
        codecPreferences: ["opus", "pcmu"],
        fakeLocalDTMF: true,
        enableRingingState: true,
        debug: false,
      });

      twilioDevice.on("ready", (device) => {
        console.log("twilio device ready");
        setTwilioDevice(device);
        dispatch(setDeviceStatusAction(READY));
      });

      twilioDevice.on("error", (error) => {
        dispatch(setDeviceStatusAction(OFFLINE));
        console.error("twilio error", error);
      });

      twilioDevice.on("disconnect", () => {
        console.log("twilio device disconnect");
        dispatch(setDeviceStatusAction(READY));
        dispatch(setOpenCallModalAction(false));
      });

      twilioDevice.on("incoming", (connection) => {
        console.log("twilio device incoming");

        connection.on("reject", () => {
          dispatch(setDeviceStatusAction(READY));
        });
      });

      twilioDevice.on("tokenWillExpire", () => {
        console.log("twilio token will expire");
        dispatch(getTwilioToken());
      });
    }
  }, [twilioToken, dispatch, twilioDevice, OFFLINE, READY]);

  useEffect(() => {
    console.debug("device status", deviceStatus);
    if (deviceStatus === OFFLINE) {
      dispatch(getTwilioToken());
    }
  }, [deviceStatus, OFFLINE, dispatch]);

  const handleDisconnect = useCallback(() => {
    console.log("handle disconnect");
    twilioDevice?.disconnectAll();
    dispatch(setDeviceStatusAction(READY));
  }, [twilioDevice, dispatch, READY]);

  useEffect(() => {
    if (call) {
      call.on("cancel", () => {
        console.log("call canceled");
        handleDisconnect();
      });
      call.on("disconnect", () => {
        console.log("call disconnected");
        handleDisconnect();
      });
      call.on("error", (error) => {
        handleDisconnect();
        console.error("call error", error);
      });
      call.on("reject", () => {
        console.log("call rejected");
        handleDisconnect();
      });
      call.on("reconnecting", (twilioError) => {
        console.log("call reconnecting", twilioError);
      });
    }
    return () => {
      console.debug("call is muted", false);
      setIsMuted(false);
    };
  }, [call, handleDisconnect]);

  useEffect(() => {
    if (call) {
      console.debug("call is muted", call.isMuted());
    } else {
      console.debug("call is muted", false);
    }
  }, [call]);

  useEffect(() => {
    if (callEvent) {
      if (callEvent.status === "ringing") {
        console.log("call ringing");
        dispatch(setDeviceStatusAction(RINGING));
      }
      if (callEvent.status === "in-progress") {
        console.log("call in progress");
        dispatch(setDeviceStatusAction(IN_PROGRESS));
      }
      if (callEvent.status === "completed") {
        console.log("call completed");
        handleDisconnect();
      }
    }
  }, [callEvent, IN_PROGRESS, RINGING, dispatch, handleDisconnect]);

  const handleCall = async (phone_number) => {
    console.log("handle call, device status", deviceStatus);
    if (deviceStatus !== twilioDeviceStatuses.READY || !twilioDevice) {
      dispatch(getTwilioToken());
    } else {
      const call = await twilioDevice?.connect({ To: phone_number });
      setCall(call);
    }
  };

  const handleMute = () => {
    setIsMuted(!isMuted);
    call.mute(!isMuted);
  };

  const clickOutsideConversationSidebar = () => {
    if (!isSidebarStatic) {
      dispatch(collapseSidebarAction(true));
    }
  };

  return (
    <NotificationsWrapper>
      <div
        className={`h-screen-dynamic w-screen overflow-hidden md:pt-0
          ${!isDesktop && (currentConversation || (selectedPatientInfo && isPatientProfile)) ? "pt-0" : "pt-16"}`}
      >
        <Navigation />
        <div
          className={`h-full w-full overflow-hidden grid grid-cols-conv-layout
            ${isDesktop ? "pl-[72px]" : ""}
            ${isPatientProfile && isDesktop ? "bg-gray-background" : ""}
            ${isPatientProfile && selectedPatientInfo && isDesktop ? "gap-4" : ""}`}
        >
          {children}
          <div className="overflow-hidden md:relative">
            {((selectedPatientInfo && isDesktop && isPatientProfile) ||
              ((openCallOptionsModal || recordingStarted) && !isDesktop)) && (
              <div
                className={`
                  ${
                    selectedPatientInfo && isDesktop && isPatientProfile
                      ? `h-full w-[42vw] min-w-[400px]
                        ${isHeightSm ? "py-4 pr-4 height-md:py-6 height-md:pr-6" : "py-2 pr-2"}`
                      : ""
                  }
                  ${(openCallOptionsModal || recordingStarted) && !isDesktop ? "absolute top-0 left-0 z-20 h-screen-dynamic w-full" : ""}`}
              >
                <ChatCallScribePanel
                  handleCall={handleCall}
                  handleDisconnect={handleDisconnect}
                  handleMute={handleMute}
                  isMuted={isMuted}
                  conversation={profileConversation}
                  patient={selectedPatientInfo}
                  selectedTab={selectedPatientPanelTab}
                  setSelectedTab={selectTab}
                  messages={messages}
                  setMessages={(messages) =>
                    dispatch(setMessagesAction(messages))
                  }
                  scribeTab
                />
              </div>
            )}
            {isDesktop && currentConversation && isInbox && (
              <div
                onClick={clickOutsideConversationSidebar}
                className="flex min-h-0 max-h-full h-full w-screen max-w-full p-6 bg-gray-background space-x-6"
              >
                <ChatCallScribePanel
                  handleCall={handleCall}
                  handleDisconnect={handleDisconnect}
                  handleMute={handleMute}
                  isMuted={isMuted}
                  conversation={currentConversation}
                  patient={currentConversation.patient}
                  selectedTab={selectedConversationTab}
                  setSelectedTab={(tab) =>
                    dispatch(setSelectedConversationTabAction(tab))
                  }
                  messages={messages}
                  setMessages={(messages) =>
                    dispatch(setMessagesAction(messages))
                  }
                />

                <InsightPanel />
              </div>
            )}
          </div>
        </div>
      </div>
    </NotificationsWrapper>
  );
};

export default Layout;
