import Button from "../../Basic/Button";
import Modal from "../../Basic/Modal";
import { ReactComponent as ErrorIcon } from "../../../assets/icons/error-triangle.svg";

const DeleteErrorModal = ({ onClose, title, message }) => {
  return (
    <Modal className="pt-5 rounded-2xl" onClickAway={onClose}>
      <div className="w-120">
        <p className="text-base md:text-xl font-semibold mb-5 px-5">{title}</p>

        <div className="px-5 flex items-center space-x-2">
          <ErrorIcon width="32" height="32" className="flex-none" />
          <p className="text-sm md:text-base font-medium">{message}</p>
        </div>

        <div className="flex justify-end font-semibold text-sm bg-gray-background py-4 px-5 mt-5">
          <Button variant="gray" onClick={onClose}>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteErrorModal;
