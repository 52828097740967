import { useSelector } from "react-redux";
import FreeText from "../FreeText";
import Reports from "../Reports";
import TemplatePreviewWithoutContent from "../../Basic/TemplatePreviewWithoutContent";

const ScribeRecording = ({ scribe }) => {
  const { user } = useSelector((state) => state.user);
  const { selectedScribeNoteTemplate } = useSelector((state) => state.scribe);

  // TODO: implement support to switch between Transcript and Note
  return (
    <div>
      <FreeText scribe={scribe} originalFreeText={scribe?.free_text} />

      {/* demo mock reports */}
      {scribe?.patient_id === 29360 && user?.doctor_id === 15 && (
        <Reports className="mt-6 max-w-2xl" />
      )}

      {selectedScribeNoteTemplate && (
        <TemplatePreviewWithoutContent
          className="hidden md:block mt-6"
          sections={selectedScribeNoteTemplate?.sections}
        />
      )}
    </div>
  );
};

export default ScribeRecording;
