import Bugsnag from "@bugsnag/js";
import axios from "axios";
import { BASE_URL, setAuthToken } from "../../helpers/config";
import { Pages } from "../../helpers/constants";
import LocalStorageService from "../../services/LocalStorageService";
import { setRegisteredEmailAddress } from "../auth/actions";
import {
  setAssistantsAction,
  setDoctorOptionsAction,
  setErrorAction,
  setNotificationAction,
  setRoleOptionsAction,
  setRolesAction,
  setSignupInfoAction,
  setTeamsAction,
  setTeamsLoadingAction,
  setUserAction,
  setUsersAction,
  setUsersLoadingAction,
  setUsersWithoutTeamAction,
} from "./actions";

const storageService = new LocalStorageService();

export const getUser = async () => {
  const { data } = await axios.get(`${BASE_URL}/user/me`);

  const user = data.data;

  Bugsnag.setUser(user.doctor_id, user.email, user.display_name);
  Bugsnag.setContext(`org: ${user.customer.display_name}`);

  return user;
};

export const updateStoredUser = (user) => (dispatch) => {
  if (storageService.getItem("user")) {
    storageService.setItem("user", user);
  }
  dispatch(setUserAction(user));
};

export const setToken = (email, password, intercom) => (dispatch) => {
  axios
    .post(`${BASE_URL}/auth/login`, {
      email: email,
      password: password,
    })
    .then((response) => {
      const token = response.data.data.auth_token;
      if (token) {
        storageService.setItem("token", token);

        setAuthToken(token);

        getUser().then((user) => {
          storageService.setItem("user", user);

          dispatch(setUserAction(user));
          intercom.update({ email: email });
        });
      }
    })
    .catch((error) => {
      if (error.response.status !== 401) {
        console.error(error);
        Bugsnag.notify(error);
        dispatch(setErrorAction(error.response.data.error.message));
        return;
      }

      dispatch(setErrorAction("Failed to login"));
    });
};

export const setSettings =
  (settings, notification = true) =>
  (dispatch) => {
    axios
      .post(`${BASE_URL}/user/general`, settings)
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.error);
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to save settings",
            }),
          );
          Bugsnag.notify(response.data.error);
        } else {
          getUser().then((user) => dispatch(updateStoredUser(user)));
          if (notification) {
            dispatch(
              setNotificationAction({
                status: "success",
                title: "Settings successfully saved",
                desc: "Your general settings have been successfully saved",
              }),
            );
          }
        }
      })
      .catch((error) => {
        console.error(error);
        Bugsnag.notify(error);
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to save settings",
          }),
        );
      });
  };

export const updateUser =
  (doctorId, updatedFields, onUserUpdated, userSearchValue = "") =>
  (dispatch) => {
    axios
      .post(`${BASE_URL}/user/update/${doctorId}`, updatedFields)
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.error);
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to update user information",
            }),
          );
          Bugsnag.notify(response.data.error);
        } else {
          getUser().then((user) => {
            dispatch(updateStoredUser(user));
            dispatch(setDoctorOptionsAction(user?.doctors));
            dispatch(getUsers(userSearchValue));
            onUserUpdated();
          });
          dispatch(
            setNotificationAction({
              status: "success",
              title: "User information successfully updated",
              desc: "Information has been successfully updated",
            }),
          );
        }
      })
      .catch((error) => {
        console.error(error);
        Bugsnag.notify(error);
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to update user information",
          }),
        );
      });
  };

export const updatePassword = (oldPassword, newPassword) => (dispatch) => {
  axios
    .post(`${BASE_URL}/user/update-password`, {
      old_password: oldPassword,
      new_password: newPassword,
    })
    .then((response) => {
      if (response.data.error) {
        console.error(response.data.error);
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to update password",
          }),
        );
        Bugsnag.notify(response.data.error);
      } else {
        dispatch(
          setNotificationAction({
            status: "success",
            title: "Password successfully updated",
            desc: "Your password has been successfully updated",
          }),
        );
      }
    })
    .catch((error) => {
      console.error(error);
      Bugsnag.notify(error);
      dispatch(
        setNotificationAction({
          status: "error",
          title: "Something went wrong",
          desc: "Failed to update password",
        }),
      );
    });
};

export const createPassword = (resetToken, newPassword) => (dispatch) => {
  axios
    .post(`${BASE_URL}/user/create-password/${resetToken}`, {
      password: newPassword,
    })
    .then((response) => {
      if (response.data.error) {
        console.error(response.data.error);
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Password is not updated",
            desc: "Failed to update password",
          }),
        );
        Bugsnag.notify(response.data.error);
      } else {
        dispatch(
          setNotificationAction({
            status: "success",
            title: "Password successfully updated",
            desc: "Your password has been successfully updated",
          }),
        );
      }
    })
    .catch((error) => {
      console.error(error);
      Bugsnag.notify(error);
      dispatch(
        setNotificationAction({
          status: "error",
          title: "Something went wrong",
          desc: "Failed to update password",
        }),
      );
    });
};

export const forgotPassword = (email) => (dispatch) => {
  axios
    .post(`${BASE_URL}/user/forgot-password`, {
      email: email,
    })
    .then((response) => {
      if (response.data.error) {
        console.error(response.data.error);
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to reset password",
          }),
        );
        Bugsnag.notify(response.data.error);
      } else {
        dispatch(
          setNotificationAction({
            status: "success",
            title: "Password reset",
            desc: "Please check the email to reset the password",
          }),
        );
      }
    });
};

export const resetPassword = async (payload, newPassword) => {
  await axios.post(`${BASE_URL}/user/reset-password`, {
    payload: payload,
    password: newPassword,
  });
};

export const createUser =
  (providers, onEnd = () => {}, userSearchValue = "") =>
  (dispatch) => {
    // providers -> [{email: "email@mail.com", role_id: 1, ...}, {...}]
    axios
      .post(`${BASE_URL}/user/create`, { providers: providers })
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.error);
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to create user",
            }),
          );
          Bugsnag.notify(response.data.error);
        } else {
          dispatch(getUsers(userSearchValue));
          dispatch(
            setNotificationAction({
              status: "success",
              title: "User successfully created",
              desc: "Your invitation has been sent to entered email",
            }),
          );
        }
      })
      .catch((error) => {
        console.error(error);
        Bugsnag.notify(error);
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to create user",
          }),
        );
      })
      .finally(() => {
        dispatch(getUsers(userSearchValue));
        onEnd();
      });
  };

export const getUsers =
  (searchName = "") =>
  (dispatch) => {
    axios
      .get(`${BASE_URL}/user/list?search=${searchName}`)
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.error);
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to load users",
            }),
          );
          Bugsnag.notify(response.data.error);
        } else {
          const supportEmailPattern = /^support.*@quadrant\.health$/;

          const usersWithoutSupport = response.data.data.users.filter(
            (user) =>
              !(user.last_name || "").toLowerCase().startsWith("support") &&
              !supportEmailPattern.test(user.email),
          );
          dispatch(setUsersAction(usersWithoutSupport));
        }
      })
      .catch((error) => {
        console.error(error);
        Bugsnag.notify(error);
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to load users",
          }),
        );
      })
      .finally(() => {
        dispatch(setUsersLoadingAction(false));
      });
  };

export const getTeams =
  (searchName = "") =>
  (dispatch) => {
    axios
      .get(`${BASE_URL}/user/list?is_team=true&search=${searchName}`)
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.error);
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to load teams",
            }),
          );
          Bugsnag.notify(response.data.error);
        } else {
          dispatch(setTeamsAction(response.data.data.users));
        }
      })
      .catch((error) => {
        console.error(error);
        Bugsnag.notify(error);
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to load teams",
          }),
        );
      })
      .finally(() => {
        dispatch(setTeamsLoadingAction(false));
      });
  };

export const getUsersWithoutTeam = () => (dispatch) => {
  axios
    .get(`${BASE_URL}/user/list-without-team`)
    .then((response) => {
      if (response.data.error) {
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to load users",
          }),
        );
        Bugsnag.notify(response.data.error);
      } else {
        dispatch(
          setUsersWithoutTeamAction(
            response.data.data.length > 0 ? response.data.data : [],
          ),
        );
      }
    })
    .catch((error) => {
      console.error(error);
      Bugsnag.notify(error);
      dispatch(
        setNotificationAction({
          status: "error",
          title: "Something went wrong",
          desc: "Failed to load users",
        }),
      );
    });
};

export const deleteTeam =
  (doctorTeamId, teamSearchValue = "", notification = true) =>
  (dispatch) => {
    axios
      .post(`${BASE_URL}/user/delete-team/${doctorTeamId}`)
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.error);
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to delete team",
            }),
          );
          Bugsnag.notify(response.data.error);
        } else {
          dispatch(getTeams(teamSearchValue));
          if (notification) {
            dispatch(
              setNotificationAction({
                status: "success",
                title: "Team successfully deleted",
                desc: "Team has been successfully deleted",
              }),
            );
          }
        }
      })
      .catch((error) => {
        console.error(error);
        Bugsnag.notify(error);
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to delete team",
          }),
        );
      });
  };

export const getRoles = () => (dispatch) => {
  axios
    .get(`${BASE_URL}/user/roles`)
    .then((response) => {
      if (response.data.error) {
        console.error(response.data.error);
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to load roles",
          }),
        );
        Bugsnag.notify(response.data.error);
      } else {
        const roles = response.data.data;
        dispatch(setRolesAction(roles));

        const roleOptions = Object.keys(roles).map((key) => ({
          value: key,
          text: roles[key],
        }));
        dispatch(setRoleOptionsAction(roleOptions));
      }
    })
    .catch((error) => {
      console.error(error);
      Bugsnag.notify(error);
      dispatch(
        setNotificationAction({
          status: "error",
          title: "Something went wrong",
          desc: "Failed to load roles",
        }),
      );
    });
};

export const getAssistants = () => (dispatch) => {
  axios
    .get(`${BASE_URL}/user/list-shared`)
    .then((response) => {
      if (response.data.error) {
        console.error(response.data.error);
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to load assistants",
          }),
        );
        Bugsnag.notify(response.data.error);
      } else {
        dispatch(setAssistantsAction(response.data.data.doctors));
      }
    })
    .catch((error) => {
      console.error(error);
      Bugsnag.notify(error);
      dispatch(
        setNotificationAction({
          status: "error",
          title: "Something went wrong",
          desc: "Failed to load assistants",
        }),
      );
    });
};

export const createUserTeam =
  (
    leadId,
    teamName,
    teamSearchValue = "",
    showNotification = true,
    onSuccess = () => {},
  ) =>
  (dispatch) => {
    axios
      .post(`${BASE_URL}/user/create-team`, {
        name: teamName,
        clinical_lead_id: leadId,
      })
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.error);
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to create team",
            }),
          );
          Bugsnag.notify(response.data.error);
        } else {
          dispatch(getTeams(teamSearchValue));
          getUser().then((user) => dispatch(updateStoredUser(user)));
          onSuccess(response.data.data.doctor_team_id);
          if (showNotification) {
            dispatch(
              setNotificationAction({
                status: "success",
                title: "Team successfully created",
                desc: "Team has been successfully created",
              }),
            );
          }
        }
      })
      .catch((error) => {
        console.error(error);
        Bugsnag.notify(error);
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to create team",
          }),
        );
      });
  };

export const updateUserTeam =
  (
    doctorTeamId,
    newTeamName,
    showInbox,
    showNotification = true,
    onSuccess = () => {},
    teamSearchValue = "",
  ) =>
  (dispatch) => {
    axios
      .post(`${BASE_URL}/user/update-team/${doctorTeamId}`, {
        doctor_team: {
          name: newTeamName,
          show_inbox: showInbox, // 1 || 0
        },
      })
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.error);
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to update team",
            }),
          );
          Bugsnag.notify(response.data.error);
        } else {
          dispatch(getTeams(teamSearchValue));
          getUser().then((user) => dispatch(updateStoredUser(user)));
          onSuccess();
          if (showNotification) {
            dispatch(
              setNotificationAction({
                status: "success",
                title: "Team successfully updated",
                desc: "Team has been successfully updated",
              }),
            );
          }
        }
      })
      .catch((error) => {
        console.error(error);
        Bugsnag.notify(error);
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to update team",
          }),
        );
      });
  };

export const addAssistant =
  (doctorId, teamOwnerId, teamSearchValue = "") =>
  (dispatch) => {
    axios
      .post(`${BASE_URL}/user/share/${doctorId}/${teamOwnerId}`)
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.error);
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to add assistant",
            }),
          );
          Bugsnag.notify(response.data.error);
        } else {
          dispatch(getTeams(teamSearchValue));
          getUser().then((user) => dispatch(updateStoredUser(user)));
        }
      })
      .catch((error) => {
        console.error(error);
        Bugsnag.notify(error);
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to add assistant",
          }),
        );
      });
  };

export const removeAssistant =
  (doctorId, teamOwnerId, teamSearchValue = "") =>
  (dispatch) => {
    axios
      .post(`${BASE_URL}/user/remove-shared/${doctorId}/${teamOwnerId}`)
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.error);
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to remove assistant",
            }),
          );
          Bugsnag.notify(response.data.error);
        } else {
          dispatch(getTeams(teamSearchValue));
          getUser().then((user) => dispatch(updateStoredUser(user)));
        }
      })
      .catch((error) => {
        console.error(error);
        Bugsnag.notify(error);
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to remove assistant",
          }),
        );
      });
  };

export const getUserInfo = (token) => (dispatch) => {
  axios
    .get(`${BASE_URL}/user/info/${token}`)
    .then((response) => {
      if (response.data.error) {
        console.error(response.data.error);
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to load user info",
          }),
        );
        Bugsnag.notify(response.data.error);
      } else {
        dispatch(setSignupInfoAction(response.data.data));
      }
    })
    .catch((error) => {
      console.error(error);
      Bugsnag.notify(error);
      dispatch(
        setNotificationAction({
          status: "error",
          title: "Something went wrong",
          desc: "Failed to load user info",
        }),
      );
    });
};

export const registerUser =
  (
    token,
    password,
    firstName,
    lastName,
    displayName,
    phoneNumber,
    occupation,
    profilePicture,
    navigate,
  ) =>
  (dispatch) => {
    axios
      .post(`${BASE_URL}/user/complete-sign-up/${token}`, {
        password: password,
        first_name: firstName,
        last_name: lastName,
        display_name: displayName,
        phone_number: phoneNumber,
        occupation: occupation,
        profile_picture: profilePicture,
      })
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.error);
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to register user",
            }),
          );
          Bugsnag.notify(response.data.error);
        } else {
          navigate(Pages.LOGIN);
        }
      })
      .catch((error) => {
        console.error(error);
        Bugsnag.notify(error);
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to register user",
          }),
        );
      });
  };

export const registerUserV2 =
  (providerEmail, providerPassword) => (dispatch) => {
    axios
      .post(`${BASE_URL}/customer/sign-up/v2`, {
        providerEmail,
        providerPassword,
      })
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.error);
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to register user",
            }),
          );
          Bugsnag.notify(response.data.error);
        } else {
          dispatch(setRegisteredEmailAddress(providerEmail));
        }
      })
      .catch((error) => {
        console.error(error);
        Bugsnag.notify(error);
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to register user",
          }),
        );
      });
  };

export const deleteUser =
  (doctorId, onError = null, userSearchValue = "") =>
  (dispatch) => {
    axios
      .post(`${BASE_URL}/user/delete/${doctorId}`)
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.error);
          if (onError) {
            onError(response.data.error.message);
          } else {
            dispatch(
              setNotificationAction({
                status: "error",
                title: "Something went wrong",
                desc: "Failed to delete user",
              }),
            );
          }
          Bugsnag.notify(response.data.error);
        } else {
          dispatch(getUsers(userSearchValue));
          dispatch(
            setNotificationAction({
              status: "success",
              title: "User successfully deleted",
              desc: "User has been successfully deleted",
            }),
          );
        }
      })
      .catch((error) => {
        console.error(error);
        Bugsnag.notify(error);
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to delete user",
          }),
        );
      });
  };

export const resendVerificationEmail = (userId) => (dispatch) => {
  axios
    .post(`${BASE_URL}/user/resend-verification-email/${userId}`)
    .then((response) => {
      if (response.data.error) {
        console.error(response.data.error);
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to resend verification email",
          }),
        );
        Bugsnag.notify(response.data.error);
      } else {
        dispatch(
          setNotificationAction({
            status: "success",
            title: "Verification email sent",
            desc: "Verification email has been successfully sent",
          }),
        );
      }
    })
    .catch((error) => {
      console.error(error);
      Bugsnag.notify(error);
      dispatch(
        setNotificationAction({
          status: "error",
          title: "Something went wrong",
          desc: "Failed to resend verification email",
        }),
      );
    });
};

export const updateOrganization =
  (name, displayName, legacyPhoneNumber, address, onSuccess = () => {}) =>
  (dispatch) => {
    axios
      .post(`${BASE_URL}/customer/update`, {
        name: name,
        display_name: displayName,
        legacy_phone_number: legacyPhoneNumber,
        clinic_address: address,
      })
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.error);
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to update organization",
            }),
          );
          Bugsnag.notify(response.data.error);
        } else {
          getUser().then((user) => dispatch(updateStoredUser(user)));
          dispatch(
            setNotificationAction({
              status: "success",
              title: "Organization successfully updated",
              desc: "Your organization information has been successfully updated",
            }),
          );
          onSuccess();
        }
      })
      .catch((error) => {
        console.error(error);
        Bugsnag.notify(error);
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to update organization",
          }),
        );
      });
  };
