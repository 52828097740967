import Skeleton from "@mui/material/Skeleton";

const ScribeNoteSkeleton = ({
  paragraphs = 8,
  bgColor = "#EAEDF3",
  animation = "wave",
  className = "",
}) => {
  return (
    <div className={`flex flex-col space-y-5 w-full ${className}`}>
      {[...Array(paragraphs)].map((_, index) => (
        <div key={`paragraph-${index}`} className="space-y-2">
          <Skeleton
            animation={animation}
            variant="rounded"
            width={136}
            height={12}
            sx={{ bgcolor: bgColor, borderRadius: "12px" }}
          />
          <Skeleton
            animation={animation}
            variant="rounded"
            height={12}
            sx={{ bgcolor: bgColor, borderRadius: "12px" }}
          />
          <Skeleton
            animation={animation}
            variant="rounded"
            height={12}
            sx={{ bgcolor: bgColor, borderRadius: "12px" }}
          />
        </div>
      ))}
    </div>
  );
};

export default ScribeNoteSkeleton;
