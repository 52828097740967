import { convert } from "html-to-text";
import LocalStorageService from "../services/LocalStorageService";

export const isSameDay = (date1, date2) => {
  const firstDate = new Date(`${date1.replace(/-/g, "/")} GMT+0`);
  const secondDate = new Date(`${date2.replace(/-/g, "/")} GMT+0`);

  firstDate.setHours(0, 0, 0, 0);
  secondDate.setHours(0, 0, 0, 0);

  return firstDate.getTime() === secondDate.getTime();
};

export const isToday = (date) => {
  const currDate = new Date();
  const checkDate = new Date(date);

  currDate.setHours(0, 0, 0, 0);
  checkDate.setHours(0, 0, 0, 0);

  return currDate.getTime() === checkDate.getTime();
};

export const isYesterday = (date) => {
  const yesterdayDate = new Date();
  const checkDate = new Date(date);
  yesterdayDate.setDate(yesterdayDate.getDate() - 1);
  yesterdayDate.setHours(0, 0, 0, 0);
  checkDate.setHours(0, 0, 0, 0);

  return yesterdayDate.getTime() === checkDate.getTime();
};

export const isWeek = (date) => {
  const weekAgoDate = new Date();
  const checkDate = new Date(date);
  weekAgoDate.setDate(weekAgoDate.getDate() - 6);
  weekAgoDate.setHours(0, 0, 0, 0);
  checkDate.setHours(0, 0, 0, 0);

  return checkDate.getTime() > weekAgoDate.getTime();
};

export const isThisYear = (date) => {
  const currDate = new Date();
  const firstDayOfYear = new Date(currDate.getFullYear(), 0, 1);
  const checkDate = new Date(date);
  firstDayOfYear.setHours(0, 0, 0, 0);
  checkDate.setHours(0, 0, 0, 0);

  return checkDate.getTime() > firstDayOfYear.getTime();
};

export const getAge = (birthdate) => {
  const dob = new Date(`${birthdate.replace(/-/g, "/")} GMT+0`);
  const currDate = new Date();
  const oneOrZero =
    currDate.getMonth() < dob.getMonth() ||
    (currDate.getMonth() === dob.getMonth() &&
      currDate.getDate() < dob.getDate());
  const yearDifference = currDate.getFullYear() - dob.getFullYear();
  return yearDifference - oneOrZero;
};

export const getFormattedTime = (date) => {
  return date.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "2-digit",
  });
};

export const getFormattedDateWithDayOfWeek = (date) => {
  return date.toLocaleDateString("en-US", {
    weekday: "long",
    month: "2-digit",
    day: "2-digit",
    year: "2-digit",
  });
};

export const getFormattedTimeOrDate = (date) => {
  if (isToday(date)) {
    return date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
    });
  }
  if (isThisYear(date)) {
    return date.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
    });
  }
  return date.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "2-digit",
  });
};

export const getFormattedTimeWithSeconds = (duration) => {
  const hrs = Math.floor(duration / 3600);
  const mins = Math.floor((duration % 3600) / 60);
  const secs = Math.floor(duration % 60);

  let time = "";

  if (hrs > 0) {
    time += `${hrs < 10 ? `0${hrs}` : hrs}:`;
  }

  time += `${mins < 10 ? `0${mins}` : mins}:`;
  time += `${secs < 10 ? `0${secs}` : secs}`;

  return time;
};

export const getFormattedTimeColon = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  if (hours > 0) {
    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }
  const formattedMinutes = String(minutes);
  const formattedSeconds = String(remainingSeconds).padStart(2, "0");
  return `${formattedMinutes}:${formattedSeconds}`;
};

export const getFormattedTimeHMS = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  return `${hours > 0 ? `${hours}h ` : ""}${minutes > 0 || hours > 0 ? `${minutes}min ` : ""}${remainingSeconds}s`;
};

export const timeStringToSeconds = (timeString) => {
  const parts = timeString.split(/\s+|:/);

  let hours = 0;
  let minutes = 0;
  let seconds = 0;

  for (const part of parts) {
    if (part.endsWith("h")) {
      hours = Number.parseInt(part, 10);
    } else if (part.endsWith("m")) {
      minutes = Number.parseInt(part, 10);
    } else if (part.endsWith("s")) {
      seconds = Number.parseInt(part, 10);
    }
  }
  return hours * 3600 + minutes * 60 + seconds;
};

export const getTimeAndUnit = (minutes) => {
  const mins = Math.abs(minutes);
  let hours = 0;
  let days = 0;
  if (mins >= 60) {
    hours = mins / 60;
  }
  if (hours >= 24) {
    days = hours / 24;
  }
  if (days >= 1) {
    return { value: Math.round(days), unit: "days" };
  }
  if (hours >= 1) {
    return { value: Math.round(hours), unit: "hours" };
  }
  return { value: mins, unit: "minutes" };
};

export const getDateDivider = (date) => {
  if (isToday(date)) {
    return "Today";
  }
  if (isYesterday(date)) {
    return "Yesterday";
  }
  if (isWeek(date)) {
    return date.toLocaleDateString("en-US", { weekday: "long" });
  }
  return date.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
};

export const addHours = (date, hours) => {
  const dateCopy = new Date(date);
  dateCopy.setTime(dateCopy.getTime() + hours * 60 * 60 * 1000);
  return dateCopy;
};

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value);
};

export const onDragEnd = (result, values, setValues) => {
  const { destination, source } = result;

  if (!destination) {
    return;
  }

  if (
    destination.droppableId === source.droppableId &&
    destination.index === source.index
  ) {
    return;
  }

  const newOrder = [...values];
  const [removed] = newOrder.splice(result.source.index, 1);
  newOrder.splice(result.destination.index, 0, removed);
  setValues(newOrder);
};

export const getFormattedPhoneNumber = (number) => {
  const cleanNumber = number.trim().replaceAll(/[^\d]/g, "");
  return cleanNumber.length > 11 ? (
    <p>{cleanNumber.replace(/(\d{2})(\d{3})(\d{3})(\d)/, "+$1 $2-$3-$4")}</p>
  ) : (
    <p>{cleanNumber.replace(/(\d)(\d{3})(\d{3})(\d)/, "+$1 $2-$3-$4")}</p>
  );
};

export const getTagVariant = (tagName) => {
  switch (tagName) {
    // case "HPI":
    //   return "slate";
    // case "CCM":
    //   return "violet";
    // case "PCM":
    //   return "red";
    // case "Registration":
    //   return "red";
    // case "BHI":
    //   return "amber";
    // case "CC":
    //   return "emerald";
    // case "Patient Initiated":
    //   return "sky";
    default:
      return "slate";
  }
};

export const getEventDotBgColor = (tagName) => {
  switch (tagName) {
    case "HPI":
      return "bg-slate-700";
    case "CCM":
      return "bg-violet-700";
    case "PCM":
      return "bg-red-700";
    case "Registration":
      return "bg-red-700";
    case "BHI":
      return "bg-amber-700";
    case "CC":
      return "bg-emerald-700";
    case "Patient Initiated":
      return "bg-sky-700";
    default:
      return "bg-slate-700";
  }
};

export const selectInputText = (e) => {
  e.target.select();
};

export const removeTags = (textWithHtmlTags) => {
  const hasBR = /<br\s*\/?>/i.test(textWithHtmlTags);
  return !hasBR
    ? textWithHtmlTags
    : convert(
        textWithHtmlTags
          .replace(
            /<\/[a-zA-Z]+>\s*<br\/>/g,
            (match) => `</${match.match(/[a-zA-Z]+/)[0]}>`,
          )
          .replace(/<br><\/[a-zA-Z]+>/g, "</$1>"),
        {
          wordwrap: false,
        },
      );
};

export const copyText = (textWithHtmlTags, onSuccess) => {
  const text = removeTags(textWithHtmlTags);
  navigator.clipboard.writeText(text).then(() => onSuccess());
};

export const convertToHtmlList = (text) => {
  const lines = text.split("\n");

  let html = "";
  let inList = false;

  for (const line of lines) {
    if (line.trim().startsWith("- ")) {
      if (!inList) {
        html += "<ul style='list-style-type: disc; padding-left: 18px'>";
        inList = true;
      }
      html += `<li>${line.trim().substring(1).trim()}</li>`;
    } else {
      if (inList) {
        html += "</ul>";
        inList = false;
      }
      html += `<p>${line}</p>`;
    }
  }
  if (inList) {
    html += "</ul>";
  }
  return html;
};

export const deepEqual = (obj1, obj2) => {
  if (typeof obj1 !== typeof obj2) {
    return false;
  }
  if (Array.isArray(obj1)) {
    if (obj1.length !== obj2.length) {
      return false;
    }
    const sortedObj1 = obj1.slice().sort();
    const sortedObj2 = obj2.slice().sort();
    return sortedObj1.every((value, index) =>
      deepEqual(value, sortedObj2[index]),
    );
  }
  if (typeof obj1 === "object" && obj1 !== null) {
    const keys1 = Object.keys(obj1).sort();
    const keys2 = Object.keys(obj2).sort();
    if (!deepEqual(keys1, keys2)) {
      return false;
    }
    return keys1.every((key) => deepEqual(obj1[key], obj2[key]));
  }
  return obj1 === obj2;
};

export const getFrequencyByInterval = (interval) => {
  switch (interval) {
    case 0:
      return "once";
    case 1440:
      return "daily";
    case 4320:
      return "every_3_days";
    case 10080:
      return "weekly";
    case 21600:
      return "biweekly";
    case 43200:
      return "monthly";
    case 86400:
      return "every_2_months";
    case 129600:
      return "every_3_months";
    case 259200:
      return "every_6_months";
    case 525600:
      return "yearly";
    default:
      return "unknown";
  }
};

export const insertTag = (selection, tag) => {
  if (
    selection.focusNode.parentNode.getAttribute("contenteditable") === "true"
  ) {
    const range = selection.getRangeAt(0);

    const span = document.createElement("span");
    span.className = "py-0.5 px-1.5 border border-gray-300 rounded-md text-xs";
    span.textContent = tag.name;
    span.contentEditable = "false";

    range.deleteContents();
    range.insertNode(span);

    const newRange = document.createRange();
    newRange.setStartAfter(span);
    newRange.setEndAfter(span);

    selection.removeAllRanges();
    selection.addRange(newRange);
  }
};

export const getDurationString = (durationInSeconds) => {
  const hours = Math.floor(durationInSeconds / 3600);
  const minutes = Math.floor((durationInSeconds % 3600) / 60);
  const seconds = durationInSeconds % 60;

  if (hours > 0) {
    if (minutes > 0) {
      return `${hours} hr ${minutes} min`;
    }
    return `${hours} hr`;
  }

  if (minutes > 0) {
    if (seconds > 0) {
      return `${minutes} min ${seconds} sec`;
    }
    return `${minutes} min`;
  }

  return `${seconds} sec`;
};

function isValidDateFormat(dateString) {
  const regex1 = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}(\.\d{1,3})?$/;
  const regex2 = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,3})?Z$/;
  return regex1.test(dateString) || regex2.test(dateString);
}

export const parseDateString = (dateString) => {
  if (!isValidDateFormat(dateString)) {
    throw new Error(
      `Invalid date format. Expected formats: "YYYY-MM-DD HH:mm:ss" or "YYYY-MM-DDTHH:mm:ssZ" but received: ${dateString}`,
    );
  }

  if (dateString.includes(" ")) {
    return new Date(`${dateString.replace(" ", "T")}Z`);
  }

  return new Date(dateString);
};

export const clearStorage = () => {
  const storageService = new LocalStorageService();
  storageService.removeItem("failedMessages");
  storageService.removeItem("closedResponses");
  storageService.removeItem("collapsePatientList");
  storageService.removeItem("selectedPatientNotes");
  storageService.removeItem("onlyScribeFeature");
  storageService.removeItem("trackingDateStamp");
  storageService.removeItem("trackingBillingType");
  storageService.removeItem("trackingCptCode");
  storageService.removeItem("trackingSortedBy");
  storageService.removeItem("sortingDirection");
  storageService.removeItem("billingSearchValue");
  storageService.removeItem("selectedEnrollCampaign");
  storageService.removeItem("selectedCampaign");
  storageService.removeItem("selectedCampaignIds");
  storageService.removeItem("campaignTab");
  storageService.removeItem("calendarView");
  storageService.removeItem("inboxFilters");
  storageService.removeItem("conversationSearchInput");
  storageService.removeItem("currentConversation");
  storageService.removeItem("currentConversationId");
  storageService.removeItem("inboxTab");
  storageService.removeItem("insightPanelTab");
  storageService.removeItem("selectedDoctorInbox");
  storageService.removeItem("selectedConversationTab");
  storageService.removeItem("patientSearchInput");
  storageService.removeItem("patientProfileTab");
  storageService.removeItem("selectedDoctorPatients");
  storageService.removeItem("selectedPatientPanelTab");
  storageService.removeItem("selectedPatientInfo");
  storageService.removeItem("selectedPatientProfileTab");
  storageService.removeItem("selectedPatientCampaigns");
  storageService.removeItem("selectedPatientStatuses");
  storageService.removeItem("selectedOrganizationTab");
  storageService.removeItem("selectedNotesTab");
};

export const handleUnauthorized = () => {
  const storageService = new LocalStorageService();
  storageService.clear();
  window.location.reload();
};
