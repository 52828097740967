import { isEqual } from "lodash";
import LocalStorageService from "../../../services/LocalStorageService";
import { initialTitle } from "../ScribeHeaderTitle";
import { NOTE_NOT_SELECTED, NOTE_RECORDING_ID } from "../consts";
import {
  LOGOUT,
  SET_DEFAULT_NOTE_TEMPLATE,
  SET_INTERRUPTED_RECORDING_ACTION,
  SET_IS_CURRENTLY_RECORDING,
  SET_NOTE_TEMPLATES,
  SET_PROVIDER_NOTE,
  SET_PROVIDER_NOTES,
  SET_PROVIDER_NOTES_LOADING,
  SET_PROVIDER_NOTES_RECORDING,
  SET_PROVIDER_NOTES_RECORDING_CANCELLED,
  SET_PROVIDER_NOTES_TOGGLE_PAUSE,
  SET_PROVIDER_NOTE_CONTENT,
  SET_PROVIDER_NOTE_DELETED,
  SET_SELECTED_NOTE_TEMPLATE,
  SET_SELECTED_SCRIBE_NOTE_TEMPLATE,
  SET_SHOW_SCRIBE_NOTE_TEMPLATE,
} from "./types";

const storageService = new LocalStorageService();

const init = {
  providerNotes: storageService.getItem("providerNotes") ?? [],
  providerNotesLoading: true,
  noteTemplates: [],
  defaultNoteTemplate: null,
  selectedNoteTemplate: null,
  showScribeNoteTemplate: false,
  selectedScribeNoteTemplate: null,
  interruptedRecordingAction: null,
};

const getRecordingNote = (overrides = {}) => ({
  note_id: NOTE_RECORDING_ID,
  audio_id: NOTE_NOT_SELECTED,
  title: initialTitle,
  patient: null,
  patient_id: null,
  started_at: new Date().toISOString(),
  finished_at: new Date().toISOString(),
  accumulated_duration: 0,
  isEditing: false,
  isRecording: false,
  isGenerating: false,
  isVisible: false,
  free_text: "",
  note: "",
  ...overrides,
});

const updateRecordingNote = (notes, recordingNoteOverride = {}) => {
  let recordingNoteFound = false;
  const updatedNotes = notes.map((note) => {
    if (note.note_id === NOTE_RECORDING_ID) {
      recordingNoteFound = true;

      return {
        ...note,
        ...recordingNoteOverride,
      };
    }
    return note;
  });

  if (!recordingNoteFound) {
    updatedNotes.unshift(getRecordingNote(recordingNoteOverride));
  }

  return updatedNotes;
};

export default function scribeReducer(state = init, action) {
  const { type, payload } = action;

  switch (type) {
    case LOGOUT:
      storageService.removeItem("providerNotes");
      return {
        ...init,
      };

    case SET_PROVIDER_NOTES_RECORDING:
      return {
        ...state,
        // TODO: It should update only the recording note
        providerNotes: updateRecordingNote(state.providerNotes, {
          started_at: new Date().toISOString(),
          finished_at: null,
          isRecording: true,
          isVisible: true,
        }),
      };

    case SET_PROVIDER_NOTES_TOGGLE_PAUSE: {
      const recordingNote = state.providerNotes.find(
        (note) => note.note_id === NOTE_RECORDING_ID,
      );

      if (recordingNote.started_at === null) {
        return {
          ...state,
          providerNotes: updateRecordingNote(state.providerNotes, {
            started_at: new Date().toISOString(),
          }),
        };
      }

      return {
        ...state,
        providerNotes: updateRecordingNote(state.providerNotes, {
          started_at: null,
          isGenerating: false,
          accumulated_duration: Math.floor(
            (new Date() - new Date(recordingNote.started_at)) / 1000 +
              recordingNote.accumulated_duration,
          ),
        }),
      };
    }

    case SET_PROVIDER_NOTES_RECORDING_CANCELLED:
      return {
        ...state,
        providerNotes: updateRecordingNote(
          state.providerNotes,
          getRecordingNote({
            isVisible: payload?.isVisible || false,
          }),
        ),
      };

    case SET_PROVIDER_NOTES: {
      let recordingNoteFoundSet = false;
      const currentEditingNotes = state.providerNotes.filter(
        (note) => note.isEditing,
      );

      const updatedNotesForSet = payload.notes.map((note) => {
        const currentEditingNote = currentEditingNotes.find(
          (editingNote) => editingNote.note_id === note.note_id,
        );

        if (currentEditingNote && note.note_id === currentEditingNote.note_id) {
          return currentEditingNote;
        }

        const existingNote = state.providerNotes.find(
          (existingNote) => existingNote.note_id === note.note_id,
        );

        if (existingNote) {
          return existingNote;
        }

        return note;
      });

      if (state.providerNotes[0]?.note_id === NOTE_RECORDING_ID) {
        recordingNoteFoundSet = true;
        updatedNotesForSet.unshift(state.providerNotes[0]);
      }

      if (!recordingNoteFoundSet) {
        updatedNotesForSet.unshift(getRecordingNote());
      }

      if (isEqual(state.providerNotes, updatedNotesForSet)) {
        return state;
      }

      storageService.setItem(
        "providerNotes",
        updatedNotesForSet.filter((note) => note.note_id !== NOTE_RECORDING_ID),
      );

      return {
        ...state,
        providerNotes: updatedNotesForSet,
      };
    }

    case SET_PROVIDER_NOTE: {
      const updatedNotesForNote = state.providerNotes.map((note) => {
        if (note.note_id === payload.noteId) {
          const newNote = {
            ...note,
            ...payload.note,
          };

          if (newNote.isEditing === false) {
            delete newNote.isEditing;
            delete newNote.originalTitle;
          }

          return newNote;
        }

        return note;
      });

      storageService.setItem(
        "providerNotes",
        updatedNotesForNote.filter(
          (note) => note.note_id !== NOTE_RECORDING_ID,
        ),
      );

      return {
        ...state,
        providerNotes: updatedNotesForNote,
      };
    }

    case SET_PROVIDER_NOTES_LOADING:
      return {
        ...state,
        providerNotesLoading: payload,
      };

    case SET_PROVIDER_NOTE_DELETED: {
      const updatedNotesForDeleted = state.providerNotes.filter(
        (note) => note.note_id !== payload,
      );

      storageService.setItem(
        "providerNotes",
        updatedNotesForDeleted.filter(
          (note) => note.note_id !== NOTE_RECORDING_ID,
        ),
      );

      return {
        ...state,
        providerNotes: updatedNotesForDeleted,
      };
    }

    case SET_PROVIDER_NOTE_CONTENT: {
      const updatedNotesForContent = state.providerNotes.map((note) => {
        if (note.note_id === payload.noteId) {
          return {
            ...note,
            note: payload.noteContent,
          };
        }

        return note;
      });

      storageService.setItem(
        "providerNotes",
        updatedNotesForContent.filter(
          (note) => note.note_id !== NOTE_RECORDING_ID,
        ),
      );

      return {
        ...state,
        providerNotes: updatedNotesForContent,
      };
    }

    case SET_NOTE_TEMPLATES:
      return { ...state, noteTemplates: payload.noteTemplates };

    case SET_DEFAULT_NOTE_TEMPLATE:
      return { ...state, defaultNoteTemplate: payload.defaultNoteTemplate };

    case SET_SELECTED_NOTE_TEMPLATE:
      return { ...state, selectedNoteTemplate: payload.selectedNoteTemplate };

    case SET_SHOW_SCRIBE_NOTE_TEMPLATE:
      return {
        ...state,
        showScribeNoteTemplate: payload.showScribeNoteTemplate,
      };

    case SET_SELECTED_SCRIBE_NOTE_TEMPLATE:
      return {
        ...state,
        selectedScribeNoteTemplate: payload.selectedScribeNoteTemplate,
      };

    case SET_INTERRUPTED_RECORDING_ACTION:
      return {
        ...state,
        interruptedRecordingAction: payload.interruptedRecordingAction,
      };

    case SET_IS_CURRENTLY_RECORDING:
      return {
        ...state,
        isCurrentlyRecording: payload.isCurrentlyRecording,
      };

    default:
      return state;
  }
}
