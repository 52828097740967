import {
  SET_CURR_PAGE,
  SET_LAST_SELECTED_PATIENT_PROFILE,
  SET_NUM_FILTERED_PATIENTS,
  SET_NUM_PATIENTS,
  SET_NUM_PATIENTS_BY_DOCTOR,
  SET_OPEN_EDIT_INFO_MODAL,
  SET_PATIENT_EXISTS_ERROR,
  SET_PATIENT_LOADER,
  SET_PATIENTS,
  SET_PROFILE_CONVERSATION,
  SET_PROFILE_LOADING,
  SET_SEARCH,
  SET_SELECTED_DOCTOR_PATIENTS,
  SET_SELECTED_PATIENT_ENROLLMENTS,
  SET_SELECTED_PATIENT_INFO,
  SET_SELECTED_PATIENT_NOTE,
  SET_ENCOUNTER_TITLE,
  SET_SELECTED_PATIENT_NOTES,
  SET_SELECTED_PATIENT_PANEL_TAB,
  SET_SELECTED_PATIENT_PROFILE_TAB,
  SET_SELECTED_PATIENTS,
  SET_TABLE_SCROLL_POSITION,
  SET_SELECTED_PATIENT_CAMPAIGNS,
  SET_SELECTED_PATIENT_STATUSES,
  SET_DELETED_PATIENT_NOTE,
  SET_SELECTED_PATIENT_INSURANCES,
  SET_PATIENT_RECORDING_SCRIBE_TITLE,
} from "../types";

export function setPatientsAction(patients) {
  return {
    type: SET_PATIENTS,
    payload: {
      patients: patients,
    },
  };
}

export function setSelectedPatientsAction(patients) {
  return {
    type: SET_SELECTED_PATIENTS,
    payload: {
      patients: patients,
    },
  };
}

export function setSelectedPatientInfoAction(patientInfo) {
  return {
    type: SET_SELECTED_PATIENT_INFO,
    payload: {
      patientInfo: patientInfo,
    },
  };
}
export function saveEncounterStateTitle(noteId, name) {
  return {
    type: SET_ENCOUNTER_TITLE,
    payload: {
      noteId,
      name,
    },
  };
}

export function setSelectedPatientNotesAction(patientNotes) {
  return {
    type: SET_SELECTED_PATIENT_NOTES,
    payload: {
      patientNotes: patientNotes,
    },
  };
}

export function setSelectedPatientNoteAction(patientNote) {
  return {
    type: SET_SELECTED_PATIENT_NOTE,
    payload: {
      patientNote: patientNote,
    },
  };
}
export function setSelectedPatientEnrollmentsAction(patientEnrollments) {
  return {
    type: SET_SELECTED_PATIENT_ENROLLMENTS,
    payload: {
      patientEnrollments: patientEnrollments,
    },
  };
}

export function setLastSelectedPatientProfileAction(patient) {
  return {
    type: SET_LAST_SELECTED_PATIENT_PROFILE,
    payload: {
      patient: patient,
    },
  };
}

export function setProfileLoadingAction(profileLoading) {
  return {
    type: SET_PROFILE_LOADING,
    payload: {
      profileLoading: profileLoading,
    },
  };
}

export function setCurrPageAction(currPage) {
  return {
    type: SET_CURR_PAGE,
    payload: {
      currPage: currPage,
    },
  };
}

export function setSearchAction(searchValue) {
  return {
    type: SET_SEARCH,
    payload: {
      searchValue: searchValue,
    },
  };
}

export function setNumOfPatientsAction(numOfPatients) {
  return {
    type: SET_NUM_PATIENTS,
    payload: {
      numOfPatients: numOfPatients,
    },
  };
}

export function setNumOfPatientsByDoctorIdAction(numOfPatientsByDoctorId) {
  return {
    type: SET_NUM_PATIENTS_BY_DOCTOR,
    payload: {
      numOfPatientsByDoctorId: numOfPatientsByDoctorId,
    },
  };
}

export function setNumOfFilteredPatientsAction(numOfFilteredPatients) {
  return {
    type: SET_NUM_FILTERED_PATIENTS,
    payload: {
      numOfFilteredPatients: numOfFilteredPatients,
    },
  };
}

export function setPatientLoaderAction(loader) {
  return {
    type: SET_PATIENT_LOADER,
    payload: {
      loader: loader,
    },
  };
}

export function setTableScrollPositionAction(tableScrollPosition) {
  return {
    type: SET_TABLE_SCROLL_POSITION,
    payload: {
      tableScrollPosition: tableScrollPosition,
    },
  };
}

export function setPatientExistsErrorAction(patientExistsError) {
  return {
    type: SET_PATIENT_EXISTS_ERROR,
    payload: {
      patientExistsError: patientExistsError,
    },
  };
}

export function setSelectedDoctorPatientsAction(selectedDoctorPatients) {
  return {
    type: SET_SELECTED_DOCTOR_PATIENTS,
    payload: {
      selectedDoctorPatients: selectedDoctorPatients,
    },
  };
}

export function setSelectedPatientPanelTabAction(selectedPatientPanelTab) {
  return {
    type: SET_SELECTED_PATIENT_PANEL_TAB,
    payload: {
      selectedPatientPanelTab: selectedPatientPanelTab,
    },
  };
}

export function setSelectedPatientProfileTabAction(selectedPatientProfileTab) {
  return {
    type: SET_SELECTED_PATIENT_PROFILE_TAB,
    payload: {
      selectedPatientProfileTab: selectedPatientProfileTab,
    },
  };
}

export function setProfileConversationAction(profileConversation) {
  return {
    type: SET_PROFILE_CONVERSATION,
    payload: {
      profileConversation: profileConversation,
    },
  };
}

export function setOpenEditInfoModalAction(openEditInfoModal) {
  return {
    type: SET_OPEN_EDIT_INFO_MODAL,
    payload: {
      openEditInfoModal: openEditInfoModal,
    },
  };
}

export function setSelectedPatientCampaignsAction(selectedPatientCampaigns) {
  return {
    type: SET_SELECTED_PATIENT_CAMPAIGNS,
    payload: {
      selectedPatientCampaigns: selectedPatientCampaigns,
    },
  };
}

export function setSelectedPatientStatusesAction(selectedPatientStatuses) {
  return {
    type: SET_SELECTED_PATIENT_STATUSES,
    payload: {
      selectedPatientStatuses: selectedPatientStatuses,
    },
  };
}

export function setSelectedPatientInsurancesAction(selectedPatientInsurances) {
  return {
    type: SET_SELECTED_PATIENT_INSURANCES,
    payload: {
      selectedPatientInsurances: selectedPatientInsurances,
    },
  };
}

export function setDeletedPatientNoteAction(payload) {
  return {
    type: SET_DELETED_PATIENT_NOTE,
    payload: payload,
  };
}

export function setPatientRecordingScribeTitleAction(
  patientRecordingScribeTitle,
) {
  return {
    type: SET_PATIENT_RECORDING_SCRIBE_TITLE,
    payload: {
      patientRecordingScribeTitle: patientRecordingScribeTitle,
    },
  };
}
