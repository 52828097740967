import MicrophoneLiveVisualizer from "../../Basic/MicrophoneLiveVisualizer";
import ScribeRecordingFooterButtons from "../ScribeRecordingFooterButtons";
import RecordingIndicator from "../RecordingIndicator";
import TimerDisplay from "../TimerDisplay";

const ScribeRecordingFooter = ({
  recorderState,
  mediaRecorder,
  cancelRecording,
  saveRecording,
  isSaving,
  togglePause,
}) => {
  const isPaused = mediaRecorder.state === "paused";
  const isRecording = mediaRecorder.state === "recording";
  const hasError = recorderState.hasError;

  return (
    <footer
      className="fixed bottom-6 flex flex-col md:grid md:grid-cols-conv-layout md:items-center md:justify-between
        w-[calc(100vw-32px)] md:w-[calc(100vw-72px-300px-48px)]"
    >
      <div className="flex flex-col md:grid md:grid-cols-3-left w-full items-center">
        <div className="overflow-x-hidden w-full mb-12 md:mb-0">
          <MicrophoneLiveVisualizer mediaRecorder={mediaRecorder} />
        </div>

        <TimerDisplay
          isRecording={isRecording}
          startedAt={recorderState.startedAt}
          offset={recorderState.accumulatedSeconds}
          className="text-2xl md:text-base"
        />

        <div className="hidden md:block">
          <RecordingIndicator isRecording={isRecording} />
        </div>
        <div className="flex items-center md:hidden bg-[#FFEBEE] rounded-full px-3 py-1 gap-1">
          <RecordingIndicator isRecording={isRecording} />
          <p>{isPaused ? "Paused" : "Recording"}</p>
        </div>
      </div>

      <ScribeRecordingFooterButtons
        hasError={hasError}
        isSaving={isSaving}
        isPaused={isPaused}
        togglePause={togglePause}
        cancelRecording={cancelRecording}
        saveRecording={saveRecording}
      />
    </footer>
  );
};

export default ScribeRecordingFooter;
