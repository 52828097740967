const TabButtonRound = ({ label, onClick, active, error, className }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={`flex items-center justify-center w-fit text-sm leading-none font-semibold rounded-full px-5 py-2 border bg-white
        ${active === label ? "text-primary-blue border-primary-blue" : "text-primary border-gray-foreground"}
        ${error ? "border-red-400" : ""} ${className}`}
    >
      {label}
    </button>
  );
};

export default TabButtonRound;
