import { flags } from "../../helpers/constants";
import { ReactComponent as FlagIcon } from "../../assets/icons/flag.svg";
import userIconSrc from "../../assets/icons/user-default.svg";

const ProfilePicture = ({
  src,
  firstName,
  lastName,
  size = 8,
  fontSize = "xs",
  textColor = "primary-blue",
  bgColor = "gray-200",
  flag,
  flagPadding = true,
  className,
  ...props
}) => {
  const getFlagBgColor = (flag) => {
    return flags.find((f) => f.value === flag).color;
  };

  return (
    <div className={`relative w-fit h-fit ${flagPadding ? "pl-1" : ""}`}>
      <div
        className={`w-${size} h-${size} rounded-full overflow-hidden flex-none flex items-center justify-center
        bg-${bgColor} ${className}`}
        {...props}
      >
        {src ? (
          <img
            alt="Avatar"
            src={src}
            className="object-cover min-h-full min-w-full rounded-full"
          />
        ) : firstName || lastName ? (
          <p
            className={`uppercase font-medium text-${fontSize} text-${textColor}`}
          >
            {firstName?.charAt(0)}
            {lastName?.charAt(0)}
          </p>
        ) : (
          <img
            src={userIconSrc}
            alt="User icon"
            className="rounded-full w-full max-w-[60px] mx-auto p-2"
          />
        )}
      </div>

      {flag && (
        <div
          className="absolute -bottom-1 left-0 w-4 h-4 border-2 border-white rounded-full
            flex items-center justify-center"
          style={{ backgroundColor: getFlagBgColor(flag) }}
        >
          <FlagIcon width="9" height="9" />
        </div>
      )}
    </div>
  );
};

export default ProfilePicture;
