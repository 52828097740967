import { useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { deleteScribe } from "../../../store/voiceRecorder/thunks";
import { getPatientNotes } from "../../../store/patient/thunks";
import CircleDivider from "../../Basic/CircleDivider";
import DeleteModal from "../../Basic/DeleteModal";
import ActionsButton from "../../Basic/ActionsButton";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/trash-redesign.svg";

const NoteCard = ({ note, onClick }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const getStartDate = (date) => {
    const dateObj = new Date(`${date.replace(/-/g, "/")} GMT+0`);
    const monthDay = dateObj.toLocaleString("en-us", {
      month: "short",
      day: "2-digit",
    });
    const year = dateObj.getFullYear();
    return `${monthDay} ${year}`;
  };
  const getTime = (date) => {
    const dateObj = new Date(`${date.replace(/-/g, "/")} GMT+0`);
    return dateObj.toLocaleString("en-us", {
      hour: "numeric",
      minute: "2-digit",
    });
  };

  const onDelete = () => {
    setOpenDeleteModal(true);
  };

  const handleDelete = () => {
    dispatch(
      deleteScribe(note.audio_id, note.conversation_history_id, () => {
        dispatch(getPatientNotes(id));
        setOpenDeleteModal(false);
      }),
    );
  };

  return (
    <>
      <div
        onClick={() => onClick(note)}
        className="cursor-pointer w-full rounded-2.5 p-2.5 height-sm:p-4 relative group hover:bg-white
        border border-transparent md:hover:border-gray-foreground flex md:items-center space-x-4 justify-between"
      >
        <div>
          <p className="text-base text-left font-semibold md:group-hover:text-primary-blue mb-1">
            {note.title}
          </p>
          <div className="text-sm font-medium text-tertiary flex items-center space-x-1">
            <span>{getStartDate(note.started_at)}</span>
            <CircleDivider />
            <span>{getTime(note.started_at)}</span>
          </div>
        </div>

        <ActionsButton
          className="md:hidden"
          actions={[
            {
              icon: <DeleteIcon width="20" height="20" className="flex-none" />,
              label: "Delete",
              onClick: onDelete,
            },
          ]}
        />

        <button
          type="button"
          className="hidden md:flex w-6 h-6 items-center justify-center flex-none opacity-0 md:group-hover:opacity-100"
          onClick={(e) => {
            e.stopPropagation();
            onDelete();
          }}
        >
          <DeleteIcon width="20" height="20" />
        </button>
      </div>

      {openDeleteModal && (
        <DeleteModal
          title="Delete Encounter"
          description="Are you sure you want to delete the note?"
          onDelete={handleDelete}
          onClose={() => setOpenDeleteModal(false)}
        />
      )}
    </>
  );
};

export default NoteCard;
