let audioContextInstance = null;

export const getAudioContext = () => {
  if (audioContextInstance) {
    audioContextInstance.resume();
    return audioContextInstance;
  }

  const AudioContext = window.AudioContext || window.webkitAudioContext;
  audioContextInstance = new AudioContext();
  audioContextInstance.resume();

  // iOS specific workaround
  if (audioContextInstance.state === "suspended") {
    const resumeAudio = async () => {
      await audioContextInstance.resume();
      document.removeEventListener("touchstart", resumeAudio);
      document.removeEventListener("touchend", resumeAudio);
    };

    document.addEventListener("touchstart", resumeAudio);
    document.addEventListener("touchend", resumeAudio);
  }

  return audioContextInstance;
};
