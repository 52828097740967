import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Popover from "@mui/material/Popover";
import Checkbox from "./Checkbox";
import { ReactComponent as ArrowIcon } from "../../assets/icons/arrow-down.svg";

const FacilityMultipleSelect = ({
  className,
  displaySelect,
  selectedFacilities,
  setSelectedFacilities,
  error,
  setError = () => {},
}) => {
  const { facilities } = useSelector((state) => state.facility);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [buttonWidth, setButtonWidth] = useState(0);
  const buttonRef = useRef(null);

  useEffect(() => {
    if (buttonRef.current) {
      handleResize();
      window.addEventListener("resize", handleResize);
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [buttonRef, displaySelect]);

  const handleResize = () => {
    setButtonWidth(buttonRef.current.clientWidth);
  };

  const onCloseDropdown = () => {
    setOpenDropdown(false);
    setAnchorEl(null);
  };

  return (
    <div className={`w-full flex flex-col space-y-2 ${className}`}>
      {displaySelect && (
        <div className="w-full">
          <button
            ref={buttonRef}
            type="button"
            className={`w-full px-3 h-10 bg-white rounded-lg border flex items-center justify-between
              text-left text-base md:text-sm ${error ? "border-red-400" : "border-gray-300"}`}
            onClick={(e) => {
              e.preventDefault();
              setOpenDropdown(!openDropdown);
              setAnchorEl(e.currentTarget);
            }}
          >
            <p>{selectedFacilities.length} clinics selected</p>
            <ArrowIcon width="10" height="7" />
          </button>
          {error && (
            <p className="text-sm text-red-400 font-normal mt-1.5">{error}</p>
          )}

          {openDropdown && anchorEl && (
            <Popover
              open={openDropdown}
              onClose={onCloseDropdown}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              PaperProps={{
                sx: {
                  borderRadius: "8px",
                  width: buttonWidth,
                  boxShadow: "0px 2px 6px 0px rgba(18, 18, 18, 0.08)",
                  margin: "8px 0 0 0",
                },
              }}
            >
              <div className="w-full flex flex-col space-y-1 px-4 py-2">
                {facilities?.map((facility) => (
                  <Checkbox
                    key={facility.customer_id}
                    className="py-1.5 font-medium"
                    variant="primary-filled"
                    label={facility.display_name}
                    checked={selectedFacilities.find(
                      (selectedFacility) =>
                        selectedFacility.customer_id === facility.customer_id,
                    )}
                    onChange={() => {
                      setError(null);
                      setSelectedFacilities((prev) =>
                        prev.find(
                          (selectedFacility) =>
                            selectedFacility.customer_id ===
                            facility.customer_id,
                        )
                          ? prev.filter(
                              (selectedFacility) =>
                                selectedFacility.customer_id !==
                                facility.customer_id,
                            )
                          : [...prev, facility],
                      );
                    }}
                  />
                ))}
              </div>
            </Popover>
          )}
        </div>
      )}

      <div className="flex flex-wrap gap-2">
        {selectedFacilities.map((facility) => (
          <div
            key={facility.customer_id}
            className="text-sm text-tertiary font-medium bg-gray-foreground py-1 px-2.5 rounded"
          >
            {facility.display_name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FacilityMultipleSelect;
