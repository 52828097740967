import { configureStore } from "@reduxjs/toolkit";
import scribeReducer from "../components/IndependentScribe/store/reducer";
import authReducer from "./auth/authReducer";
import billingMetricsReducer from "./billingMetrics/billingMetricsReducer";
import campaignReducer from "./campaign/campaignReducer";
import conversationReducer from "./conversation/conversationReducer";
import facilityReducer from "./facility/facilityReducer";
import metricsReducer from "./metrics/metricsReducer";
import patientReducer from "./patient/patientReducer";
import templateReducer from "./template/templateReducer";
import userReducer from "./user/userReducer";
import voiceRecorderReducer from "./voiceRecorder/voiceRecorderReducer";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    conversation: conversationReducer,
    patient: patientReducer,
    campaign: campaignReducer,
    template: templateReducer,
    metrics: metricsReducer,
    voiceRecorder: voiceRecorderReducer,
    billingMetrics: billingMetricsReducer,
    scribe: scribeReducer,
    facility: facilityReducer,
  },
});
