import { useEffect, useRef, useState } from "react";
import { LiveAudioVisualizer } from "react-audio-visualize";

const MicrophoneLiveVisualizer = ({ mediaRecorder, height = 40 }) => {
  const [canvasWidth, setCanvasWidth] = useState(0);
  const visualizerContainerRef = useRef(null);

  useEffect(() => {
    if (visualizerContainerRef.current) {
      handleResize();
      window.addEventListener("resize", handleResize);
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [visualizerContainerRef]);

  const handleResize = () => {
    setCanvasWidth(visualizerContainerRef.current.clientWidth);
  };

  return (
    <div className="w-full overflow-hidden" ref={visualizerContainerRef}>
      {mediaRecorder ? (
        <LiveAudioVisualizer
          mediaRecorder={mediaRecorder}
          height={height}
          width={canvasWidth}
          barWidth={3}
          gap={2}
          barColor={"#7DA9FF"}
          smoothingTimeConstant={0.8}
        />
      ) : (
        <div className="w-full h-0.5 bg-border" />
      )}
    </div>
  );
};

export default MicrophoneLiveVisualizer;
