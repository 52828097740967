const TabButton = ({ icon, label, onClick, active }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={`flex items-center justify-center space-x-2 w-full h-full font-medium rounded-lg text-sm lg:text-base
        ${active === label ? "text-primary bg-primary-blue-light" : "text-tertiary"}`}
    >
      {icon}
      <span>{label}</span>
    </button>
  );
};

export default TabButton;
