import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import updateLocale from "dayjs/plugin/updateLocale";
import utc from "dayjs/plugin/utc";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { setAuthToken } from "./helpers/config";
import { store } from "./store";
import "./index.css";
import BugsnagPerformance from "@bugsnag/browser-performance";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import LocalStorageService from "./services/LocalStorageService";

const storageService = new LocalStorageService();
setAuthToken(storageService.getItem("token"));

dayjs.extend(updateLocale);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);
dayjs.extend(utc);
dayjs.updateLocale("en", {
  weekStart: 1,
});

Bugsnag.start({
  releaseStage: process.env.NODE_ENV,
  appVersion: process.env.REACT_APP_VERSION,
  apiKey: "4606bb2fa569487141ae78336a6efde2",
  plugins: [new BugsnagPluginReact()],
});

BugsnagPerformance.start({
  apiKey: "4606bb2fa569487141ae78336a6efde2",
});

console.log("Version: ", process.env.REACT_APP_VERSION);

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <StrictMode>
  <ErrorBoundary>
    <Provider store={store}>
      <BrowserRouter>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <App />
        </LocalizationProvider>
      </BrowserRouter>
    </Provider>
  </ErrorBoundary>,
  // </StrictMode>
);
