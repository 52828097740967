import { useEffect } from "react";
import { getProviderNote } from "../store/thunks";
import { useDispatch } from "react-redux";
import ScribeNoteSkeleton from "../../Skeletons/ScribeNoteSkeleton";

const ScribeViewDetailsSkeleton = ({ scribe }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProviderNote(scribe.audio_id));
  }, [scribe.audio_id, dispatch]);

  return <ScribeNoteSkeleton />;
};

export default ScribeViewDetailsSkeleton;
