import axios from "axios";
import { BASE_URL } from "../../helpers/config";
import { setNotificationAction } from "../user/actions";
import { setTwilioTokenAction, setTranscribingAction } from "./actions";
import { setProviderNoteDeletedAction } from "../../components/IndependentScribe/store/actions";
import Bugsnag from "@bugsnag/js";

export const sendPatientAudioPart =
  (
    patientId,
    record,
    recordId,
    status,
    inputLanguage = "en",
    title = "",
    freeText = "",
    onEnd = () => {},
    onError = () => {},
    noteTemplateId = null,
  ) =>
  (dispatch) => {
    dispatch(setTranscribingAction(true));
    axios
      .post(
        `${BASE_URL}/voice/send-audio`,
        {
          file: record,
          patient_id: patientId,
          audio_id: recordId,
          audio_status: status,
          inputLanguage,
          title,
          freeText,
          noteTemplateId,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          timeout: 0,
        },
      )
      .then((response) => {
        onEnd(response.data);
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          return;
        }

        console.error(error);

        if (!error.response.data.error) {
          Bugsnag.notify(error);
        }

        onError(error.response.data.error);
      })
      .finally(() => {
        dispatch(setTranscribingAction(false));
      });
  };

export const deleteScribe =
  (audioId, noteId, onSuccess = () => {}) =>
  (dispatch) => {
    axios
      .post(`${BASE_URL}/voice/${audioId}/delete`)
      .then((response) => {
        if (response.data.error) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to delete scribe",
            }),
          );
          Bugsnag.notify(response.data.error);
        } else {
          dispatch(setProviderNoteDeletedAction(noteId));
          onSuccess();
          dispatch(
            setNotificationAction({
              status: "success",
              title: "Successfully deleted scribe",
              desc: "Scribe has been successfully deleted",
            }),
          );
        }
      })
      .catch((error) => {
        console.error(error);
        Bugsnag.notify(error);
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to delete scribe",
          }),
        );
      });
  };

export const getTwilioToken = () => (dispatch) => {
  axios.get(`${BASE_URL}/voice/call/get-token`).then((response) => {
    if (response.data.error) {
      dispatch(
        setNotificationAction({
          status: "error",
          title: "Something went wrong",
          desc: "Failed to get call token",
        }),
      );
      Bugsnag.notify(response.data.error);
    } else {
      dispatch(setTwilioTokenAction(response.data.data.token));
    }
  });
};

export const sendTranscription =
  (audioId, transcription, onEnd = () => {}) =>
  (dispatch) => {
    axios
      .post(`${BASE_URL}/transcription/${audioId}/text`, {
        audio_transcription: transcription,
        type: "part",
      })
      .then((response) => {
        if (response.data.error) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to send transcription",
            }),
          );
          Bugsnag.notify(response.data.error);
        }
        onEnd();
      });
  };
