import { useState } from "react";
import { useSelector } from "react-redux";
import PillTabsBg from "../../Basic/PillTabsBg";
import ScribeUnassignedPatient from "../ScribeUnassignedPatient";
import ScribeHeaderTitle from "../ScribeHeaderTitle";
import ScribePatient from "../ScribePatient";
import { ReactComponent as ArrowLeftIcon } from "../../../assets/icons/arrow-left.svg";
import { ReactComponent as NoteIcon } from "../../../assets/icons/note-icon.svg";
// import { ReactComponent as TranscriptIcon } from "../../../assets/icons/chat-round-call.svg";

const items = [
  // {
  //   title: "Transcript",
  //   icon: (
  //     <TranscriptIcon className="stroke-[#667085] w-5 h-5 group-[.active]:stroke-white" />
  //   ),
  // },
  {
    title: "Note",
    icon: (
      <NoteIcon className="stroke-tertiary w-5 h-5 group-[.active]:stroke-white" />
    ),
  },
];

const ScribeHeader = ({
  scribe,
  patient,
  onBack,
  handleAssignPatientId,
  setShowAssignPatientModal,
}) => {
  const { onlyScribeFeature } = useSelector((state) => state.user);
  const [activeTab, setActiveTab] = useState("Note");

  const showPatient =
    patient !== null &&
    // Hack for unassigned patient
    patient &&
    patient.first_name !== "018ee44a-5aa1-74b5-b385-a3a994a769b5";
  // End hack

  const canShowPills =
    items.length > 1 && (scribe?.note_id !== -1 || scribe?.isRecording);

  return (
    <div
      className="sticky top-0 w-full flex flex-col md:py-4 md:gap-3 bg-white border-b border-gray-foreground
        md:text-2xl"
    >
      <div className="order-2 md:order-1 md:bg-white bg-gray-150">
        <ScribeHeaderTitle
          scribe={scribe}
          scribeOriginalTitle={scribe?.originalTitle || scribe?.title}
        />
      </div>
      <div
        className="flex flex-row gap-3 md:gap-0 md:justify-between items-center text-base
          py-2 md:py-0 px-5 md:px-0 order-1 md:order-2"
      >
        <button type="button" className="md:hidden" onClick={onBack}>
          <ArrowLeftIcon className="stroke-primary" />
        </button>
        <div className="grow md:grow-0">
          {showPatient ? (
            <ScribePatient
              patient={patient}
              setShowAssignPatientModal={setShowAssignPatientModal}
              handleAssignPatientId={handleAssignPatientId}
            />
          ) : (
            <ScribeUnassignedPatient
              setShowAssignPatientModal={setShowAssignPatientModal}
              className={onlyScribeFeature ? "hidden" : ""}
            />
          )}
        </div>
        {canShowPills && (
          <PillTabsBg
            tabs={items}
            activeTab={activeTab}
            onChange={setActiveTab}
            tabClassName="py-1 text-center"
          />
        )}
      </div>
    </div>
  );
};

export default ScribeHeader;
