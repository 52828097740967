import axios from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import NotEnoughTranscript from "../components/Basic/Warning/NotEnoughTranscript";
import CancelRecordingModal from "../components/IndependentScribe/CancelRecordingModal";
import useRecorder from "../components/IndependentScribe/Recorder";
import ScribeContainer from "../components/IndependentScribe/ScribeContainer";
import ScribeList from "../components/IndependentScribe/ScribeList";
import {
  ERROR_NOT_ENOUGH_TRANSCRIPT,
  NOTE_NOT_SELECTED,
  NOTE_RECORDING_ID,
} from "../components/IndependentScribe/consts";
import {
  setInterruptedRecordingActionAction,
  setIsCurrentlyRecordingAction,
  setProviderNoteAction,
  setProviderNotesLoadingAction,
  setProviderNotesRecordingAction,
  setProviderNotesRecordingCancelledAction,
  setProviderNotesTogglePauseAction,
  setShowScribeNoteTemplateAction,
} from "../components/IndependentScribe/store/actions";
import {
  getProviderNote,
  getProviderNotes,
} from "../components/IndependentScribe/store/thunks";
import { SetInterruptedRecordingActionType } from "../components/IndependentScribe/store/types";
import { BASE_URL } from "../helpers/config";
import useIndependentScribeRecorder from "../hooks/useIndependentScribeRecorder";
import { store } from "../store";
import { saveEncounterPatient } from "../store/conversation/thunks";
import { getPatientInfo } from "../store/patient/thunks";
import { setSelectedSettingsTabAction } from "../store/user/actions";
import { sendPatientAudioPart } from "../store/voiceRecorder/thunks";

const Scribe = () => {
  const { user } = useSelector((state) => state.user);
  const { selectedScribeNoteTemplate, interruptedRecordingAction } =
    useSelector((state) => state.scribe);
  const [isNewScribe, setIsNewScribe] = useState(false);
  const [onCancelStrategy, setOnCancelStrategy] = useState(null);
  const [inputLanguage, setInputLanguage] = useState("en");
  const [showCancelRecordingModal, setShowCancelRecordingModal] =
    useState(false);
  const waitingForId = useRef(NOTE_NOT_SELECTED);
  const [notEnoughTranscript, setNotEnoughTranscript] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    mediaRecorder,
    microphones,
    microphoneId,
    setMicrophoneId,
    loadMicrophones,
  } = useRecorder();

  const {
    recorderState,
    startRecording,
    togglePause,
    toggleError,
    cancelRecording,
    saveRecording,
  } = useIndependentScribeRecorder(
    microphoneId,
    mediaRecorder,
    (data, status) => {
      dispatch(
        sendPatientAudioPart(
          patient.patient_id,
          data,
          recorderState.audioId,
          status,
          inputLanguage,
          "",
          "",
          (data) => {
            waitingForId.current = data.data.voice_conversation.audio_id;
          },
          () => {},
          selectedScribeNoteTemplate
            ? selectedScribeNoteTemplate.note_template_id
            : null,
        ),
      );
    },
    (audioId) => {
      dispatch(
        setProviderNoteAction({
          noteId: NOTE_RECORDING_ID,
          note: {
            finished_at: new Date().toISOString(),
            isGenerating: true,
          },
        }),
      );

      dispatch(
        sendPatientAudioPart(
          patient.patient_id,
          null,
          audioId,
          "end",
          inputLanguage,
          selectedScribe.title,
          selectedScribe.free_text,
          () => {},
          (error) => {
            if (error === ERROR_NOT_ENOUGH_TRANSCRIPT) {
              onError();
              setNotEnoughTranscript(true);
            }
          },
        ),
      );
    },
  );

  const selectedAudioId =
    new URLSearchParams(location.search).get("id") || NOTE_NOT_SELECTED;

  useEffect(() => {
    if (
      waitingForId.current !== NOTE_NOT_SELECTED &&
      selectedAudioId === waitingForId.current
    ) {
      waitingForId.current = NOTE_NOT_SELECTED;
    }
  }, [selectedAudioId]);

  const handleSelectScribe = useCallback(
    (note) => {
      if (
        !note ||
        note.audioId === NOTE_NOT_SELECTED ||
        selectedAudioId === note.audioId
      ) {
        if (note.audioId === NOTE_NOT_SELECTED) {
          setIsNewScribe(true);
        }

        navigate("?");
        return;
      }

      navigate(`?id=${note.audioId}`);
    },
    [selectedAudioId, navigate],
  );

  useEffect(() => {
    return () => {
      dispatch(setProviderNotesRecordingCancelledAction());
      dispatch(setShowScribeNoteTemplateAction(false));
      dispatch(setIsCurrentlyRecordingAction(false));
      dispatch(setInterruptedRecordingActionAction(null));
    };
  }, [dispatch]);

  useEffect(() => {
    if (!providerNotes) {
      dispatch(setProviderNotesLoadingAction(true));
    }
    dispatch(getProviderNotes(user.doctor_id));

    const interval = setInterval(() => {
      dispatch(getProviderNotes(user.doctor_id));
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [dispatch, user]);

  const dynamicStyles = {
    list:
      selectedAudioId === NOTE_NOT_SELECTED && !isNewScribe
        ? "block"
        : "hidden md:flex md:justify-center",
    container:
      selectedAudioId === NOTE_NOT_SELECTED && !isNewScribe
        ? "hidden md:grid"
        : "grid",
  };

  const recordingStarted = !!recorderState.audioId;

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (recordingStarted) {
        event.preventDefault();
        setOnCancelStrategy("new");
        setShowCancelRecordingModal(true);
      }
    };

    const handlePopState = (_) => {
      if (recordingStarted) {
        setShowCancelRecordingModal(true);
      }
    };

    dispatch(setIsCurrentlyRecordingAction(recordingStarted));
    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("popstate", handlePopState);

    if (recordingStarted) {
      history.pushState(null, null, window.location.href);
    }

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", handlePopState);
    };
  }, [recordingStarted]);

  const { providerNotes, providerNotesLoading } = useSelector(
    (state) => state.scribe,
  );
  const [mockPatient, setMockPatient] = useState(null);
  const userActionRef = useRef(false);

  const selectedScribe = providerNotes?.find(
    (note) =>
      note.audio_id === selectedAudioId ||
      (!selectedAudioId && note.audio_id === NOTE_NOT_SELECTED),
  );

  const patient = selectedScribe?.patient || mockPatient;

  const setPatient = useCallback(
    (patient) => {
      dispatch(
        setProviderNoteAction({
          noteId: selectedScribe?.note_id,
          note: {
            patient_id: patient.patient_id,
            patient,
          },
        }),
      );
    },
    [dispatch, selectedScribe],
  );

  useEffect(() => {
    if (
      userActionRef.current &&
      !recordingStarted &&
      patient &&
      selectedScribe.audio_id !== NOTE_NOT_SELECTED
    ) {
      dispatch(
        saveEncounterPatient(selectedScribe.audio_id, patient.patient_id),
      );
    }

    userActionRef.current = false;
  }, [
    patient,
    selectedScribe?.audio_id,
    selectedScribe?.note_id,
    recordingStarted,
    dispatch,
  ]);

  useEffect(() => {
    userActionRef.current = false;
  }, []); // TODO CHECK

  useEffect(() => {
    const isNewNoteAvailable =
      providerNotes?.find(
        (note) =>
          waitingForId.current !== NOTE_NOT_SELECTED &&
          note.audio_id === waitingForId.current,
      ) !== undefined;

    if (waitingForId.current !== NOTE_NOT_SELECTED && isNewNoteAvailable) {
      handleSelectScribe({ audioId: waitingForId.current });
      dispatch(setProviderNotesRecordingCancelledAction());
      dispatch(getProviderNote(waitingForId.current));
      cancelRecording();
      waitingForId.current = NOTE_NOT_SELECTED;
    }
  }, [providerNotes, dispatch, cancelRecording, handleSelectScribe]);

  // Hack for unassigned patient
  const loadUnassignedPatient = useCallback(() => {
    if (mockPatient) {
      return;
    }

    axios
      .post(`${BASE_URL}/patient/search`, {
        q: "018ee44a-5aa1-74b5-b385-a3a994a769b5",
        offset: 0,
        limit: 10,
      })
      .then((response) => {
        const patients = response.data.data.patients.map((patient) => {
          patient.age =
            new Date().getFullYear() -
            new Date(patient.birthdate).getFullYear();
          return patient;
        });
        setMockPatient(patients[0]);
      });
  }, [mockPatient]);

  const handleAssignPatientId = useCallback(
    (patientId) => {
      if (!mockPatient) {
        return;
      }

      let assignPatientId = patientId;

      if (!assignPatientId) {
        assignPatientId = mockPatient.patient_id;
      }

      dispatch(
        getPatientInfo(assignPatientId, (patient) => {
          setPatient(patient);
        }),
      );
    },
    [mockPatient, dispatch, setPatient],
  );

  useEffect(() => {
    if (!mockPatient) {
      loadUnassignedPatient();
    }
  }, [mockPatient, loadUnassignedPatient]);
  // End hack

  useEffect(() => {
    setMockPatient(null);
    waitingForId.current = NOTE_NOT_SELECTED;
  }, [user.customer_id]);

  const onBackCancelRecording = useCallback(() => {
    setOnCancelStrategy("back");
    setShowCancelRecordingModal(true);
  }, []);

  useEffect(() => {
    if (interruptedRecordingAction) {
      setShowCancelRecordingModal(true);
    }
  }, [interruptedRecordingAction]);

  const onBack = useCallback(() => {
    setIsNewScribe(false);

    if (selectedAudioId !== null) {
      navigate("?");
    }
  }, [selectedAudioId, navigate]);

  const onCancel = useCallback(() => {
    if (onCancelStrategy === "back") {
      onBack();
    } else if (onCancelStrategy === "new") {
      setIsNewScribe(true);
    }

    if (selectedAudioId !== null) {
      navigate("?");
    }

    dispatch(setInterruptedRecordingActionAction(null));
    dispatch(setProviderNotesRecordingCancelledAction({ isVisible: true }));

    cancelRecording();
    setShowCancelRecordingModal(false);
    setNotEnoughTranscript(false);

    if (
      interruptedRecordingAction?.type ===
      SetInterruptedRecordingActionType.NAVIGATION
    ) {
      navigate(interruptedRecordingAction.value);
    } else if (
      interruptedRecordingAction?.type ===
      SetInterruptedRecordingActionType.SETTINGS_NAVIGATION
    ) {
      const { selectedSettingsTab } = store.getState().user;

      if (!selectedSettingsTab) {
        dispatch(setSelectedSettingsTabAction("Profile"));
      }

      navigate(interruptedRecordingAction.value);
    }

    // do a window page reload
    // window.location.reload();
  }, [
    onCancelStrategy,
    selectedAudioId,
    interruptedRecordingAction,
    cancelRecording,
    onBack,
    navigate,
    dispatch,
  ]);

  const onSetNewScribe = useCallback(() => {
    if (recordingStarted) {
      setOnCancelStrategy("new");
      setShowCancelRecordingModal(true);
      return;
    }

    if (selectedAudioId !== null) {
      navigate("?");
    }

    dispatch(setProviderNotesRecordingCancelledAction({ isVisible: true }));
    setIsNewScribe(true);
  }, [recordingStarted, selectedAudioId, navigate, dispatch]);

  const onSetRecordingStarted = useCallback(() => {
    if (!recordingStarted) {
      dispatch(setProviderNotesRecordingAction());
    }

    startRecording();
  }, [recordingStarted, dispatch, startRecording]);

  const onTogglePause = useCallback(() => {
    dispatch(setProviderNotesTogglePauseAction());
    togglePause();
  }, [togglePause, dispatch]);

  const onError = useCallback(() => {
    dispatch(setProviderNotesTogglePauseAction());
    toggleError();
  }, [dispatch, toggleError]);

  return (
    <div className="h-full w-full md:grid md:grid-cols-[300px,1fr] overflow-auto">
      <div
        className={`md:block md:h-screen overflow-hidden px-5 md:px-4 bg-gray-background ${dynamicStyles.list}`}
      >
        <ScribeList
          user={user}
          isRecording={!!recordingStarted}
          providerNotes={providerNotes}
          handleSelectScribe={handleSelectScribe}
          setIsNewScribe={onSetNewScribe}
          notesLoading={providerNotesLoading}
        />
      </div>
      <div
        className={`w-full h-full md:px-6 bg-white overflow-hidden grid-rows-tab-layout ${dynamicStyles.container}`}
      >
        <ScribeContainer
          selectedAudioId={selectedAudioId}
          recordingStarted={recordingStarted}
          patient={patient}
          scribe={selectedScribe}
          mediaRecorder={mediaRecorder}
          recorderState={recorderState}
          loadMicrophones={loadMicrophones}
          microphoneId={microphoneId}
          microphones={microphones}
          setMicrophoneId={setMicrophoneId}
          userActionRef={userActionRef}
          setRecordingStarted={onSetRecordingStarted}
          saveRecording={saveRecording}
          setInputLanguage={setInputLanguage}
          onBack={onBack}
          onCancel={onCancel}
          onBackCancelRecording={onBackCancelRecording}
          handleAssignPatientId={handleAssignPatientId}
          togglePause={onTogglePause}
        />
      </div>
      {showCancelRecordingModal && (
        <CancelRecordingModal
          onClose={() => {
            setShowCancelRecordingModal(false);
            dispatch(setInterruptedRecordingActionAction(null));
          }}
          onConfirm={onCancel}
        />
      )}
      {notEnoughTranscript && <NotEnoughTranscript onSubmit={onCancel} />}
    </div>
  );
};

export default Scribe;
