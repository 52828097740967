import { useEffect, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";

const TextareaAutosizeForm = ({
  className = "",
  textareaClassName = "text-sm",
  label,
  placeholder,
  name,
  required,
  withoutAsterisk,
  register = () => {},
  registerOptions = {},
  error,
  initLength = 0,
  maxLength,
  hasBorder = true,
  onChange = () => {},
  onFocus = () => {},
  onBlur = () => {},
  ...props
}) => {
  const [length, setLength] = useState(initLength);
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    setLength(initLength);
  }, [initLength]);

  return (
    <div className={`flex flex-col text-sm ${className}`}>
      {label && (
        <p className="font-semibold mb-1.5">
          {label}
          {required && !withoutAsterisk && (
            <span className="text-red-600">*</span>
          )}
        </p>
      )}
      <div className="relative">
        <TextareaAutosize
          name={name}
          className={`w-full resize-none px-3 py-2.5 outline-none focus:outline-none rounded-lg align-top scrollbar
            ${textareaClassName}
            ${hasBorder ? "border focus:ring-0" : ""}
            ${error ? "border-red-400" : "border-gray-300"}
          `}
          placeholder={placeholder}
          maxLength={maxLength}
          onChange={(e) => {
            onChange(e);
            if (maxLength) {
              setLength(e.target.value.length);
            }
          }}
          onFocus={(e) => {
            setFocused(true);
            onFocus(e);
          }}
          onBlur={(e) => {
            setFocused(false);
            onBlur(e);
          }}
          {...register(name, {
            required: required,
            ...registerOptions,
            onChange: (e) => {
              onChange(e);
              if (maxLength) {
                setLength(e.target.value.length);
              }
            },
            onFocus: (e) => {
              setFocused(true);
              onFocus(e);
            },
            onBlur: (e) => {
              setFocused(false);
              onBlur(e);
            },
          })}
          {...props}
        />
        {maxLength && focused && (
          <p className="absolute bottom-0.5 right-1 text-xs text-gray-400">
            {maxLength - length}
          </p>
        )}
      </div>
      {error && (
        <p className="text-red-400 text-sm font-normal mt-1.5">
          {error.message}
        </p>
      )}
    </div>
  );
};

export default TextareaAutosizeForm;
