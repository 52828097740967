let isProcessorRegistered = false;

export class SoundMeter {
  constructor(context) {
    if (
      !(context instanceof (window.AudioContext || window.webkitAudioContext))
    ) {
      throw new Error("Invalid AudioContext provided");
    }
    this.context = context;
    this.instant = 0.0;
    this.slow = 0.0;
    this.clip = 0.0;
    this.audioWorkletNode = null;
    this.mic = null;
    this.fallbackScriptProcessor = null;
  }

  async connectToSource(stream) {
    try {
      // console.log("Stream received:", stream);
      // console.log("AudioContext state before resume:", this.context.state);

      if (!stream || !stream.active || stream.getAudioTracks().length === 0) {
        throw new Error("Invalid or inactive audio stream");
      }

      await this.context.resume();
      // console.log("AudioContext state after resume:", this.context.state);

      this.mic = this.context.createMediaStreamSource(stream);
      // console.log("MediaStreamSource created:", this.mic);

      if (!this.mic) {
        throw new Error("Failed to create MediaStreamSource");
      }

      if (this.context.audioWorklet) {
        // console.log("Setting up AudioWorklet");
        await this.setupAudioWorklet();
      } else {
        // console.log("Setting up ScriptProcessor");
        this.setupScriptProcessor();
      }

      if (!this.audioWorkletNode && !this.fallbackScriptProcessor) {
        throw new Error(
          "Neither AudioWorklet nor ScriptProcessor are available",
        );
      }

      this.mic.connect(this.audioWorkletNode || this.fallbackScriptProcessor);
      // console.log("Connection successful");
    } catch (e) {
      console.error(
        "Error connecting to audio source:",
        e,
        "Context state:",
        this.context.state,
      );
      throw e;
    }
  }

  async setupAudioWorklet() {
    try {
      await this.registerAudioWorkletProcessor();
      this.audioWorkletNode = new AudioWorkletNode(
        this.context,
        "sound-processor",
      );
      this.audioWorkletNode.port.onmessage = (event) => {
        const { instant, slow, clip } = event.data;
        Object.assign(this, { instant, slow, clip });
      };
      // console.log("AudioWorklet setup successful");
    } catch (e) {
      console.error("Error setting up AudioWorklet:", e);
      this.audioWorkletNode = null;
    }
  }

  async registerAudioWorkletProcessor() {
    if (isProcessorRegistered) {
      // console.log(
      //   "AudioWorkletProcessor 'sound-processor' is already registered",
      // );
      return;
    }

    const processorCode = `
      class SoundProcessor extends AudioWorkletProcessor {
        constructor() {
          super();
          this._instant = 0.0;
          this._slow = 0.0;
          this._clip = 0.0;
        }

        process(inputs) {
          const input = inputs[0];
          if (input && input.length > 0) {
            const samples = input[0];
            const { sum, max } = this.processAudioSamples(samples);
            this.updateMetrics(sum, max, samples.length);
          }
          this.port.postMessage({ instant: this._instant, slow: this._slow, clip: this._clip });
          return true;
        }

        processAudioSamples(samples) {
          return samples.reduce((acc, x) => {
            acc.sum += x * x;
            acc.max = Math.max(acc.max, Math.abs(x));
            return acc;
          }, { sum: 0, max: 0 });
        }

        updateMetrics(sum, max, sampleCount) {
          this._instant = Math.sqrt(sum / sampleCount);
          this._slow = 0.95 * this._slow + 0.05 * this._instant;
          this._clip = max > 0.99 ? 1 : 0;
        }
      }

      registerProcessor("sound-processor", SoundProcessor);
    `;

    const blob = new Blob([processorCode], { type: "application/javascript" });
    const url = URL.createObjectURL(blob);

    try {
      await this.context.audioWorklet.addModule(url);
      isProcessorRegistered = true;
      console.log(
        "AudioWorkletProcessor 'sound-processor' registered successfully",
      );
    } catch (error) {
      if (error.name === "InvalidStateError") {
        console.log(
          "AudioWorkletProcessor 'sound-processor' is already registered",
        );
        isProcessorRegistered = true;
      } else {
        throw error;
      }
    } finally {
      URL.revokeObjectURL(url);
    }
  }

  setupScriptProcessor() {
    try {
      console.warn(
        "AudioWorklet not supported, falling back to ScriptProcessorNode",
      );

      /**
       * TODO:
       * The ScriptProcessorNode is deprecated and will be removed in the future.
       * It is only used as a fallback when AudioWorklet is not supported.
       * The AudioWorklet is the recommended way to process audio in the browser.
       * https://developer.mozilla.org/en-US/docs/Web/API/ScriptProcessorNode
       **/
      this.fallbackScriptProcessor = this.context.createScriptProcessor(
        4096,
        1,
        1,
      );
      this.fallbackScriptProcessor.onaudioprocess =
        this.processAudioFallback.bind(this);
    } catch (e) {
      console.error("Error setting up ScriptProcessor:", e);
      this.fallbackScriptProcessor = null;
    }
  }

  processAudioFallback(event) {
    const input = event.inputBuffer.getChannelData(0);
    const { sum, max } = this.processAudioSamples(input);
    this.updateMetrics(sum, max, input.length);
  }

  processAudioSamples(samples) {
    return samples.reduce(
      (acc, x) => {
        acc.sum += x * x;
        acc.max = Math.max(acc.max, Math.abs(x));
        return acc;
      },
      { sum: 0, max: 0 },
    );
  }

  updateMetrics(sum, max, sampleCount) {
    this.instant = Math.sqrt(sum / sampleCount);
    this.slow = 0.95 * this.slow + 0.05 * this.instant;
    this.clip = max > 0.99 ? 1 : 0;
  }

  stop() {
    if (this.mic) {
      this.mic.disconnect();
      this.mic = null;
    }

    if (this.audioWorkletNode) {
      this.audioWorkletNode.port.close();
      this.audioWorkletNode.disconnect();
      this.audioWorkletNode = null;
    }

    if (this.fallbackScriptProcessor) {
      this.fallbackScriptProcessor.disconnect();
      this.fallbackScriptProcessor = null;
    }
  }
}
