import Select from "./Select";

const MicSelect = ({
  name,
  register,
  loadMicrophones,
  microphones,
  onChange,
  ...props
}) => {
  return (
    <div
      onClick={async () => {
        loadMicrophones();
      }}
    >
      <Select
        options={microphones}
        name={name}
        register={register}
        onChange={(e) => onChange?.(e.target.value)}
        {...props}
      />
    </div>
  );
};

export default MicSelect;
