const Select = ({
  label,
  options,
  className = "",
  selectClassName = "",
  optionClass = "",
  borderColor = "gray-300",
  placeholder,
  required,
  withoutAsterisk,
  name,
  register = () => {},
  disabled,
  error,
  onChange,
  ...props
}) => {
  return (
    <div className={`flex flex-col text-sm max-w-full ${className}`}>
      {label && (
        <p className="font-semibold mb-1.5">
          {label}
          {required && !withoutAsterisk && (
            <span className="text-red-600">*</span>
          )}
        </p>
      )}
      <select
        {...register(name, { required })}
        disabled={disabled}
        onChange={(e) => {
          register(name)?.onChange(e);
          if (onChange) {
            onChange(e);
          }
        }}
        {...props}
        className={`p-2 pl-3 pr-6 rounded-lg border outline-none h-10 relative truncate ${selectClassName}
          ${error ? "border-red-400" : `border-${borderColor}`}
          ${disabled ? "bg-gray-150 opacity-100" : "bg-white"}`}
      >
        {placeholder && (
          <option value="" disabled hidden>
            {placeholder}
          </option>
        )}
        {options.length > 0 ? (
          options.map((option, index) => (
            <option
              key={index}
              value={option.value}
              className={`truncate ${optionClass}`}
            >
              {option.text}
            </option>
          ))
        ) : (
          <option disabled value="">
            No options
          </option>
        )}
      </select>
      {error?.message && (
        <p className="text-red-400 font-normal mt-1.5">{error.message}</p>
      )}
    </div>
  );
};

export default Select;
