import { useCallback, useEffect, useRef, useState } from "react";
import { getAudioContext } from "../audioContextSingleton";

const setupAudioNodes = (audioContext) => {
  console.log("Setting up audio nodes");
  const filter = audioContext.createBiquadFilter();
  filter.type = "lowpass";
  filter.frequency.value = 1000;

  const gainNode = audioContext.createGain();
  gainNode.gain.value = 5;

  const compressor = audioContext.createDynamicsCompressor();
  compressor.threshold.value = -40;
  compressor.knee.value = 40;
  compressor.ratio.value = 12;
  compressor.attack.value = 0;
  compressor.release.value = 0.25;

  return { filter, gainNode, compressor };
};

const useRecorder = () => {
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [microphones, setMicrophones] = useState([]);
  const [microphoneId, setMicrophoneId] = useState(null);
  const [permissionGranted, setPermissionGranted] = useState(false);
  const audioNodesRef = useRef(null);
  const streamRef = useRef(null);

  const loadPermissions = useCallback(async () => {
    try {
      console.log("Requesting microphone permissions");
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      streamRef.current = stream; // Keep the stream active
      setPermissionGranted(true);
      console.log("Microphone permissions granted");
      return true;
    } catch (_error) {
      // alert("Error requesting microphone permissions:", JSON.stringify(error));
      setPermissionGranted(false);
      return false;
    }
  }, []);

  const loadMicrophones = useCallback(async () => {
    if (!(await loadPermissions())) {
      // alert("Permission not granted, cannot load microphones");
      return;
    }

    if (!navigator.mediaDevices?.enumerateDevices) {
      // alert("enumerateDevices() not supported.");
      return;
    }

    try {
      console.log("Enumerating devices");
      const devices = await navigator.mediaDevices.enumerateDevices();
      const audioInputDevices = devices.filter(
        (device) => device.kind === "audioinput",
      );
      const microphonesMapped = audioInputDevices.map((device) => ({
        text: device.label || `Microphone ${device.deviceId}`,
        value: device.deviceId,
      }));

      setMicrophones(microphonesMapped);
      console.log("Microphones loaded:", microphonesMapped);

      // If we don't have a selected microphone, select the first one
      if (!microphoneId && microphonesMapped.length > 0) {
        console.log("Selecting first microphone, as none was selected", {
          microphonesMapped,
        });
        setMicrophoneId(microphonesMapped[0].value);
      } else {
        console.log("Microphone not automatically selected", {
          microphoneId,
          microphonesMapped,
        });
      }
    } catch (error) {
      // alert("Error enumerating devices:", error);
      console.error("Error enumerating devices:", error);
    }
  }, [microphoneId, loadPermissions]);

  useEffect(() => {
    const handleDeviceChange = () => {
      console.log("Device change detected");
      loadMicrophones();
    };

    navigator.mediaDevices.addEventListener("devicechange", handleDeviceChange);

    return () => {
      navigator.mediaDevices.removeEventListener(
        "devicechange",
        handleDeviceChange,
      );
    };
  }, [loadMicrophones]);

  useEffect(() => {
    if (!microphoneId || !permissionGranted) {
      // alert("No microphone selected or permission not granted");
      return;
    }

    const setup = async () => {
      try {
        console.log("Setting up audio capture");
        if (streamRef.current) {
          streamRef.current.getTracks().forEach((track) => track.stop());
        }

        const stream = await navigator.mediaDevices.getUserMedia({
          audio: {
            deviceId: { exact: microphoneId },
            noiseSuppression: false,
            echoCancellation: false,
          },
        });

        streamRef.current = stream;

        const audioContext = getAudioContext();
        console.log(
          `Setup, current status: ${audioContext?.state}; media recorder: ${mediaRecorder?.state}`,
        );
        await audioContext.resume();

        audioNodesRef.current = setupAudioNodes(audioContext);

        const source = audioContext.createMediaStreamSource(stream);
        const destination = audioContext.createMediaStreamDestination();
        source.connect(audioNodesRef.current.filter);
        audioNodesRef.current.filter.connect(audioNodesRef.current.gainNode);
        audioNodesRef.current.gainNode.connect(
          audioNodesRef.current.compressor,
        );
        audioNodesRef.current.compressor.connect(destination);

        // Try different MIME types
        const mimeTypes = [
          "audio/webm;codecs=opus",
          "audio/webm",
          "audio/ogg;codecs=opus",
          "audio/mp4",
          "audio/mpeg",
          "", // Let the browser choose
        ];

        const options = {};

        for (const mimeType of mimeTypes) {
          if (MediaRecorder.isTypeSupported(mimeType)) {
            options.mimeType = mimeType;
            // console.log(`Using MIME type: ${mimeType}`);
            break;
          }
        }

        if (
          options.mimeType.includes("webm") ||
          options.mimeType.includes("ogg")
        ) {
          options.audioBitsPerSecond = 128000;
        }

        const newMediaRecorder = new MediaRecorder(destination.stream, options);

        setMediaRecorder(newMediaRecorder);
        console.log("MediaRecorder set up", newMediaRecorder?.state);
      } catch (error) {
        console.error("Error setting up audio capture:", error);
      }
    };

    setup();

    return () => {
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
      }
    };
  }, [microphoneId, permissionGranted]);

  useEffect(() => {
    console.log(`media recorder: ${mediaRecorder}`);
  }, [mediaRecorder]);

  // iOS-specific setup
  useEffect(() => {
    const isiOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    if (isiOS) {
      const handleTouchStart = () => {
        const audioContext = getAudioContext();
        console.log(
          "Resuming AudioContext on iOS, current status: ",
          audioContext?.state,
          mediaRecorder?.state,
        );
        audioContext.resume().then(() => {
          console.log("AudioContext resumed on iOS");
        });
        // loadPermissions(); // Re-request permissions on user interaction
      };

      document.body.addEventListener("touchstart", handleTouchStart, false);

      return () => {
        document.body.removeEventListener("touchstart", handleTouchStart);
      };
    }
  }, [
    mediaRecorder?.state,
    // loadPermissions
  ]);

  return {
    mediaRecorder,
    microphones,
    microphoneId,
    setMicrophoneId,
    loadMicrophones,
    permissionGranted,
  };
};

export default useRecorder;
