import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteScribe } from "../../../store/voiceRecorder/thunks";
import { getProviderNotes } from "../store/thunks";
import ActionsButton from "../../Basic/ActionsButton";
import DeleteModal from "../../Basic/DeleteModal";
import Loader from "../../Basic/Loader";
import RecordingIndicator from "../RecordingIndicator";
import { ReactComponent as UserRoundedIcon } from "../../../assets/icons/user-rounded.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/trash-redesign.svg";
import { NOTE_NOT_SELECTED } from "../consts";

const ScribeListItem = ({ scribe, onClick }) => {
  const { user, onlyScribeFeature } = useSelector((state) => state.user);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const selectedAudioId =
    new URLSearchParams(location.search).get("id") || NOTE_NOT_SELECTED;

  const isSelected = selectedAudioId === scribe.audioId;

  const showPatient =
    scribe.patient &&
    // Hack for unassigned patient
    scribe.patient.first_name !== "018ee44a-5aa1-74b5-b385-a3a994a769b5";
  // End hack

  const onDelete = () => {
    setOpenDeleteModal(true);
  };

  const handleDelete = () => {
    dispatch(
      deleteScribe(scribe.audioId, scribe.id, () => {
        dispatch(getProviderNotes(user.doctor_id));
        setOpenDeleteModal(false);
      }),
    );
  };

  return (
    <>
      <div
        onClick={onClick}
        className={`flex justify-between p-3 py-2 cursor-pointer rounded-lg border border-transparent hover:bg-white group
        ${isSelected ? "md:bg-[#d5dce3] md:hover:bg-[#d5dce3]" : "hover:border-gray-foreground"}`}
      >
        <div className="flex flex-col w-full">
          <div className="flex flex-row justify-between">
            <p
              className={`w-50 h-5 text-sm font-semibold truncate text-primary
              ${isSelected ? "" : "group-hover:text-primary-blue"}`}
            >
              {scribe.title}
            </p>
            <div className="min-w-10 flex justify-end">
              {scribe.isGenerating && (
                <Loader
                  size={4}
                  borderWidth={2}
                  borderColor="border-zinc-450"
                />
              )}
              {!scribe.isGenerating && scribe.isRecording && (
                <RecordingIndicator isRecording={!scribe.isPaused} size={20} />
              )}
            </div>

            {!scribe.isGenerating && !scribe.isRecording && (
              <>
                <ActionsButton
                  buttonClassName="w-5 h-5 rounded-md"
                  className="md:hidden"
                  actions={[
                    {
                      icon: (
                        <DeleteIcon
                          width="20"
                          height="20"
                          className="flex-none"
                        />
                      ),
                      label: "Delete",
                      onClick: onDelete,
                    },
                  ]}
                />

                <button
                  type="button"
                  className="hidden md:flex flex-none opacity-0 md:group-hover:opacity-100"
                  onClick={(e) => {
                    e.stopPropagation();
                    onDelete();
                  }}
                >
                  <DeleteIcon width="16" height="16" />
                </button>
              </>
            )}
          </div>
          <div className="flex flex-row justify-between text-xs text-[#404654]">
            {showPatient ? (
              <div className="flex space-x-3">
                <p>
                  {[
                    `${scribe.patient.first_name} ${scribe.patient.last_name}`.trim(),
                    scribe.duration,
                  ]
                    .filter((field) => !!field)
                    .join(" ・ ")}
                </p>
              </div>
            ) : (
              <p className="flex">
                {!onlyScribeFeature && (
                  <UserRoundedIcon width="16" height="16" stroke="#667085" />
                )}
                {scribe.duration !== null
                  ? `${onlyScribeFeature ? "" : " ・ "}${scribe.duration}`
                  : ""}
              </p>
            )}
            <p className="text-xs text-[#A0A6B2]">{scribe.time}</p>
          </div>
        </div>
      </div>

      {openDeleteModal && (
        <DeleteModal
          title="Delete Encounter"
          description="Are you sure you want to delete the note?"
          onDelete={handleDelete}
          onClose={() => setOpenDeleteModal(false)}
        />
      )}
    </>
  );
};

export default ScribeListItem;
