import ClickAwayListener from "@mui/base/ClickAwayListener";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { ReactComponent as CheckIcon } from "../../assets/icons/check-box.svg";
import { ReactComponent as FileIcon } from "../../assets/icons/file-round.svg";
import { renameTemplate } from "../../store/template/thunks";
import Checkbox from "../Basic/Checkbox";

const TemplateItem = ({
  template,
  containerRef,
  selectedItems,
  setSelectedItems,
  onDuplicate,
  setOpenDeleteModal,
  clickedTemplate,
  setClickedTemplate,
  setMode,
}) => {
  const [openTemplateMenu, setOpenTemplateMenu] = useState(false);
  const [templateMenuPosition, setTemplateMenuPosition] = useState({
    x: 0,
    y: 0,
  });
  const [renameTemplateId, setRenameTemplateId] = useState("");
  const [renamedTemplateName, setRenamedTemplateName] = useState("");
  const dispatch = useDispatch();

  return (
    <div
      className="p-2.5 flex items-center cursor-pointer relative"
      onContextMenu={(e) => {
        e.preventDefault();
        setOpenTemplateMenu(true);
        setClickedTemplate(template);

        const bounds = e.currentTarget.getBoundingClientRect();
        const listBounds = containerRef.current.getBoundingClientRect();
        const isItemAtBottom = bounds.bottom > listBounds.bottom - 100;
        setTemplateMenuPosition({
          x: e.clientX - bounds.left,
          y: isItemAtBottom ? "-100px" : e.clientY - bounds.top,
        });
      }}
      onClick={() => {
        if (!renameTemplateId) {
          if (
            selectedItems.find(
              (t) => t.campaign_template_id === template.campaign_template_id,
            )
          ) {
            setSelectedItems(
              selectedItems.filter(
                (t) => t.campaign_template_id !== template.campaign_template_id,
              ),
            );
          } else {
            setSelectedItems([...selectedItems, template]);
          }
          setMode("");
        }
      }}
    >
      {openTemplateMenu &&
        clickedTemplate.campaign_template_id ===
          template.campaign_template_id && (
          <ClickAwayListener onClickAway={() => setOpenTemplateMenu(false)}>
            <div
              className="absolute bg-white p-2 rounded-lg shadow-filter z-30 flex flex-col
                text-xs font-semibold space-y-1"
              style={{
                top: templateMenuPosition.y,
                left:
                  templateMenuPosition.x > 160 ? 160 : templateMenuPosition.x,
              }}
            >
              <button
                type="button"
                className="text-primary-blue py-2 px-4 hover:bg-blue-50 rounded-lg text-left"
                onClick={(e) => {
                  e.stopPropagation();
                  setRenameTemplateId(template.campaign_template_id);
                  setRenamedTemplateName(template.name);
                  setOpenTemplateMenu(false);
                }}
              >
                Rename
              </button>
              <button
                type="button"
                className="text-primary-blue py-2 px-4 hover:bg-blue-50 rounded-lg text-left"
                onClick={(e) => {
                  e.stopPropagation();
                  onDuplicate(template);
                  setOpenTemplateMenu(false);
                }}
              >
                Duplicate
              </button>
              <button
                type="button"
                className="text-red-500 py-2 px-4 hover:bg-blue-50 rounded-lg text-left"
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenDeleteModal(true);
                  setOpenTemplateMenu(false);
                }}
              >
                Delete
              </button>
            </div>
          </ClickAwayListener>
        )}

      <FileIcon width="16" height="16" className="flex-none" />
      {renameTemplateId === template.campaign_template_id ? (
        <input
          className="outline-none border-b h-5 text-sm px-2 w-full"
          value={renamedTemplateName}
          onChange={(e) => setRenamedTemplateName(e.target.value)}
        />
      ) : (
        <p className="ml-1.5 text-sm">{template.name}</p>
      )}
      {renameTemplateId === template.campaign_template_id ? (
        <button
          type="button"
          className="ml-auto flex-none p-1 pr-0"
          onClick={() => {
            dispatch(
              renameTemplate(
                template.campaign_template_id,
                renamedTemplateName,
              ),
            );
            setRenameTemplateId("");
            setRenamedTemplateName("");
          }}
        >
          <CheckIcon width="12" height="10" stroke="#12B76A" />
        </button>
      ) : (
        <Checkbox
          className="ml-auto"
          onClick={(e) => e.stopPropagation()}
          checked={selectedItems.find(
            (t) => t.campaign_template_id === template.campaign_template_id,
          )}
          number={
            selectedItems.findIndex(
              (t) => t.campaign_template_id === template.campaign_template_id,
            ) + 1
          }
        />
      )}
    </div>
  );
};

export default TemplateItem;
