import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setProviderNoteAction } from "../store/actions";
import { saveEncounterFreeText } from "../../../store/conversation/thunks";
import { setNotificationAction } from "../../../store/user/actions";
import { copyText } from "../../../helpers/helpers";
import TextareaAutosizeForm from "../../Basic/TextareaAutosizeForm";
import { ReactComponent as CopyIcon } from "../../../assets/icons/copy-check.svg";

const labelStyle = {
  default: "text-primary",
  oldRecord: "text-tertiary",
};

const textAreaStyle = {
  default: "text-tertiary",
  oldRecord: "text-primary",
};

const FreeText = ({
  scribe,
  originalFreeText,
  variant = "default",
  copyButton = false,
}) => {
  const [text, setText] = useState(originalFreeText);
  const freeTextRef = useRef(originalFreeText);
  const dispatch = useDispatch();

  useEffect(() => {
    setText(originalFreeText);
    freeTextRef.current = originalFreeText;
  }, [scribe?.note_id, originalFreeText]);

  const handleChange = useCallback(
    (event) => {
      setText(event.target.value);
      freeTextRef.current = event.target.value;
      dispatch(
        setProviderNoteAction({
          noteId: scribe?.note_id,
          note: {
            free_text: event.target.value,
            isEditing: true,
          },
        }),
      );
    },
    [scribe?.note_id, dispatch],
  );

  const handleBlur = useCallback(() => {
    if (!scribe?.note_id) {
      return;
    }

    dispatch(
      setProviderNoteAction({
        noteId: scribe?.note_id,
        note: {
          free_text: freeTextRef.current,
          isEditing: false,
        },
      }),
    );

    dispatch(saveEncounterFreeText(scribe.audio_id, freeTextRef.current));
  }, [scribe, dispatch]);

  const handleCopyText = () => {
    copyText(text, () =>
      dispatch(
        setNotificationAction({
          status: "success",
          title: "Successfully copied text",
          desc: "Text has been successfully copied to the clipboard",
        }),
      ),
    );
  };

  return (
    <>
      <div className="flex items-center space-x-2">
        <p
          className={`${labelStyle[variant]} text-sm font-semibold tracking-wider`}
        >
          FREE TEXT
        </p>
        {Boolean(copyButton) && (
          <button type="button" aria-label="Copy text" onClick={handleCopyText}>
            <CopyIcon width="24" height="24" />
          </button>
        )}
      </div>

      {/* <TextareaAutosize
        style={{ width: "1000px" }}
        value={note}
        onBlur={handleNoteBlur}
        onChange={(e) => (setNote(e.target.value))}
      /> */}
      <TextareaAutosizeForm
        className={`${textAreaStyle[variant]} resize-none w-full mt-2 text-sm`}
        textareaClassName="focus:border-primary-blue"
        placeholder="Enter your text..."
        minRows={2}
        onBlur={handleBlur}
        onChange={handleChange}
        value={text}
      />
    </>
  );
};

export default FreeText;
