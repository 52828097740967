// LocalStorageService.js
class LocalStorageService {
  static VERSION = "1.0";

  /**
   * Generates a storage key with versioning.
   * @param {string} key - The base key.
   * @returns {string} - The combined storage key.
   */
  _generateStorageKey(key) {
    return `${key}_v${LocalStorageService.VERSION}`;
  }

  /**
   * Stores an item in localStorage after serializing it to JSON.
   * @param {string} key - The key under which the value is stored.
   * @param {*} value - The value to store.
   */
  setItem(key, value) {
    const storageKey = this._generateStorageKey(key);

    if (value === undefined) {
      console.warn(
        `Attempted to store undefined value under key "${storageKey}". Operation aborted.`,
      );
      return;
    }

    try {
      const serializedValue = JSON.stringify(value);
      localStorage.setItem(storageKey, serializedValue);
    } catch (error) {
      console.error(
        `Error setting item in localStorage with key "${storageKey}":`,
        error,
      );
    }
  }

  /**
   * Retrieves an item from localStorage and deserializes it from JSON.
   * @param {string} key - The key under which the value is stored.
   * @returns {*} - The retrieved value, or null if not found or an error occurs.
   */
  getItem(key) {
    const storageKey = this._generateStorageKey(key);

    try {
      const serializedValue = localStorage.getItem(storageKey);

      if (serializedValue === null || serializedValue === undefined) {
        return null;
      }

      return JSON.parse(serializedValue);
    } catch (error) {
      console.error(
        `Error getting item from localStorage with key "${storageKey}":`,
        error,
      );
      return null;
    }
  }

  /**
   * Removes an item from localStorage.
   * @param {string} key - The key under which the value is stored.
   */
  removeItem(key) {
    const storageKey = this._generateStorageKey(key);

    try {
      localStorage.removeItem(storageKey);
    } catch (error) {
      console.error(
        `Error removing item from localStorage with key "${storageKey}":`,
        error,
      );
    }
  }

  /**
   * Clears all items from localStorage.
   */
  clear() {
    try {
      localStorage.clear();
    } catch (error) {
      console.error("Error clearing localStorage:", error);
    }
  }

  /**
   * Retrieves all keys from localStorage.
   * @returns {Array<string>} - An array of all keys.
   */
  getAllKeys() {
    try {
      return Object.keys(localStorage);
    } catch (error) {
      console.error("Error getting keys from localStorage:", error);
      return [];
    }
  }
}

export default LocalStorageService;
