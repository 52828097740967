import { useForm } from "react-hook-form";
import { passwordValidationRegEx } from "../../../../helpers/constants";
import Button from "../../../Basic/Button";
import Input from "../../../Basic/Input";
import InputBlock from "../../../Basic/InputBlock";
import Modal from "../../../Basic/Modal";
import UserPictureName from "./UserPictureName";

const ResetPasswordModal = ({ onClose, user }) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      new_password: "",
      confirm_password: "",
    },
  });

  const onSubmit = (data) => {
    console.log(data);
    onClose();
  };

  return (
    <Modal className="pt-5 rounded-2xl" onClickAway={onClose}>
      <div className="w-114">
        <p className="text-base md:text-xl font-semibold mb-5 px-5">
          Reset Password
        </p>

        <form
          id="reset-password"
          onSubmit={handleSubmit(onSubmit)}
          className="px-5 space-y-5 overflow-x-hidden"
        >
          <UserPictureName user={user} />

          <InputBlock title="New Password" titleClassName="text-sm mt-2.5">
            <Input
              className="col-span-2"
              register={register}
              name="new_password"
              placeholder="Enter new password"
              type="password"
              required="Password can’t be blank"
              error={errors.new_password}
              minLength={{
                value: 8,
                message: "Must be at least 8 characters",
              }}
              validationPattern={{
                value: passwordValidationRegEx,
                message:
                  "Use a minimum of 8 characters with a mix of letters, numbers & symbols",
              }}
              autoComplete="new-password"
            />
          </InputBlock>

          <InputBlock title="Confirm Password" titleClassName="text-sm mt-2.5">
            <Input
              className="col-span-2"
              register={register}
              name="confirm_password"
              placeholder="Repeat your password"
              type="password"
              required="Password can’t be blank"
              error={errors.confirm_password}
              minLength={{
                value: 8,
                message: "Must be at least 8 characters",
              }}
              validate={(value) => {
                if (watch("new_password") !== value) {
                  return "Passwords don't match";
                }
              }}
              autoComplete="new-password"
            />
          </InputBlock>
        </form>

        <div className="flex justify-end gap-4 font-semibold text-sm bg-gray-background py-4 px-5 mt-5">
          <Button variant="gray" onClick={onClose}>
            Cancel
          </Button>
          <Button form="reset-password">Confirm</Button>
        </div>
      </div>
    </Modal>
  );
};

export default ResetPasswordModal;
