import { useEffect, useState } from "react";
import { getDurationString, parseDateString } from "../../../helpers/helpers";
import ScribeListSkeleton from "../../Skeletons/ScribeListSkeleton";
import ScribeListContent from "../ScribeListContent";
import ScribeListEmpty from "../ScribeListEmpty";
import ScribeListFooter from "../ScribeListFooter";
import ScribeListHeader from "../ScribeListHeader";

const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const dateOptions = {
  timeZone: userTimezone,
  month: "numeric",
  day: "numeric",
  year: "numeric",
};

const timeOptions = {
  timeZone: userTimezone,
  hour: "numeric",
  minute: "numeric",
  hour12: true,
};

const ScribeList = ({
  user,
  isRecording,
  providerNotes,
  handleSelectScribe,
  setIsNewScribe,
  notesLoading,
}) => {
  const [_, setTimer] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isRecording) {
        setTimer((prevTimer) => prevTimer + 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [isRecording]);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const groupedScribes = providerNotes
    ? providerNotes
        .reduce((acc, note) => {
          if (note.isVisible === false) {
            return acc;
          }

          const startDate = parseDateString(
            note.started_at || new Date().toISOString(),
          );
          const endDate = parseDateString(
            note.finished_at || new Date().toISOString(),
          );
          const isToday = startDate.toDateString() === today.toDateString();

          const dayDifference = (today - startDate) / (1000 * 60 * 60 * 24);

          let formattedDate;
          if (isToday) {
            formattedDate = "Today";
          } else if (dayDifference < 1) {
            formattedDate = "Yesterday";
          } else if (dayDifference < 7) {
            formattedDate = startDate.toLocaleDateString("en-US", {
              weekday: "long",
            });
          } else {
            formattedDate = startDate.toLocaleString("en-US", dateOptions);
          }

          const sortableDate = startDate.toISOString();

          if (!acc.find((group) => group.date === formattedDate)) {
            acc.push({
              date: formattedDate,
              sortableDate,
              entries: [],
            });
          }

          const group = acc.find((group) => group.date === formattedDate);

          const durationInSeconds =
            note.duration ||
            Math.floor((endDate - startDate) / 1000) +
              (note.accumulated_duration || 0);

          const item = {
            id: note.note_id,
            audioId: note.audio_id,
            title: note.title,
            time: startDate.toLocaleString("en-US", timeOptions),
            duration: getDurationString(durationInSeconds),
            patient: note.patient,
            isRecording: note.isRecording,
            isGenerating:
              note.isGenerating || (note.note_id > 0 && note.note === null),
            isPaused: note.isRecording && note.started_at === null,
          };

          if (item.id === -1) {
            item.duration = null;
          }

          group.entries.push(item);

          return acc;
        }, [])
        .sort((a, b) => new Date(b.sortableDate) - new Date(a.sortableDate))
    : [];

  return notesLoading ? (
    <div className="flex flex-col gap-4 h-full w-full">
      <ScribeListHeader user={user} />
      <div className="flex flex-col h-full">
        <ScribeListSkeleton className="pb-18" />
        <ScribeListFooter setIsNewScribe={setIsNewScribe} />
      </div>
    </div>
  ) : groupedScribes.length > 0 ? (
    <div className="flex flex-col gap-4 h-full w-full">
      <ScribeListHeader user={user} />
      <div className="flex flex-col h-full">
        <ScribeListContent
          groupedScribes={groupedScribes}
          handleSelectScribe={handleSelectScribe}
        />
        <ScribeListFooter setIsNewScribe={setIsNewScribe} />
      </div>
    </div>
  ) : (
    <ScribeListEmpty setIsNewScribe={setIsNewScribe} />
  );
};

export default ScribeList;
